/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import $ from "jquery";
import swal from 'sweetalert';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from './Header'
import Footer from './Footer'
import CountDown from './DealCountDown';
import SocialShare from "./common/SocialShare";
import ItemActivity from "./common/ItemActivity";
import avtar1 from '../Images/avtar1.png'
import { tokenToUsd, baseUrl, ontop, chainUrl, contract, tokenContract, imgsrc, blockexplorer, chain, web3, ContractAddress, Oneto11, nftMint721Address, nftMint721Contract, profileUrl } from '../Details';
import { checkMarket, getOneDeal, getOneSale } from "../helpers/helper"
// #endregion

const propTypes = {};
const defaultProps = {};

const Buynow = () => {

    const history = useHistory();
    const search = useLocation().search;
    const [reason, setReason] = useState('');
    const [comments, setComments] = useState('');
    const [buyTokneId, setBuyTokneId] = useState('');
    const [price, setPrice] = useState('');
    const [imgUrl, setImageUrl] = useState('');
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [likesNum, setLikesNum] = useState(0)
    const [reqBalance, setReqBalance] = useState(0)
    const [offerValue, setOfferValue] = useState(0)
    const [contractData, setContractData] = useState({});
    const [lastOfferAmount, setLastOfferAmount] = useState(0);
    const [hash, setHash] = useState('');
    const [wishList, setWishList] = useState(true);
    const [baseUri, setBaseUri] = useState('');
    const [socialUrl, setSocialUrl] = useState(window.location.href);
    const [liked, setLiked] = useState(false);
    const [user, setUser] = useState('');
    const [userImage, setUserImage] = useState('')
    const [userName, setUserName] = useState('')
    const [userByAdd, setuserByAdd] = useState(false);

    // #region functions
    const [historyData, setHistoryData] = useState([])
    var userIdCheck = sessionStorage.getItem("userid");
    const getHistory = (tokenId) => {
        axios.post(`${baseUrl}/getTokenHistory`, {
            tokenId: tokenId
        }).then((res) => {
            let dt = [];
            if (res.data.success && res.data.data)
                dt = res.data.data;
            setHistoryData(dt)
        }).catch((err) => {

        })
    }

    const nftSeller = (nftid) => {
        return new Promise((resolve, reject) => {
            var config = {
                method: 'get',
                url: `${baseUrl}/market-nft-owner?nftid=${nftid}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
            };
            axios(config).then(function (response) {

                if (response.data.success === true) {
                    if (response.data.data[0].username) {
                        resolve(response.data.data[0])
                    } else {
                        setuserByAdd(true)
                        resolve(response.data.data[0].seller)
                    }

                } else {
                    resolve('0x0000000000000000000000')
                }
            }).catch(function (error) {
                console.error(error);
            });
        })

    }

    const fetchNft = async () => {

        const tokenId = new URLSearchParams(search).get('tokenNo');
        console.log("Token ID for NFT Details=", tokenId);

        if (tokenId === null || tokenId === undefined || tokenId === '') {
            history.push('/');
        } else {
            getHistory(tokenId);
            console.log("web3.givenProvider chainId=", await web3.givenProvider.chainId);
            if (web3.givenProvider !== null && await web3.givenProvider.chainId === chain) {
                console.log("Fetching from Contract for token = ", tokenId);
                await contract.methods.checkMarket(tokenId).call().then(async (nftSpace) => {

                    if (nftSpace === 'Deal') {
                        await contract.methods.dealDetailsByTokenId(tokenId).call().then((nftdata) => {
                            setPrice(nftdata.price);
                        });
                    } else if (nftSpace === 'Sale') {
                        let buyprice = await contract.methods.getSellTokenPrice(tokenId).call();
                        setPrice(buyprice);
                    } else {
                        history.push('/');
                    }
                })
            } else {
                console.log("Fetching nft details from database for token ", tokenId);
                const market = await checkMarket(tokenId);
                if (market === 'Deal') {
                    const deal = await getOneDeal(tokenId);
                    console.log("deal", deal)
                    setPrice(deal.price);
                    setContractData(deal);
                } else if (market === 'Sale') {
                    const sale = await getOneSale(tokenId);
                    if (sale)
                        setPrice(sale.price);
                    setLastOfferAmount(sale.lastOffer)
                } else {
                    history.push('/');
                }
            }


            setBuyTokneId(tokenId);
            var idArr = [];
            idArr.push(tokenId);
            var data = qs.stringify({
                'id': JSON.stringify(idArr)
            });

            var config = {
                method: 'post',
                url: `${baseUrl}/nft`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };

            await axios(config)
                .then(async function (response) {
                    if (response.data.success === true) {
                        let data = JSON.parse((response.data.data[0].ipfs));
                        setImageUrl(data.image);
                        setName(data.name);
                        setDesc(data.description);
                        setHash(`${blockexplorer}/token/${nftMint721Address}?a=${tokenId}`)
                        setBaseUri(data.uri);
                        var contractdetails
                        if (web3.givenProvider !== null && web3.givenProvider.chainId === chain) {
                            contractdetails = await nftdata(tokenId);
                            setContractData(contractdetails)
                        } else {
                            const market = await checkMarket(tokenId);
                            if (market === 'Deal') {
                                const deal = await getOneDeal(tokenId);
                                setContractData(deal);
                            } else {
                                setContractData({});
                            }
                        }

                        // const uri = await nftMint721Contract.methods.getbaseURI().call();
                        // setBaseUri(uri);
                        const user = await nftSeller(tokenId);
                        if (typeof (user) === 'object') {
                            setUserName(user.username)
                            setUserImage(user.profileImage)
                        } else {
                            setUserName(user)
                        }

                    }
                }).catch(function (error) {
                    console.error(error);
                });
        }

    }
    // Approve token
    const approveBal = async () => {
        let address = sessionStorage.getItem("address");
        let userId = sessionStorage.getItem('userid')
        if (userId !== null) {
            if (address !== null) {
                let reqbal = reqBalance;
                let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
                UserTokenBalance = await web3.utils.fromWei(UserTokenBalance, "ether")
                if (reqbal !== "" && reqbal > 0) {
                    if (parseInt(UserTokenBalance) >= parseInt(reqbal)) {

                        await tokenContract.methods.approve(ContractAddress, (await web3.utils.toWei(reqbal, "ether"))).send({ from: address }, function (err, result) {
                            if (err) {
                                swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                            }
                            if (result) {
                                swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                            }
                        }).on('receipt', function (receipt) {
                            if (receipt.status === true) {
                                $('.ajax-loader').css("display", "none");
                                document.getElementById("closeButton").click();
                                swal({ title: "Your token balance approved", icon: "success", background: "#808080", timer: 2000 });
                            } else {
                                $('.ajax-loader').css("display", "none");
                                swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                            }
                        })
                    }
                    else {
                        swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                    }
                }
                else {
                    swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
                }
            } else {
                swal({ title: "Please Connect to the wallet", icon: "error", background: " #808080", timer: 2000 });
            }
        } else {
            swal({ title: "Please login account", icon: "error", background: " #808080", timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    //buy Deal
    const buyDeal = async () => {
        let nftAmount = price;
        let Id = buyTokneId;
        var userId = sessionStorage.getItem("userid");
        let address = sessionStorage.getItem("address");


        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
                let allowance = await tokenContract.methods.allowance(address, ContractAddress).call();
                let dealDetails = await contract.methods.dealDetailsByTokenId(Id).call();
                let endtime = dealDetails.endTime;
                const now = Math.floor((new Date()).getTime() / 1000);
                if (parseInt(endtime) > now) {
                    if (nftAmount / 10 ** 18 <= parseInt((UserTokenBalance) / 10 ** 18)) {
                        if (nftAmount / 10 ** 18 <= (parseInt(allowance / 10 ** 18))) {
                            await contract.methods.buyDeal(Id, nftAmount).send({ from: address }, function (err, result) {
                                if (err) {
                                    swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                                }
                                if (result) {
                                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                    setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                                }
                            }).on('receipt', function (receipt) {
                                if (receipt.status === true) {
                                    $('.ajax-loader').css("display", "none");
                                    swal({ title: "Transaction Confirm.", icon: "success", background: "#808080", showConfirmButton: false, timer: 1000 });
                                    history.push({
                                        pathname: '/SoldOut',
                                        search: `?tokenNo=${Id}`,
                                        state: {
                                            userId: userId,
                                        }
                                    });
                                } else {
                                    $('.ajax-loader').css("display", "none");
                                    swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                                }
                            })
                        } else {
                            // swal({ title: "Please approve the token", icon: "error", background: "#808080", timer: 2000 });
                            document.getElementById("rebalaId").style.display = "block";
                            var showcls = document.getElementById("buyApprove");
                            showcls.className += " show d-block";
                            showcls.style.backgroundColor = '#0000008a';
                        }

                    }
                    else {
                        swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                    }
                } else {
                    swal({ title: "This Deal is over", icon: "error", background: "#808080", timer: 2000 });
                }
            } else {
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }

    }
    //buy nft
    const buyNft = async (p) => {
        let nftAmount = price;
        let Id = buyTokneId;
        let userId = sessionStorage.getItem("userid");
        let address = sessionStorage.getItem("address");


        setReqBalance(p)


        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
                let allowance = await tokenContract.methods.allowance(address, ContractAddress).call();
                // let amount = parseInt(amt)
                if (Number(nftAmount) / 10 ** 18 <= Number((UserTokenBalance) / 10 ** 18)) {
                    if (Number(nftAmount) / 10 ** 18 <= (Number(allowance) / 10 ** 18)) {
                        await contract.methods.buy(Id, nftAmount).send({ from: address }, async function (err, result) {
                            if (err) {
                                swal("Transaction Error", { buttons: false, icon: "error", timer: 2000 });
                            }
                            if (result) {
                                swal("Your transaction is sent, Please wait for confirmation.", { button: false, timer: 2000, icon: "success" });
                                setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                            }
                        }).on('receipt', function (receipt) {
                            if (receipt.status === true) {
                                $('.ajax-loader').css("display", "none");
                                // setTimeout(() => {
                                swal({ title: "Transaction Confirm.", icon: "success", background: "#808080", showConfirmButton: false, timer: 1000 });
                                history.push({
                                    pathname: '/SoldOut',
                                    search: `?tokenNo=${Id}`,
                                    state: {
                                        userId: userId
                                    }
                                });
                            } else {
                                $('.ajax-loader').css("display", "none");
                                swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                            }
                        })
                    } else {
                        // swal({ title: "Please approve the token", icon: "error", background: "#808080", timer: 2000 });
                        document.getElementById("rebalaId").style.display = "block";
                        var showcls = document.getElementById("buyApprove");
                        showcls.className += " show d-block";
                        showcls.style.backgroundColor = '#0000008a';
                    }

                }
                else {
                    swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                }

            } else {
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }

    }
    //makeoffermodelbox
    const openOfferBox = async () => {
        let address = sessionStorage.getItem("address");
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                let Id = buyTokneId;
                var lastOffer = await contract.methods.saleDetailsByTokenId(Id).call();
                lastOffer = parseInt(lastOffer.offerInfo.price);
                setLastOfferAmount(lastOffer);
            } else {
                document.getElementById("offer_close").click();
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            document.getElementById("offer_close").click();
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }

    }

    //makeoffer
    const makeOfferhandle = async () => {
        let nftAmount = offerValue;
        let Id = buyTokneId;
        let userId = sessionStorage.getItem("userid");
        let address = sessionStorage.getItem("address");


        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                // let amount = parseInt(amt)
                let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
                let allowance = await tokenContract.methods.allowance(address, ContractAddress).call();
                var lastOffer = await contract.methods.saleDetailsByTokenId(Id).call();
                lastOffer = lastOffer.offerInfo.price;
                if (nftAmount > 0) {

                    if (parseInt(lastOffer) / 10 ** 18 < nftAmount) {
                        if (nftAmount <= parseInt(UserTokenBalance) / 10 ** 18) {
                            if (nftAmount <= parseInt(allowance) / 10 ** 18) {
                                await contract.methods.makeOffer(Id, (web3.utils.toWei(nftAmount, 'ether'))).send({ from: address }, function (err, result) {
                                    if (err) {
                                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                                        document.getElementById("offer_close").click();
                                    }
                                    if (result) {
                                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                    }
                                }).on('receipt', function (receipt) {
                                    if (receipt.status === true) {
                                        $('.ajax-loader').css("display", "none");
                                        document.getElementById("offer_close").click();
                                        swal(" Transaction confirm.", { button: false, timer: 2000, icon: "success" });
                                    } else {
                                        $('.ajax-loader').css("display", "none");
                                        document.getElementById("offer_close").click();
                                        swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                                    }
                                })
                            } else {
                                // swal({ title: "Please approve the token", icon: "error", background: "#808080", timer: 2000 });
                                document.getElementById("rebalaId").style.display = "block";
                                var showcls = document.getElementById("buyApprove");
                                showcls.className += " show d-block";
                                showcls.style.backgroundColor = '#0000008a';
                                document.getElementById("offer_close").click();
                            }
                        }
                        else {
                            swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                        }
                    } else {
                        swal({ title: "Enter more than last value", icon: "error", background: "#808080", timer: 2000 });
                    }
                } else {
                    swal({ title: "Enter valid amount", icon: "error", background: "#808080", timer: 2000 });
                }
            } else {
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            document.getElementById("offer_close").click();
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    //Wishlist 
    const addWishlist = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = buyTokneId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/addinwishlist`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            swal("Added into wishlist", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setWishList(!wishList);
                        } else {
                            swal(response.data.msg, { button: false, icon: "warning", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to add nft in wishlist", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    //remove wishlist
    const removeWishList = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = buyTokneId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/removefromwishlist`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            swal("Removed from wishlist", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setWishList(!wishList);
                        } else {
                            swal(response.data.msg, { button: false, icon: "warning", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to remove nft in wishlist", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    const handleReport = () => {

        let userId = (sessionStorage.getItem("userid"));
        let Reason = reason.trim();
        let Comment = comments.trim();

        let nftId = buyTokneId;
        if (userId === '' || userId === null || userId === undefined) {
            swal("Please login to report", { button: false, icon: "warning", timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        };
        if (Reason === null || Reason === '' || Reason === undefined) {
            return swal("Please enter valid reason", { button: false, icon: "warning", timer: 2000 });
        }
        if (Comment === '' || Comment === null || Comment === undefined) {
            return swal("Please enter valid comment", { button: false, icon: "warning", timer: 2000 });
        };
        if (nftId.trim() === '' || nftId.trim() === null || nftId.trim() === undefined) {
            return swal("There is some issue while submitting your request please try again later", { button: false, icon: "warning", timer: 2000 });
        }
        $('.ajax-loader').css("display", "flex");
        var data = qs.stringify({
            'userid': userId,
            'nftid': nftId,
            'reason': Reason,
            'comments': Comment
        });
        var config = {
            method: 'post',
            url: `${baseUrl}/add-report`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    $('.ajax-loader').css("display", "none");
                    swal("Report submitted successfully", { button: false, icon: "success", timer: 2000 });
                    setReason('');
                    setComments('');
                    document.getElementById('reportclose').click();
                } else {
                    $('.ajax-loader').css("display", "none");
                    swal("There is some techincal issue! please try again later", { button: false, icon: "success", timer: 2000 });
                }
            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loader').css("display", "none");
            });
    }
    const nftdata = async (token) => {
        return new Promise(async (resolve) => {
            await contract.methods.checkMarket(token).call().then(async (nftSpace) => {

                if (nftSpace === 'Deal') {
                    await contract.methods.dealDetailsByTokenId(token).call().then((nftdata) => {
                        resolve(nftdata)
                    });
                } else {
                    resolve({})
                }
            })
        })

    }
    //check wishlist
    const checkList = () => {
        let userId = sessionStorage.getItem('userid')
        if (userId === '' && userId === null && userId === undefined) {
            const tokenId = new URLSearchParams(search).get('tokenNo');
            // const userId = sessionStorage.getItem('userid');
            var data = qs.stringify({
                'userid': userId,
                'nftid': tokenId
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/wishlistcheck`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response.data.success === true) {
                        setWishList(false);
                    } else {
                        setWishList(true);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }
    //function to count likes on nft
    const countLikes = () => {
        return new Promise((resolve) => {
            const tokenId = new URLSearchParams(search).get('tokenNo');
            if (tokenId !== null && tokenId !== '' && tokenId !== undefined) {
                var config = {
                    method: 'get',
                    url: `${baseUrl}/count-likes?nftid=${tokenId}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    }
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            resolve(setLikesNum(response.data.data))
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        })


    }
    // Add likes
    const addLikes = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = buyTokneId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'likedby': userId,
                    'likedto': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/add-likes`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };

                axios(config)
                    .then(async function (response) {

                        if (response.data.success === true) {
                            swal("Liked successfully", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setLiked(true);
                            await countLikes();
                        } else {
                            if (response.status === 203) {
                                var data = qs.stringify({
                                    'likedby': userId,
                                    'likedto': Id
                                });
                                var config = {
                                    method: 'post',
                                    url: `${baseUrl}/remove-likes`,
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    data: data
                                };

                                axios(config)
                                    .then(function (response) {
                                        if (response.data.success === true) {
                                            swal("Unliked successfully", { button: false, icon: "success", timer: 2000 });
                                            $('.ajax-loader').css("display", "none");
                                            setLiked(false);
                                            countLikes();
                                        }
                                    })
                                    .catch(function (error) {
                                        console.error(error);
                                        $('.ajax-loader').css("display", "none");
                                    });
                            }
                            else {
                                swal(response.data.msg, { button: false, icon: "error", timer: 2000 });
                                $('.ajax-loader').css("display", "none");
                            }
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to add nft in wishlist", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    const checkLike = () => {
        let userId = sessionStorage.getItem("userid");
        let Id = buyTokneId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/check-likes`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            if (response.data.data === 1) {
                                setLiked(true);
                            } else {
                                setLiked(false);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        }
    }
    //close button
    const closebutton = () => {
        var showcls = document.getElementById("buyApprove");
        showcls.classList.remove("show");
        showcls.classList.remove("d-block");
        showcls.style.backgroundColor = '#0000';
    }

    const closeOfferbox = () => {
        var showcls = document.getElementById("makeoffer_modal");
        showcls.classList.remove("show");
        showcls.classList.remove("d-block");
        showcls.style.backgroundColor = '#0000';
    }
    // #endregion

    // #useeffectregion
    useEffect(async () => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
        await fetchNft();
        await countLikes();
    }, [name, desc, imgUrl]);


    useEffect(() => {
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== undefined && userId !== '') {
            setUser(userId)
        }
    }, [sessionStorage.getItem("userid")]);

    useEffect(() => {
        checkLike();
    }, [liked, user, buyTokneId]);

    useEffect(() => {
        checkList();
    }, [wishList]);
    // #endregion

    const now = Math.floor((new Date()).getTime() / 1000);

    return <div>
        <Header />
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <section className="exploreWrapper">
            <div className="card-details-wrapper">
                <div className="cstm-container">
                    <div className="innr_bck_box">
                        <Link className="btn viewBtn" to="/">
                            <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>
                            <p className="m-0">Back to home</p>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 dtl_lft_bx">
                            <img className="img-fluid" src={imgUrl} alt="" />
                        </div>
                        <div className="col-lg-6 details_box">
                            <h3 className="cardHeading headingC fw-bold">{name}</h3>
                            <div className="views_box_btn">
                                <div className="views_bx">
                                    {/* <div className="vies_cnt">1 of 1</div> */}
                                    <div className='d-flex' style={{ gap: "6px" }}>
                                        <div className="hrt_cnt" onClick={addLikes}>
                                            {liked ? <i className="ri-heart-fill"></i> : <i className="ri-heart-line"></i>} {likesNum}
                                        </div>
                                        {!userByAdd ? <a href={profileUrl + userName} id='userbtn' className="nm_ank">
                                            <img className="img-fluid" style={{ borderRadius: "50%", height: "30px", width: "30px" }} src={imgsrc + userImage} alt="" />{userName}
                                        </a> : <a href={chainUrl + userName} className="nm_ank">
                                            <img className="img-fluid" style={{ borderRadius: "50%", height: "30px", width: "30px" }} src={avtar1} alt="" />{(<div className="copyLink d-flex">
                                                <input
                                                    className="m-0" style={{ fontWeight: "500", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                    type="text"
                                                    defaultValue={userName}
                                                    placeholder="Type some text here"
                                                    disabled={true}
                                                />
                                                <CopyToClipboard text={userName} onCopy={() => { swal("Address Copied !!", { button: false, icon: "success", timer: 2000 }); }}>
                                                    <div className="copy-area">
                                                        <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                                    </div>
                                                </CopyToClipboard>
                                            </div>)}
                                        </a>}
                                    </div>

                                </div>
                                <div className="share_box">
                                    <div className="sharebtn_down dropdown">
                                        <button className="btn sharebtnd" id="social" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="ri-share-line"></i>
                                        </button>
                                        <ul className="dropdown-menu socialicon" aria-labelledby="social">
                                            <SocialShare url={socialUrl} img={imgUrl} title={name} desc={desc} />
                                        </ul>
                                    </div>
                                    <div className="sharebtn_down dropdown report">
                                        <button className="btn sharebtnd" id="report" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="ri-more-fill"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="report">
                                            <li>
                                                <Link to="/" data-bs-toggle="modal" data-bs-target="#reportM">
                                                    <i className="ri-flag-line"></i> Report
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div />
                            <div className="dropdown vpabtnbx">
                                <button className="btn vpbtn dropdown-toggle" type="button" id="vpadpdn" data-bs-toggle="dropdown" aria-expanded="false">
                                    View proof of authenticity
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="vpadpdn">
                                    <li>
                                        <a rel="noreferrer" href={baseUri + buyTokneId} target="_blank">
                                            <span><img src="img/ipfs.png" alt="" className="me-2" /></span>
                                            <span>View on IPFS</span>
                                            <span><i className="ri-external-link-line"></i> </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a rel="noreferrer" href={hash} target="_blank">
                                            <span><img src="img/polygon.png" alt="" className="me-2" /></span>
                                            <span>View on Polyscan</span>
                                            <span><i className="ri-external-link-line"></i></span>
                                        </a>
                                    </li>
                                </ul>
                                {/* <Link className="btn vpbtn mx-3" href="MyWishlist.html">Add to Wishlist</Link> */}
                                {wishList ? <button className="btn vpbtn mx-3" type="button" onClick={addWishlist}>Add to Wishlist</button> : <button className="btn vpbtn mx-3" type="button" onClick={removeWishList}>Remove from Wishlist</button>}
                            </div>
                            <div className="details_tabbx">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="btn active" id="pills-Details-tab" data-bs-toggle="pill" data-bs-target="#pills-Details" type="button" role="tab" aria-controls="pills-Details" aria-selected="false" >Details</button>
                                    </li>
                                </ul>
                                <div className="hrbx hr"></div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-Details" role="tabpanel" aria-labelledby="pills-Details-tab">
                                        <p className="">{desc}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="numbersbx">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="n_box">
                                            <p className="ttl_l color_text">Price</p>
                                            <h5 className="price_l">{Oneto11}{(price / 10 ** 18)} / <b>~${tokenToUsd(price / 10 ** 18)}</b><span className="txt_sm color_text"> </span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hrbx hr2"></div>
                            {Object.keys(contractData).length === 0 ? <button className="btn dtl_btn" id="buyButton" onClick={() => buyNft((price / 10 ** 18))}
                                style={{ marginRight: '10px' }}
                            >
                                Buy Now
                            </button> : parseInt(now) > parseInt(contractData.startTime) && parseInt(now) < parseInt(contractData.endTime) ? <button className="btn dtl_btn" id="buyButton" onClick={buyDeal}
                                style={{ marginRight: '10px' }}>
                                Buy Deal
                            </button> : null}

                            {Object.keys(contractData).length === 0 ? (<button className="btn offer_btn" data-bs-toggle={userIdCheck !== null && userIdCheck !== '' && userIdCheck !== undefined ? 'modal' : ''} data-bs-target="#makeoffer_modal" onClick={openOfferBox}>
                                <span style={{ fontSize: "17px" }} className="d-block w-100">Make a Offer</span>
                                {/* <span style={{ fontSize: "14px", color: "#6F7D95 " }}>hello</span> */}
                            </button>) : (
                                <button className="btn offer_btn" >
                                    <div style={{ fontSize: "17px" }} className="d-block w-100"><CountDown end={parseInt(contractData.endTime)} start={parseInt(contractData.startTime)} /></div>
                                    {/* <span style={{ fontSize: "14px", color: "#6F7D95 " }}>hello</span> */}
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <div className="ItemActivity">
                <ItemActivity tokenId={buyTokneId} />
            </div>
        </section>
        <div className="modal commonModal oneForm fade" id="reportM" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" id="reportclose" aria-label="Close"><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Report this item</h3>
                            <form action=" " className="m-0">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">Reason:</label>
                                    <select name="" className="form-control" id="" value={reason} onChange={(event) => { setReason(event.target.value) }}>
                                        <option value=''>Select a reason</option>
                                        <option value="Purchase">Purchase</option>
                                        <option value="Support">Support</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="text-gray fs-6">Additional comments:</label>
                                    <textarea name="" id="" className="form-control" value={comments} onChange={(event) => { setComments(event.target.value) }} placeholder="Explain why you are concerned about it."></textarea>
                                </div>
                            </form>
                            <div className="btnM">
                                <button className="btn bid_btn" onClick={handleReport}>Report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade commonModal oneForm" id="makeoffer_modal" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" id="offer_close" aria-label="Close"><i className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Make a Offer</h3>
                            <form action=" " className="">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">This offer is available for more then <span>{parseInt(lastOfferAmount) / 10 ** 18} {Oneto11}</span></label>
                                    <input type="number" min="0" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} className="form-control" value={offerValue} onChange={(event) => { setOfferValue(event.target.value) }} id="bidI" />
                                </div>
                            </form>
                            <div className="hr hrbx"></div>
                            <div className="btnM">
                                <button className="btn bid_btn" onClick={makeOfferhandle}>Make a Offer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade commonModal" id="buyApprove" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton" onClick={closebutton}><i
                            className="ri-close-line"></i></button>
                        <div className="oneForm  m-history-wrapper">
                            <h3 className="heading fs-4 mb-4 text-capitalize">Approve Token</h3>
                            <div className="form-group mt-2" style={{ display: "none" }} id="rebalaId">
                                <p style={{ color: "red", border: "1px solid red", borderRadius: "5px", padding: "0 10px", textAlign: "center", margin: "20px 0" }}>Please approve tokens first !</p>
                                <label htmlFor="bidI" className="text-gray fs-6">Approve Your Token</label>
                                <input type="number" className="form-control mb-2" min="0" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={reqBalance} onChange={(event) => { setReqBalance(event.target.value) }} />
                                <div className="hr hrbx"></div>
                                <div className="btnM">
                                    <button className="btn bid_btn " id="approveID" onClick={approveBal}>Approve</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}

Buynow.propTypes = propTypes;
Buynow.defaultProps = defaultProps;
// #endregion

export default Buynow;