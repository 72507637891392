/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState}from 'react';
import CountDown from '../CountDown';
import LikesCount from './LikesCount';
import { Oneto11,tokenToUsd } from '../../Details';


export default function ProfileCard(props) {

    const {item} = props;
    const [buyUrl, setBuyUrl] = useState( `/BuyNow?tokenNo=${item.nftId}`);
    const [bidUrl, setBidUrl] = useState(`/PlaceBid?tokenNo=${item.nftId}`);
    
    
  return (
    <div>
        <div className="card" key = {item.nftId}>
            {item.userAuctiondata ? <div className="time_btn bid_btn"><CountDown end={parseInt(item.endTime)} start={parseInt(item.startTime)} /></div> : null}
            <div style={{position:"relative"}} >
                <a href="" >
                    <div className="cardImg">
                        <img src={item.userAuctiondata ? (JSON.parse(item.userAuctiondata[0].ipfs)).image : item.userSaledata ? (JSON.parse(item.userSaledata[0].ipfs)).image : item.userDealdata ? ((JSON.parse(item.userDealdata[0].ipfs)).image) : null} className="card-img-top" alt="..." />
                    </div>
                </a>
                <LikesCount nftId = {item.nftId}/>
            </div>
            
           
            
            <div className="card-body">
                <p className="card-text text-decoration-none">
                    <span>{item.userAuctiondata ? (JSON.parse(item.userAuctiondata[0].ipfs)).name : item.userSaledata ? (JSON.parse(item.userSaledata[0].ipfs)).name : item.userDealdata ? (JSON.parse(item.userDealdata[0].ipfs)).name : null}</span>
                    <div className='d-flex justify-content-between w-100 align-items-baseline'>
                        <span className='pricePlusLike' >Price: { Oneto11 }{item.amount/10**18} / <b>~${tokenToUsd(item.amount/10**18)}</b></span>
                    </div>
                    
                </p>
                <div className="history_View">
                    {item.userAuctiondata ? <a href = {bidUrl} className="bid_btn btn" >
                        Place Bid
                    </a> : item.userDealdata ?  <a href = {buyUrl} className="bid_btn btn">
                        Deal
                    </a> : item.userSaledata ? <a  href = {buyUrl} className="bid_btn btn" >
                        Buy
                    </a> : null}
                </div>
            </div>
        </div>
    </div>
  )
}
