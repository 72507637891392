/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import swal from 'sweetalert';
import $ from "jquery";
import { formatCount, baseUrl } from '../../Details';


export default function LikesCount(props) {

    const { nftId } = props
    const history = useHistory();
    const [likes, setLikes] = useState(0);
    const [liked, setLiked] = useState(false);
    const [user, setUser] = useState('');

    const checkLike = () => {
        let userId = sessionStorage.getItem("userid");
        let Id = nftId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {

                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });


                var config = {
                    method: 'post',
                    url: `${baseUrl}/check-likes`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            if (response.data.data === 1) {
                                setLiked(true);
                            } else {
                                setLiked(false);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        }
    }

    const numLikes = () => {
        var config = {
            method: 'get',
            url: `${baseUrl}/count-likes?nftid=${nftId}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    setLikes(response.data.data);
                }

            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const addLikes = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = nftId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'likedby': userId,
                    'likedto': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/add-likes`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            swal("Liked successfully", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setLiked(true);
                            numLikes();
                        } else {
                            if (response.status === 203) {
                                var data = qs.stringify({
                                    'likedby': userId,
                                    'likedto': Id
                                });
                                var config = {
                                    method: 'post',
                                    url: `${baseUrl}/remove-likes`,
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    data: data
                                };

                                axios(config)
                                    .then(function (response) {
                                        if (response.data.success === true) {
                                            swal("Unliked successfully", { button: false, icon: "success", timer: 2000 });
                                            $('.ajax-loader').css("display", "none");
                                            setLiked(false);
                                            numLikes();
                                        }
                                    })
                                    .catch(function (error) {
                                        console.error(error);
                                        $('.ajax-loader').css("display", "none");
                                    });
                            }
                            else {
                                swal(response.data.msg, { button: false, icon: "success", timer: 2000 });
                                $('.ajax-loader').css("display", "none");
                            }

                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to like Nft", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }

    useEffect(() => {
        numLikes();
    }, [nftId]);

    useEffect(() => {
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== undefined && userId !== '') {
            setUser(userId)
        }
    }, [sessionStorage.getItem("userid")]);

    useEffect(() => {
        checkLike();
    }, [liked, user]);

    return (

        <div className='views_box_btn' style={{ position: "absolute", bottom: "10px", right: "35px" }}>
            <div className="views_bx" onClick={addLikes} >
                <div className="hrt_cnt" style={{ padding: "0px 12px" }}>
                    {liked ? <i className="ri-heart-fill"></i> : <i className="ri-heart-line"></i>}{formatCount(likes)}
                </div>
            </div>
        </div>

    )
}
