/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { baseUrl, imgsrc,Oneto11,profileUrl,chainUrl } from '../../Details';
import avtar1 from '../../Images/avtar1.png'
import axios from 'axios';
import Moment from 'react-moment';

export default function HistoryItem({ onClose, token }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    const [state, setState] = useState({
        loader: true,
        history: []
    })

    useEffect(() => {
        axios.post(`${baseUrl}/getBidHistory`, {
            nftId: token
        }).then((res) => {
            let dt = [];
            if (res.data.success && res.data.data)
                dt = res.data.data;
            setState(preState => ({
                ...preState,
                loader: false,
                history: dt
            }))
           
        }).catch((err) => {

        })
    }, [])
    
    const convertPrice = (n) => {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }
    return (
        <div className="modal fade commonModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false"
            style={{
                display: "block",
                background: "rgba(0, 0, 0, 0.3)",
                opacity: 1
            }}
        >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" onClick={onClose}><i
                            className="ri-close-line"></i></button>

                        {
                            state.loader ?

                                <div className="ajax-loaderCtn" style={{ display: "flex" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <p id="loaderText" style={{ color: "#000" }}>Loading....</p>
                                        <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt="" />
                                    </div>
                                </div>

                                :
                                <div className="m-history-wrapper"
                                    style={{
                                        maxHeight: "320px",
                                        "overflow-x": "hidden"
                                    }}>
                                    <h3 className="heading fs-5 text-capitalize mb-3">Bid History</h3>
                                    <div className="bid_box cardHistoy d-flex align-items-center justify-content-center flex-column">
                                        
                                        {state.history.map((item) => {
                                            const user = item.userData && item.userData.length > 0 ? item.userData[0] : ''
                                            const bidder = item.bidder;
                                            return (
                                                <div className="bid_box_r w-100" key={item._id}>
                                                    <div className="userC" style={{ backgroundImage: `url(${item.userData && item.userData.length > 0 ? imgsrc+user.profileImage : avtar1})` }}  ></div>
                                                    <div className="bid_box_r_d">
                                                        <p className="headingC">Bid Placed {Oneto11} {convertPrice(item.amount / 1000000000000000000)} by {user ? <a href={profileUrl+user.displayName}>{user.displayName}</a> : (<div className="copyLink d-flex">
                                                        <a href= {chainUrl+bidder} rel="noreferrer" target="_blank">
                                                <input
                                                    className="m-0" style={{ fontWeight: "500", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                    type="text"
                                                    defaultValue={bidder}
                                                    placeholder="Type some text here"
                                                    disabled = {true}
                                                />
                                                </a>
                                                <CopyToClipboard text={bidder} onCopy={() => { swal("Address Copied !!", { button: false, icon: "success", timer: 2000 }); }}>
                                                    <div className="copy-area">
                                                        <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                                    </div>
                                                </CopyToClipboard>
                                            </div>)}</p>
                                                        <Moment date={item.date} format={"DD/MM/YYYY, hh:mm"} />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    {!state.loader && state.history.length === 0 && (
                                        <div className="cardHistoy d-flex align-items-center justify-content-center flex-column">
                                            Bid not found.
                                        </div>
                                    )}
                                </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}
