/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React, { useEffect, useState } from 'react';
import { useLocation, Link} from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { baseUrl,contract,Oneto11,tokenToUsd} from '../Details';
// #endregion
const propTypes = {};
const defaultProps = {};

const AllSearch = () => {
  const location = useLocation();
  const [allNft, setAllNft] = useState([]);
  const [searchtext, setSearchVal] = useState();

  // #region function
  const getDetails =(Arr)=>{
    let dataArr=[];
    return new Promise((resolve,reject)=>{
      Arr.map(async (item)=>{
        let market = await contract.methods.checkMarket(item.NftId).call();
        if(market === 'Auction'){
          item["type"] = 'Auction'
          let details = await contract.methods.getAuction(item.NftId).call();
          item["price"] = details.price;
        }else if(market === 'Sale'){
          item["type"] = 'Sale';
          item["price"] = await contract.methods.getSellTokenPrice(item.NftId).call();
        }else if(market === 'Deal'){
          item["type"] = 'Deal';
          let details= await contract.methods.dealDetailsByTokenId(item.NftId).call();
          item["price"] = details.price;
        }else{
          item["type"] = 'Sold';
        }
        dataArr.push(item);
      })

      resolve(dataArr)
    })
    
  }
  const serachNfts = (searchVal) => {
    axios.post(`${baseUrl}/search-nft`, {
      search: searchVal
    }).then(async(result) => {
    
      if (result.data.success) {
        let data = result.data.data;
        await Promise.all(data.map(async (item)=>{
          let market = await contract.methods.checkMarket(item.NftId).call();
          if(market === 'Auction'){
            item["type"] = 'Auction'
            let details = await contract.methods.getAuction(item.NftId).call();
            item["price"] = details.price;
          }else if(market === 'Sale'){
            item["type"] = 'Sale';
            item["price"] = await contract.methods.getSellTokenPrice(item.NftId).call();
          }else if(market === 'Deal'){
            item["type"] = 'Deal';
            let details= await contract.methods.dealDetailsByTokenId(item.NftId).call();
            item["price"] = details.price;
          }else{
            item["type"] = 'Sold';
          }
        }))
        setAllNft(data)
      } else {
        setAllNft([])
      }
    }).catch((err) => {
      setAllNft([])
      console.error(err)
    })
  }
  // #endregion
  
  // #useeffect region
  useEffect(() => {
    let searchVal = location.search.split("=")[1] || ''
    setSearchVal(decodeURI(searchVal))
    serachNfts(searchVal)
  }, []);
  // #endregion
  return <div>
    <Header />
    <div className='exploreWrapper' style={{}}>
      <div className="cstm-container">
        <div className="innr_bck_box">
          <a className="btn viewBtn" href="/">
            <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>
            <p className="m-0">Back to home</p>
          </a>
        </div>
        <h2 style={{ margin: "0 20px" }}>All search result : {searchtext}</h2>
        {allNft && allNft.length > 0 && (
          // <div className='container all-nft row'>
          <div className="playerCard cardX">
            <div className="innerPlayer">
              {allNft.map((item) => {
                const data = JSON.parse(item.ipfs);
                return (
                  <a href={ item.type === 'Auction' ? `/PlaceBid?tokenNo=${item.NftId}` :  item.type === 'Sale' || item.type === 'Deal' ? `/BuyNow?tokenNo=${item.NftId}`: `/SoldOut?tokenNo=${item.NftId}`} key={item._id} >
                    <div className="card">
                      <div className="cardImg">
                        <img src={data.image} className="card-img-top" alt="..." />
                      </div>
                      <div className="card-body">
                        <p  className="card-text text-decoration-none">
                          <span>{data.name}</span>
                          {item.price ?<span> Price: {Oneto11}{item.price/10**18} / <b>~${tokenToUsd(item.price/10**18)}</b></span>:null}
                        </p>
                        <div className="history_View justify-content-end">
                          {item.type === 'Auction' ? <a className="bid_btn btn" href = {`/PlaceBid?tokenNo=${item.NftId}`} >
                              Place Bid
                          </a> : item.type === 'Sale' || item.type === 'Deal' ? <a className="bid_btn btn" href = {`/BuyNow?tokenNo=${item.NftId}`} >
                              Buy
                          </a> : <a className="bid_btn btn" style={{backgroundColor:"red",pointerEvents:"none"}} href = {`/SoldOut?tokenNo=${item.NftId}`} >
                              Sold
                          </a>}
                        </div>
                      </div>

                      
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
        )}

    {allNft && allNft.length === 0 && (
            // <div className='container all-nft row'>
            <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", padding: "72px", alignItems: "center", justifyContent: "center", display: "flex" }}>
            No result found
        </div>
            )}
      </div>
    </div>
    <Footer />
  </div>;
}



AllSearch.propTypes = propTypes;
AllSearch.defaultProps = defaultProps;
// #endregion

export default AllSearch;