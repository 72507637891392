/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";


const DealCountDown = (props) => {

    let {start, end} = props;
    const now = Math.floor(((new Date()).getTime())/1000);
    const calculateTimeLeft = () => {
     
      let number = 0;
       if(start > now){
        number = Number(start) - Number(now);
        }else{
        number = Number(end) - Number(now);
       }
      return number;
    };
    
  let [count, setCount] = useState(calculateTimeLeft());
  useEffect(() => {
    setTimeout(() => {
      setCount(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (Number(count) > 0) {
        setCount(--count);
      }
    }, 1000);
    return () => clearInterval(interval);

  }, [count]);

  return (
   <><span style={{ textAlign: "center" }}>
      {/* {count > 0 ? (parseInt(start) > parseInt(now)) ? ('Deal Start ') : ('Deal End ') : null} */}
      {count > 0 ? <span>{Math.floor(count / (60 * 60 * 24)) === 0 ?  null : Math.floor(count / (60 * 60 * 24))+'d '}{Math.floor((count % (60 * 60 * 24)) / (60 * 60))+'h'} : {Math.floor((count % (60 * 60)) / (60))+'m'} : {Math.floor((count % (60)))+'s'}</span> : <span>Time's up!</span>}
    </span></>
  );
};
export default DealCountDown;