import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer'
import { ontop } from "../Details";

export default function AboutUs() {
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);
    return (
        <div>
            <Header />
            <section className="aboutWrapper exploreWrapper" id="aboutbx">
                <div className="container ">
                    <div className="text-center ">
                        <h1 className="heading ">ABOUT US</h1>
                        <p className="para text-gray lh-lg">OneTo11 is a gaming ecosystem with games for every gamer persona and implementing the Play-To-Earn model across the platform.
                        </p>
                        <p className="mt-4 para text-gray lh-lg">We are using the power of DeFi and NFTs to facilitate consistent income generation for gamers via gameplay and time investment. Our ecosystem comprises Fantasy Sports app, Mobile gaming App, Gamified learning App, RPG/Metaverse game,
                            native crypto token of the platform and NFT marketplace.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
