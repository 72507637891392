/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import $ from 'jquery'
import axios from 'axios'
import Header from './Header'
import Footer from './Footer'
import { ontop, nftMint721Contract, baseUrl, contract } from '../Details';
import NftCard from './common/NftCard';
import MyNftCard from './common/MyNftCard';
//#endregion

const propTypes = {};
const defaultProps = {};

const MyItems = () => {

    const [allNftDetails, setAllNftDetails] = useState([]);
    const [dealDetails, setDealDetails] = useState([]);
    const [auctionDetails, setAuctionDetails] = useState([]);
    const [saleDetails, setSaleDetails] = useState([]);
    const [reload, setReload] = useState(false);

    // #region functions
    const myItemDetails = async () => {
        $('.ajax-loader').css("display", "flex");
        let address = sessionStorage.getItem("address");
        await nftMint721Contract.methods.tokenOwner(address).call().then((nftid) => {
            let idArr = [];
            nftid.map((id)=>{
                idArr.push(Number(id))
                return idArr;
            })
            var data = qs.stringify({
                'id': JSON.stringify(idArr),
                'address':address
            });
           
            var config = {
                method: 'post',
                url: `${baseUrl}/get-user-nft`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };

            axios(config)
                .then(async function (response) {
                    if(response.data.success === true){
                        let allData = response.data.data;
                        if(Object.keys(allData).length === 0 && allData.constructor === Object) {
                            $('.ajax-loader').css("display", "none");
                            setAllNftDetails([]);
                        }else{
                            setAllNftDetails(allData)
                            $('.ajax-loader').css("display", "none");
                        }
                        
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    $('.ajax-loader').css("display", "none");
                });
        })
    }
    const userAuction = () => {
        let address = sessionStorage.getItem("address");
        var data = qs.stringify({
            'seller': address 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserAuctionData`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
              if(response.data.success === true){
                  let obj = response.data.data
                  if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                    setAuctionDetails([])
                  }else{
                    setAuctionDetails(response.data.data)
                  }
                
              }
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    const userSale = () => {
        let address = sessionStorage.getItem("address");
        var data = qs.stringify({
            'seller': address 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserSaleData`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            if(response.data.success === true){
               
                let obj = response.data.data
                  if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                    setSaleDetails([])
                  }else{
                    setSaleDetails(response.data.data)
                  }
                
              }
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    const userDeal = () =>{
        let address = sessionStorage.getItem("address");
        var data = qs.stringify({
            'seller': address 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserDealData`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
              if(response.data.success === true){
                  let obj = response.data.data
                  if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                    setDealDetails([])
                  }else{  
                    setDealDetails(response.data.data)
                  }
                
              }
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    //#endregion

    // #useeffect region

    useEffect(() => {
        myItemDetails();  
    }, []);
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
        // myItemDetails();
        userSale();
        userAuction();
    }, []);
    
    useEffect (()=>{
        userDeal();
    },[]);
    //#endregion

    return <div>

        <Header/>
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
                <section className="exploreWrapper">
                    <div className="cstm-container">
                        <div className="innr_bck_box">
                            <div className="ibb_l">
                                <Link className="btn viewBtn" to="/">
                                <i className="ri-arrow-left-line" style={{fontSize: "22px"}}></i>Back to home
                                </Link>
                            </div>
                            <div className="ibb_r">
                                <Link className="bg-primary text-white" to="/CreateNewSale">Create New Sale</Link>
                                {/* <Link className="sr_btn" to="/">Submit request</Link> */}
                            </div>
                        </div>
                        <div className="innerExplore exptab_bx">
                            <h2 className="heading">My items</h2>
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-6 active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-6" id="pills-sale-tab" data-bs-toggle="pill" data-bs-target="#pills-sale" type="button" role="tab" aria-controls="pills-sale" aria-selected="false">For Sale</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-6" id="pills-EvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-EvolutionX" type="button" role="tab" aria-controls="pills-EvolutionX" aria-selected="false">Not For Sale</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                                <div className="playerCard cardX explore_b">
                                    <div className="innerPlayer">
                                        {/* all data */}
                                        {allNftDetails.length > 0 ?(allNftDetails.map((item) => (
                                        <NftCard key = {item.NftId} item = {item}/>
                                        ))) : null}
                                        {auctionDetails.length > 0 ?(auctionDetails.map((item) => (
                                        <MyNftCard key = {item.nftId} item = {item} callBack={setReload} />
                                        ))) : null}
                                        {saleDetails.length > 0 ? (saleDetails.map((item) => (
                                        <MyNftCard key = {item.nftId} item = {item} callBack={setReload}/>
                                        ))) : null}
                                        {dealDetails.length > 0 ? (dealDetails.map((item) => (
                                        <MyNftCard key = {item.nftId} item = {item} callBack={setReload}/>
                                        ))) : null}
                                         {auctionDetails.length === 0 && saleDetails.length === 0 && dealDetails.length === 0 && allNftDetails.length === 0 ? <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-sale" role="tabpanel" aria-labelledby="pills-sale-tab">
                        <div className="playerCard cardX explore_b">
                            <div className="innerPlayer">
                            {auctionDetails.length > 0 ?(auctionDetails.map((item) => (
                                <MyNftCard key = {item.nftId} item = {item} callBack={setReload}/>
                            ))) : null}
                            {saleDetails.length > 0 ? (saleDetails.map((item) => (
                                <MyNftCard key = {item.nftId} item = {item} callBack={setReload}/>
                            ))) : null}
                            {dealDetails.length > 0 ? (dealDetails.map((item) => (
                            <MyNftCard key = {item.nftId} item = {item} callBack={setReload}/>
                            ))) : null}
                            </div>
                            {auctionDetails.length === 0 && saleDetails.length === 0 && dealDetails.length === 0 ?  <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div> : null}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-EvolutionX" role="tabpanel" aria-labelledby="pills-EvolutionX-tab">
                        <div className="playerCard cardX explore_b">
                            <div className="innerPlayer">
                            {allNftDetails.length > 0 ?(allNftDetails.map((item) => (
                                <NftCard key = {item.NftId} item = {item} />
                            ))) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
       
        
    </div>;
}

MyItems.propTypes = propTypes;
MyItems.defaultProps = defaultProps;
// #endregion

export default MyItems;