import React,{useState, useEffect} from 'react'

function ScrollTop() {
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }, []);
    // This function will scroll the window to the top
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
      });
    };
  return (
    <div>
        {showButton && (
        <button onClick={scrollToTop} className="back-to-top scrollToTop" style={{    border: "0", background: "#fff0"}}>
         <img src="img/arrow_top.png" alt="" style={{width:"41px"}}/>
        </button>
        )}
    </div>
  )
}

export default ScrollTop