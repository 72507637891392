/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import $ from 'jquery';
import swal from 'sweetalert'
import Footer from './Footer';
import Header from './Header';
import CountDown from './CountDown';
import { baseUrl, contract,Oneto11,tokenToUsd } from '../Details';
// #endregion

const Claim = () => {

    const [bidData, setBidData] = useState([]);
    const [offerData, setOfferData] = useState([]);

    // #region functions
    const userBids = () =>{
        let bidder = sessionStorage.getItem('address');
        if(bidder === undefined || bidder === null || bidder === ''){
            swal("Please connect to the metamask", { button: false, icon: "warning", timer: 1000 });
        }
        var data = qs.stringify({
            'bidder': bidder 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserbids`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(async function (response) {
              var userBidData = (response.data.data)
              userBidData = [...new Map(userBidData.map((item) => [item['nftId'], item])).values()]
             
              var dataArr = [];
              var dataObj = {};
              for(let i = 0; i < userBidData.length; i++ ){
                
                // eslint-disable-next-line no-loop-func
                await contract.methods.getpending_claim_auction(bidder, userBidData[i].nftId).call().then(async (data)=>{
                    if(parseInt(data) > 0){
                        let autionDetails = await contract.methods.getAuction(userBidData[i].nftId).call();
                        let endtime = parseInt(autionDetails.endTime);
                        let starttime = parseInt(autionDetails.startTime);

                        dataObj['nftId'] = userBidData[i].nftId;
                        dataObj['ipfs'] = userBidData[i].ipfs;
                        dataObj['bidder'] = userBidData[i].bidder;
                        dataObj['price'] = data;
                        dataObj['endtime'] = endtime;
                        dataObj['starttime'] = starttime;
                        
                        dataArr.push(dataObj);
                        dataObj = {};
                    }
                    
                })
                    
              }
            setBidData(dataArr)
          })
          .catch(function (error) {
            console.error(error);
            $('.ajax-loader').css("display", "none");
          });
          
    }
    const userOffers = () =>{
        let offerer = sessionStorage.getItem('address');
        if(offerer === undefined || offerer === null || offerer === ''){
            swal("Please connect to the metamask", { button: false, icon: "warning", timer: 1000 });
        }
        var data = qs.stringify({
            'offerer': offerer 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserOffers`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(async function (response) {
            var userofferData = (response.data.data)
            userofferData = [...new Map(userofferData.map((item) => [item['nftId'], item])).values()]
           
            var dataArr = [];
            var dataObj = {};
            for(let i = 0; i < userofferData.length; i++ ){
              
              // eslint-disable-next-line no-loop-func
              await contract.methods.getpending_claim_offer(offerer, userofferData[i].nftId).call().then((data)=>{
                  if(parseInt(data) > 0){
                      dataObj['nftId'] = userofferData[i].nftId;
                      dataObj['ipfs'] = userofferData[i].ipfs;
                      dataObj['offerer'] = userofferData[i].offerer;
                      dataObj['price'] = data;
                      dataArr.push(dataObj);
                      dataObj = {};
                  }
                  
              })
                  
            }
            setOfferData(dataArr)
              
          })
          .catch(function (error) {
            console.error(error);
            $('.ajax-loader').css("display", "none");
          });
    }

    const claimBid = async (add, token) => {
        
        if(add === "" || add === null || add === undefined || add !== (sessionStorage.getItem('address'))){
            swal({ title: "Please connect to the wallet", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }
        if(token === "" || token === null || token === undefined){
            swal({ title: "Something went wrong try again letter", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }else{
            
            let auctionDetails = await contract.methods.getAuction(token).call();
            if(auctionDetails.highestBidder === add){
                await contract.methods.auctionClaim(token).send({from : add},function (err, result){
                    if (err) {
                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                    }
                    if (result) {
                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                    }
                }).on('receipt', function (receipt) {
                    if (receipt.status === true) {
                        $('.ajax-loader').css("display", "none");
                        swal({ title: "Your bid is successfully claimed", icon: "success", background: "#808080", timer: 2000 });
                        userBids();
                        userOffers();
                    } else {
                        $('.ajax-loader').css("display", "none");
                        swal("Transaction Error", { icon: "error", timer: 1000 });
                    }
                })
            }else{
                await contract.methods.auctionPendingClaim(token).send({from : add},function (err, result){
                    if (err) {
                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                    }
                    if (result) {
                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                    }
                }).on('receipt', function (receipt) {
                    if (receipt.status === true) {
                        $('.ajax-loader').css("display", "none");
                        swal({ title: "Your bid is successfully claimed", icon: "success", background: "#808080", timer: 2000 });
                        userBids();
                        userOffers();
                    } else {
                        $('.ajax-loader').css("display", "none");
                        swal("Transaction Error", { icon: "error", timer: 1000 });
                    }
                })
            } 
            
        }
    }

    const claimofferer = async (add, token) => {
        if(add === "" || add === null || add === undefined || add !== (sessionStorage.getItem('address'))){
            swal({ title: "Please connect to the same wallet you had bidded with", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }
        if(token === "" || token === null || token === undefined){
            swal({ title: "Something went wrong try again letter", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }else{
            await contract.methods.offerClaim(token).send({from : add},function (err, result){
                if (err) {
                    swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                }
                if (result) {
                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                    setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                }
            }).on('receipt', function (receipt) {
                if (receipt.status === true) {
                    $('.ajax-loader').css("display", "none");
                    swal({ title: "Your offer is successfully claimed", icon: "success", background: "#808080", timer: 2000 });
                    userBids();
                    userOffers();
                } else {
                    $('.ajax-loader').css("display", "none");
                    swal("Transaction Error", { icon: "error", timer: 1000 });
                }
            })
        }
    }

    const cancelBid = async(add, token) => {
        if(add === "" || add === null || add === undefined || add !== (sessionStorage.getItem('address'))){
            swal({ title: "Please connect to the wallet", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }
        if(token === "" || token === null || token === undefined){
            swal({ title: "Something went wrong try again letter", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }else{
            let bidEnable = await contract.methods.getCancelBidEnabled().call();
            if(bidEnable){
                await contract.methods.cancelBid(token).send({from : add},function (err, result){
                    if (err) {
                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                    }
                    if (result) {
                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                    }
                }).on('receipt', function (receipt) {
                    if (receipt.status === true) {
                        $('.ajax-loader').css("display", "none");
                        swal({ title: "Your bid is cancelled", icon: "success", background: "#808080", timer: 2000 });
                        userBids();
                        userOffers();
                    } else {
                        $('.ajax-loader').css("display", "none");
                        swal("Transaction Error", { icon: "error", timer: 1000 });
                    }
                })
            }else{
                swal("Cancel Bid is disabled", { icon: "error", timer: 1000 });
            }
        }
    }

    const cancelOffer = async(add, token) =>{
        if(add === "" || add === null || add === undefined || add !== (sessionStorage.getItem('address'))){
            swal({ title: "Please connect to the wallet", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }
        if(token === "" || token === null || token === undefined){
            swal({ title: "Something went wrong try again letter", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }else{
            let offerEnable = await contract.methods.getCancelOfferEnabled().call();
            if(offerEnable){
                await contract.methods.cancelOffer(token).send({from : add},function (err, result){
                    if (err) {
                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                    }
                    if (result) {
                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                    }
                }).on('receipt', function (receipt) {
                    if (receipt.status === true) {
                        $('.ajax-loader').css("display", "none");
                        swal({ title: "Your offer is cancelled", icon: "success", background: "#808080", timer: 2000 });
                        userBids();
                        userOffers();
                    } else {
                        $('.ajax-loader').css("display", "none");
                        swal("Transaction Error", { icon: "error", timer: 1000 });
                    }
                })
            }else{
                swal("Cancel Offer is disabled", { icon: "error", timer: 1000 });
            }
            
        }
    }
    // #endregion

    // #useeffect region
    useEffect(()=>{
        userBids();
        userOffers();
    },[]);
    // #endregion
    return <div>
        <Header />
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <section className="exploreWrapper">
            <div className="cstm-container">
                <div className="innr_bck_box">
                            <div className="ibb_l">
                                <Link className="btn viewBtn" to="/">
                                <i className="ri-arrow-left-line" style={{fontSize: "22px"}}></i>Back to home
                                </Link>
                            </div>
                            <div className="ibb_r">
                            </div>
                </div>
                <div className="innerExplore exptab_bx">
                    <h2 className="heading">Claims</h2>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link fs-6 active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link fs-6" id="pills-CricX-tab" data-bs-toggle="pill" data-bs-target="#pills-CricX" type="button" role="tab" aria-controls="pills-CricX" aria-selected="false">Offer</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link fs-6" id="pills-EvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-EvolutionX" type="button" role="tab" aria-controls="pills-EvolutionX" aria-selected="false">Bid</button>
                        </li>                         
                    </ul>
                </div>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                        <div className="playerCard cardX explore_b">
                            <div className="innerPlayer">
                            {bidData.length > 0 ? (bidData.map((item) => (
                                <div className="card" key = {item.nftId}>
                                    <div className="time_btn bid_btn"><CountDown end={item.endtime} start={item.starttime} /></div>
                                <div className="cardImg">
                                    <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                                </div>
                                <div className="card-body">
                                    <p className="card-text text-decoration-none">
                                        <span> {(JSON.parse(item.ipfs)).name} </span>
                                        <span>Total claim: {Oneto11}{(parseInt(item.price)/10**18)}</span>
                                    </p>
                                    <div className="history_View">
                                        {Math.floor(((new Date()).getTime())/1000) > Number(item.endtime) ? <button className="bid_btn btn"  onClick = {() => {claimBid(item.bidder, item.nftId)}}>Claim Bid</button>:null}                                         
                                        <button className="bid_btn btn" onClick = {() => {cancelBid(item.bidder, item.nftId)}}>Cancel Bid</button>
                                    </div>
                                </div>
                            </div>
                            ))) : null}

                            {offerData.length > 0 ? (offerData.map((item) => (
                                <div className="card" key = {item.nftId}>
                                <div className="cardImg">
                                    <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                                </div>
                                <div className="card-body">
                                    <p className="card-text text-decoration-none">
                                        <span> {(JSON.parse(item.ipfs)).name} </span>
                                        <span>Total claim: {Oneto11}{(parseInt(item.price)/10**18)} </span>
                                        <span>Total claim: ${tokenToUsd(parseInt(item.price)/10**18)} </span>
                                    </p>
                                    <div className="history_View">
                                        <button className="bid_btn btn" onClick = {() => {claimofferer(item.offerer, item.nftId)}}>Claim offer</button>                                             
                                        <button className="bid_btn btn" onClick = {() => {cancelOffer(item.offerer, item.nftId)}}>Cancel offer</button>
                                    </div>
                                </div>
                            </div>
                            ))) : null}  
                            {bidData.length === 0 && offerData.length === 0 ? <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>:null}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-CricX" role="tabpanel" aria-labelledby="pills-CricX-tab">
                        <div className="playerCard cardX explore_b">
                            <div className="innerPlayer">
                            {offerData.length > 0 ? (offerData.map((item) => (
                                <div className="card" key = {item.nftId}>
                                <div className="cardImg">
                                    <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                                </div>
                                <div className="card-body">
                                    <p className="card-text text-decoration-none">
                                        <span> {(JSON.parse(item.ipfs)).name} </span>
                                        <span>Total claim: {Oneto11}{(parseInt(item.price)/10**18)} </span>
                                        <span>Total claim: ${tokenToUsd(parseInt(item.price)/10**18)} </span>
                                    </p>
                                    <div className="history_View">
                                        <button className="bid_btn btn" onClick = {() => {claimofferer(item.offerer, item.nftId)}}>Claim offer</button>                                             
                                        <button className="bid_btn btn" onClick = {() => {cancelOffer(item.offerer, item.nftId)}}>Cancel Bid</button>
                                    </div>
                                </div>
                            </div>
                            ))) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}                                 
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-EvolutionX" role="tabpanel" aria-labelledby="pills-EvolutionX-tab">
                     <div className="playerCard cardX explore_b">
                            <div className="innerPlayer">
                            {bidData.length > 0 ? (bidData.map((item) => (
                                <div className="card" key = {item.nftId}>
                                    <div className="time_btn bid_btn"><CountDown end={item.endtime} start={item.starttime} /></div>
                                <div className="cardImg">
                                    <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                                </div>
                                <div className="card-body">
                                    <p className="card-text text-decoration-none">
                                        <span> {(JSON.parse(item.ipfs)).name} </span>
                                        <span>Total claim: {Oneto11}{(parseInt(item.price)/10**18)}</span>
                                    </p>
                                    <div className="history_View">
                                    {Math.floor(((new Date()).getTime())/1000) > Number(item.endtime) ? <button className="bid_btn btn"  onClick = {() => {claimBid(item.bidder, item.nftId)}}>Claim Bid</button>:null}                                           
                                    <button className="bid_btn btn" onClick = {() => {cancelBid(item.bidder, item.nftId)}}>Cancel Bid</button>
                                    </div>
                                </div>
                            </div>
                            ))) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>                                
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>;
}

// #endregion

export default Claim;