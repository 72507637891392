/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import $ from "jquery";
import axios from 'axios'
import Auction from './common/Auction';
import Sale from './common/Sale';
import PriceComponent from './common/PriceComponent';
import {ontop, baseUrl, contract, web3, chain} from '../Details';
import {allNFTSort ,getRunningAuctions,getRunningSale} from "../helpers/helper"
import Footer from './Footer';
import Header from './Header';
import SoldCrad from './common/SoldCard';
import NewListCard from './common/NewListCard';
var arr1 = false, arr2 = false;
var batch;
// #endregion

const propTypes = {};
const defaultProps = {};

const BuyOnly = () => {

    var address = sessionStorage.getItem("address");
    const [saledata, setSaledata] = useState([]);
    const [auctionData, setAuctionData] = useState([]);
    const [lowToHigh, setLowToHigh] = useState([]);
    const [highToLow, setHighToLow] = useState([]);
    const[soldData, setSoldData] = useState([]);
    const [newList, setNewList] = useState([]);
    const [cricxData, setCricxData] = useState([]);
    const [evolutionxData, setEvolutionxData] = useState([]);

    // #region functions
    const allNftDetails = async() => {
        let getArray = setInterval(async() => {
            if (arr1 && arr2) {
                clearInterval(getArray);
                const data = await allNFTSort(getArray, window.auction, window.sale);
                setCricxData(data.cricx);
                setEvolutionxData(data.evolutionx);
                setLowToHigh(data.LowToHigh);
                setHighToLow(data.HighToLow)
            }
        }, 5000);
    }
    // Get auction list
    const getAuctionList = async (AuctionLength) => {
        let batch2 = new web3.BatchRequest();
        let auctionArr = {};
        let AuctiontokenIdArr;
        let address = sessionStorage.getItem("address");
        if (AuctionLength === '0') {
            arr1 = true;
            $('.ajax-loader').css("display", "none");
        }
        for (let i = 0; i < AuctionLength; i++) {
            batch2.add(contract.methods.auctionDetails(i).call.request((address, (error, auctionInfo) => {
                if (error) throw error;
                
                auctionArr[auctionInfo.tokenId] = auctionInfo.auctionInfo;
                AuctiontokenIdArr = Object.keys(auctionArr);

                if (AuctionLength - 1 === i) {

                    var data = qs.stringify({
                        'id': JSON.stringify(AuctiontokenIdArr)
                    });

                    var config = {
                        method: 'post',
                        url: `${baseUrl}/nft`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            let res = response.data.data;
                            let NFTDetail = [];
                            let NFTSet = {};
                            for (var i = 0; i < res.length; i++) {
                                var now = Math.floor((new Date()).getTime() / 1000);
                                if(parseInt( auctionArr[AuctiontokenIdArr[i]]['endTime']) > now){
                                    NFTSet['id'] = AuctiontokenIdArr[i];
                                    NFTSet['ipfs'] = res[i].ipfs;
                                    NFTSet['details'] = auctionArr[AuctiontokenIdArr[i]];
                                    NFTSet['price'] = auctionArr[AuctiontokenIdArr[i]]['price'];
                                    NFTDetail.push(NFTSet);
                                    NFTSet = {};
                                }
                                
                            }
                            setAuctionData(NFTDetail);
                            window.auction = NFTDetail;
                            
                            arr1 = true;
                            $('.ajax-loader').css("display", "none");
                        })
                        .catch(function (error) {
                            console.error(error);
                            $('.ajax-loader').css("display", "none");
                        });
                }
            })));
        }
        batch2.execute();
    }
    // Get sale list
    const getSaleList = async (saleLength) => {
        let batch1 = new web3.BatchRequest();
        let arr = {};
        let tokenIdArr;
        let address = sessionStorage.getItem("address");
        if (saleLength === '0') {
            arr2 = true;
            $('.ajax-loader').css("display", "none");
        }
        for (let i = 0; i < saleLength; i++) {
            batch1.add(contract.methods.saleDetails(i).call.request((address, (error, saleInfo) => {
                if (error) throw error;

                arr[saleInfo.tokenId] = saleInfo.price;
                tokenIdArr = Object.keys(arr);
                if (saleLength - 1 === i) {

                    var data = qs.stringify({
                        'id': JSON.stringify(tokenIdArr)
                    });

                    var config = {
                        method: 'post',
                        url: `${baseUrl}/nft`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            let res = response.data.data;
                            let NFTDetail = [];
                            let NFTSet = {};
                            for (var i = 0; i < res.length; i++) {
                                NFTSet['id'] = tokenIdArr[i];
                                NFTSet['ipfs'] = res[i].ipfs;
                                NFTSet['price'] = arr[tokenIdArr[i]];
                                NFTDetail.push(NFTSet);
                                NFTSet = {};
                            }
                            window.sale = NFTDetail;
                            arr2 = true;
                            setSaledata(NFTDetail);
                            $('.ajax-loader').css("display", "none");

                        })
                        .catch(function (error) {
                            console.error(error);
                            $('.ajax-loader').css("display", "none");
                        });
                }


            })));
        }
        batch1.execute();

    }
    //sold nft details
    const soldnftdetails = () =>{
        var config = {
            method: 'get',
            url: `${baseUrl}/getSoldHistory`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
          };
          
          axios(config)
          .then(function (response) {
            if(response.data.success === true){
                let obj = response.data.data
                if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                    setSoldData([])
                }else{
                    setSoldData(response.data.data)
                }
                
            }
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    const Newlisted = () =>{
        var config = {
            method: 'get',
            url: `${baseUrl}/getnewlisted`,
            headers: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
             }
          };
          
          axios(config)
          .then(function (response) {
            var dataArr = [];
            var data = response.data.data;
            for(let i = 0; i < data.length; i++){
              if("AuctionToken" in data[i]){
                  
                  var now = Math.floor((new Date()).getTime() / 1000);
                  if(parseInt(data[i].endTime) > now){
                      dataArr.push(data[i])
                  }
              }else{
                  dataArr.push(data[i])
              }
            }  
              setNewList(dataArr);
              $('.ajax-loader').css("display", "none");
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    const getData = async() =>{
        const sale = await getRunningSale();
        const auction = await getRunningAuctions();
        
        setAuctionData(auction);
        setSaledata(sale);

        window.sale = sale
        window.auction = auction
        arr1 = true;arr2 = true;
        $('.ajax-loader').css("display", "none");
    }
    
    // #endregion

    // #useeffect region
    useEffect(() => {

        if(web3.givenProvider !== null && web3.givenProvider.chainId === chain){
            batch = new web3.BatchRequest();
            batch.add(contract.methods.totalSale().call.request(('0x0000000000000000000000000000000000000000', (error, totalSale) => { if (error) throw error; getSaleList(totalSale) })));
            batch.add(contract.methods.totalAuction().call.request(('0x0000000000000000000000000000000000000000', (error, totalAuction) => { if (error) throw error; getAuctionList(totalAuction) })));
            batch.execute();
        }else{
            getData()
        }
        allNftDetails();

    }, []);
    useEffect( () => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);
    // #endregion
    return <div>
        <Header />
        <div className="ajax-loader" style={{ display: "flex" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#000" }}>Loading....</p>
                <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt = ""/>
            </div>
        </div>
        <section className="exploreWrapper">
            <div className="cstm-container">
                <div className="innr_bck_box">
                    <Link className="btn viewBtn" to="/">
                        <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>
                        <p className="m-0">Back to home</p>
                    </Link>
                </div>
                <div className="innerExplore exptab_bx">
                    <h2 className="heading">Buy Only</h2>
                    <ul className="nav nav-pills animate__slideInDown animate__animated" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-CricX-tab" data-bs-toggle="pill" data-bs-target="#pills-CricX" type="button" role="tab" aria-controls="pills-CricX" aria-selected="false">CricX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-EvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-EvolutionX" type="button" role="tab" aria-controls="pills-EvolutionX" aria-selected="false">EvolutionX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-BuyOnly-tab" data-bs-toggle="pill" data-bs-target="#pills-BuyOnly" type="button" role="tab" aria-controls="pills-BuyOnly" aria-selected="false">Buy Only</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BidOnly-tab" data-bs-toggle="pill" data-bs-target="#pills-BidOnly" type="button" role="tab" aria-controls="pills-BidOnly" aria-selected="false">Bid Only</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-NewListed-tab" onClick={Newlisted} data-bs-toggle="pill" data-bs-target="#pills-NewListed" type="button" role="tab" aria-controls="pills-NewListed" aria-selected="false">New Listed</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-SOLD-tab" onClick={soldnftdetails} data-bs-toggle="pill" data-bs-target="#pills-SOLD" type="button" role="tab" aria-controls="pills-SOLD" aria-selected="false">SOLD</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-High-tab" data-bs-toggle="pill" data-bs-target="#pills-High" type="button" role="tab" aria-controls="pills-High" aria-selected="false">High to Low Price</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-Low-tab" data-bs-toggle="pill" data-bs-target="#pills-Low" type="button" role="tab" aria-controls="pills-Low" aria-selected="false">Low to High Price</button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                        <div className="playerCard cardX animate__fadeIn animate__animated">
                            <div className="innerPlayer" id="All">
                                {/* Auction Nft */}
                                {auctionData.length > 0 ? auctionData.map((item) => (
                                    <Auction key={item.id} item={item} />
                                )) : null}
                                {/* sale NFT */}
                                {saledata.length > 0 ? saledata.map((item) => (
                                    <Sale key={item.id} item={item} />
                                )) : null}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-CricX" role="tabpanel" aria-labelledby="pills-CricX-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {cricxData.length > 0 ? cricxData.map((item) => (
                                    <>
                                        { item.details ? <Auction key={item.id} item={item} /> : <Sale key={item.id} item={item} />}
                                    </>
                                    )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    No result found
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-EvolutionX" role="tabpanel" aria-labelledby="pills-EvolutionX-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {evolutionxData.length > 0 ? evolutionxData.map((item) => (
                                    <>
                                        { item.details ? <Auction key={item.id} item={item} /> : <Sale key={item.id} item={item} />}
                                    </>
                                    )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    No result found
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade  show active" id="pills-BuyOnly" role="tabpanel" aria-labelledby="pills-BuyOnly-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer" id="buyAll">
                                {saledata.length > 0 ? saledata.map((item) => (
                                    <Sale key={item.id} item={item} />
                                )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-BidOnly" role="tabpanel" aria-labelledby="pills-BidOnly-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer" id="bidOnly">
                                {auctionData.length > 0 ? auctionData.map((item) => (
                                    <Auction key={item.id} item={item} />
                                )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-NewListed" role="tabpanel" aria-labelledby="pills-NewListed-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                            {newList.length > 0 ? newList.map((item) => (
                                    <NewListCard key={item.AuctionToken || item.SaleToken} item={item} />
                                )) :<div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}
                                
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-SOLD" role="tabpanel" aria-labelledby="pills-SOLD-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                            {soldData.length > 0 ? soldData.map((item) => (
                                    <SoldCrad key={item.nftId} item={item} />
                                )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>} 
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-High" role="tabpanel" aria-labelledby="pills-High-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {highToLow.length > 0 ? highToLow.map((item) => (
                                    <PriceComponent key={item.id} item={item} />
                                )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-Low" role="tabpanel" aria-labelledby="pills-Low-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {lowToHigh.length > 0 ? lowToHigh.map((item) => (
                                    <PriceComponent key={item.id} item={item} />
                                )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

    </div>;
}

BuyOnly.propTypes = propTypes;
BuyOnly.defaultProps = defaultProps;
// #endregion

export default BuyOnly;