/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import $ from "jquery";
import Header from './Header';
import Footer from './Footer';
import { baseUrl, ontop,web3 } from '../Details';
import swal from 'sweetalert';
// #endregion

// #region component
const propTypes = {};
const defaultProps = {};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  // #region functions
  const onFormSubmit = (e) => {
    e.preventDefault();
      if(password.trim()){
        $('.ajax-loader').css("display", "flex");
        let Email = email;
        let Password = password;
        var data = qs.stringify({
          'email': Email,
          'password': Password
        });
        var config = {
          method: 'post',
          url: `${baseUrl}/login`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
          },
          data: data
        };
  
        axios(config)
          .then(async function (response) {
            if (response.data.success === true) {
                $('.ajax-loader').css("display", "none");
                sessionStorage.setItem("userid", response.data.data);
                swal("Logged In Successfully", { button: false, icon: "success", timer: 2000 });
                if(window.ethereum && sessionStorage.getItem("address")){
                  let metamaskAdd = await window.ethereum.request({ method: 'eth_requestAccounts' });
                  let addressLogin = sessionStorage.getItem("address");
                //  alert("here 2=", metamaskAdd[0]);
                    if (metamaskAdd[0] !== addressLogin) {
                      web3.eth.getAccounts()
                          .then((account) => {
                              sessionStorage.setItem("address", account[0]);
                          })
                          setTimeout(() => { history.push('/') }, 2000);
                  }
                }else{
                  setTimeout(() => { history.push('/ConnectWallet') }, 2000);
                }
            } else {
              swal(response.data.msg, { button: false, icon: "warning", timer: 1000 });
              $('.ajax-loader').css("display", "none");
            }
          })
          .catch(function (error) {
            console.error(error);
            $('.ajax-loader').css("display", "none");
          });
      }
      else{
        swal("Enter password", { button: false, icon: "warning", timer: 1000 });
      }
    
  }
  // #endregion

  // #useeffect region
  useEffect(() => {
    var body = document.body;
    body.classList.remove("connectWalletWrapper");
    ontop();
  }, []);
  // #endregion

  return <div>
    <Header />
    <div className="ajax-loader" style={{ display: "none" }}>
      <div style={{ textAlign: "center" }}>
        <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
        <img src="img/load.gif" alt = "" className="img-responsive" style={{ width: "50px" }} />
      </div>
    </div>
    <section className="exploreWrapper">
      <div className="cstm-container">
        <div className="innr_bck_box">
          <Link className="btn viewBtn" to="/">
            <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>
            <p className="m-0">Back to home</p>
          </Link>
        </div>
        <div className="loginInner">
          <div>
            <h1 className="fw-bold headingC">LOGIN</h1>
          </div>
          <div className="mt-5 w-50 loginData mb-5 bg-white cstmfrm shadow-sm">
            <form onSubmit={onFormSubmit} className="">
              <div className="form-group">
                <label htmlFor="" className="cstm_lbl">Email id</label>
                <input type="text" className="form-control" id="email" placeholder="Enter Email id" onChange={(event) => { setEmail(event.target.value); }} value={email} />
              </div>
              <div className="form-group">
                <label htmlFor="" className="cstm_lbl">Password</label>
                <input type="password" className="form-control" placeholder="Enter Your Password" onChange={(event) => { setPassword(event.target.value); }} value={password} />
              </div>
              <div className="hrbx hr"></div>
              <div className="loginBtn d-flex align-items-center justify-content-between"> 
              <Link to="/ForgotPassword" className="cstm_link">Forgot Password?</Link>    
                <button type="submit" className="btn btn-primary rounded-pill shadow" >Login</button>
                
              </div>
              <br/>
              <div className=''>
                <div className=''>
                Don't have an account yet?  <Link to="/Register" className="cstm_link">Sign Up</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>;
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;
// #endregion

export default Login;