/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React ,{useEffect,useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import OTPInput from "react-otp-input";
import axios from 'axios';
import qs from 'qs';
import $ from "jquery";
import swal from 'sweetalert';
import Header from './Header'
import Footer from './Footer'
import { baseUrl,ontop } from '../Details';
var otpNum ;
var otpVerified;
var timerInterval;
// #endregion

// #region component
const propTypes = {};
const defaultProps = {};


const ForgotPassword = () => {
    
    const [email, setEmail] = useState('');
    const [OTP, setOTP] = useState('')
    const [count, setCount] = useState(0)
    let history = useHistory();

    // #region functions
    const showTimer = (seconds)=>{
        
        timerInterval = setInterval(() => {
            if (Number(seconds) > 0) {
              setCount(--seconds);
            }else{
                clearInterval(timerInterval);
                document.getElementById('resend-otp-btn').removeAttribute("disabled");
            }
          }, 1000);
    }

    const closebutton = () => { 
        var showcls = document.getElementById("Verifyemail");
        showcls.classList.remove("show");
        showcls.classList.remove("d-block");
        showcls.style.backgroundColor = '#0000';
    }

    const verifyEmail = () => { 
        let Email = email;
        if(Email.trim() !== '' && Email.trim() !== undefined && Email.trim() !== null){
            $('.ajax-loader').css("display", "flex");
            var data = qs.stringify({
                'email': Email 
               });
               var config = {
                 method: 'post',
                 url: `${baseUrl}/forget-password-verify`,
                 headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                 },
                 data : data
               };
               
                axios(config)
               .then(function (response) {
                if(response.data.success === true){
                    // console.log(response.data)
                    otpNum = response.data.data;
                    $('.ajax-loader').css("display", "none");
                    swal("OTP has been sent to your email, Please check...", {
                      timer: 3000,
                      icon: "success",
                      button: false,
                    });

                    let timestamp = parseInt(response.data.time);
                    let currentStamp = parseInt((new Date().getTime()) / 1000);
                    let time = 120;
                    if(timestamp+120 === currentStamp){
                        document.getElementById('resend-otp-btn').removeAttribute("disabled");
                    }else{
                        clearInterval(timerInterval)
                        showTimer(time);
                    }

                    let element = document.getElementById('otpmodal');  
                    element.setAttribute("data-bs-toggle","modal"); 
                    element.setAttribute("data-bs-target","#Verifyemail");
         
                    var showcls = document.getElementById("Verifyemail");
                    showcls.className += " show d-block";
                    showcls.style.backgroundColor = '#0000008a';
                    document.getElementById('resend-otp-btn').setAttribute("disabled","true");
                }else if(response.status === 203){
                    $('.ajax-loader').css("display", "none");
                    // swal("Wait for two minutes to send another otp request", {
                    //     timer: 3000,
                    //     icon: "warning",
                    //     button: false,
                    //   });
                    let timestamp = parseInt(response.data.time);
                    let currentStamp = parseInt((new Date().getTime()) / 1000);
                    
                    if(timestamp+120 === currentStamp){
                        document.getElementById('resend-otp-btn').removeAttribute("disabled");
                    }else{
                        clearInterval(timerInterval)
                        let time = timestamp+120-currentStamp;
                        showTimer(time);
                    }

                    let element = document.getElementById('otpmodal');  
                    element.setAttribute("data-bs-toggle","modal"); 
                    element.setAttribute("data-bs-target","#Verifyemail");
        
                    let showcls = document.getElementById("Verifyemail");
                    showcls.className += " show d-block";
                    showcls.style.backgroundColor = '#0000008a';
                    document.getElementById('resend-otp-btn').setAttribute("disabled","true"); 
                }else{
                    document.getElementById("closeButton").click();
                    $('.ajax-loader').css("display", "none");
                    swal(response.data.msg, {
                        timer: 3000,
                        icon: "warning",
                        button: false,
                    });
                }
               })
               .catch(function (error) {
                 console.error(error);
                 $('.ajax-loader').css("display", "none");
               });               
        }else{
            swal("Please enter email",{button:false, icon:"warning",timer:1000});
        } 
    }
    
    const matchOtp = () => {
        var userOtp = OTP;
        if(userOtp !== undefined && userOtp !== null && userOtp !== '' ){
            userOtp = parseInt(userOtp);
                if(userOtp === otpNum){
                    $('.ajax-loader').css("display", "flex");
                    var config = {
                        method: 'get',
                        url: `${baseUrl}/otp-verify?email=${email}`,
                        headers: { 
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                         },
                      };
                      
                      axios(config)
                      .then(function (response) {
                        if(response.data.success === true){
                            $('.ajax-loader').css("display", "none");
                            otpVerified = true;
                            setOTP('');
                            document.getElementById("closeButton").click();
                            setTimeout(() => {swal("Success",{button:false, icon:"success",timer:1000});},2000)
                            history.push({
                                pathname: '/GeneratePassword',
                                state: {
                                    otpVerified,
                                    email
                                }
                            });
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            }); 
                        }else{
                            $('.ajax-loader').css("display", "none");
                            setTimeout(() => {swal("There is some problem while processing request",{button:false, icon:"warning",timer:1000});},2000)
                        }
                      })
                      .catch(function (error) {
                        $('.ajax-loader').css("display", "none");
                        console.log(error);
                      });
                    
                }else{
                    setOTP('');
                    swal("Invalid Otp",{button:false, icon:"warning",timer:1000});
                }
        }
                


        
        
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
		body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);
    // #endregion
    return <div>
            <Header/>
            <div className="ajax-loader" style={{display:"none"}}>
                <div style={{textAlign:"center"}}> 
                    <p id="loaderText" style={{color:"#fff"}}>Please wait....</p>
                    <img src="img/load.gif" className="img-responsive" alt="" style={{width: "50px"}}/> 
                </div>
            </div>
                <section className="exploreWrapper">
                    <div className="cstm-container">
                        <div className="innr_bck_box">
                            <Link className="btn viewBtn" to="/">
                                <i className="ri-arrow-left-line" style={{fontSize: "22px"}}></i>
                                <p className="m-0">Back to home</p>
                            </Link>
                        </div>
                        <div className="loginInner">
                            <div>
                                <h1 className="fw-bold headingC">Forgot Password</h1>
                            </div>
                            <div className="mt-5 w-50 loginData mb-5 bg-white cstmfrm shadow-sm">
                                <form action="" className="">
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl">Email id</label>
                                        <input type="text" className="form-control" placeholder="Enter email id" onChange = {(event)=>{setEmail(event.target.value);}} value = {email}/>
                                    </div>
                                    <div className="hrbx hr"></div>
                                    <div className="loginBtn d-flex align-items-center justify-content-between">
                                        <Link to="/login" className="cstm_link">Login?</Link>
                                        <Link className="btn btn-primary rounded-pill shadow" to="#"  id= "otpmodal" onClick={verifyEmail}>Send</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
        <Footer/>
        <div className="modal fade commonModal" id="mailvefryctn" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close"><i
                                className="ri-close-line"></i></button>
                        <div className="m-history-wrapper text-center">
                            <img src="img/check.png" alt="" className="mb-4"/>
                            <h3 className="heading fs-3 text-center mb-3 text-capitalize">Check your email</h3>
                            <p className="text-center text-gray fs-5">We sent you an email with instructions to reset your password. if you haven't received this email within a few minutes, please check your span folder.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal commonModal oneForm fade" id="Verifyemail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" id = "closeButton" aria-label="Close" onClick={closebutton}><i
                                className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Verify your email id</h3>
                            <div style={{textAlign:"center"}}>
                                <h5 className="heading mb-2 text-gray fs-6" style = {{textTransform:"none"}}>{email}</h5>
                                <div style={{textAlign:"center"}}>
                                    <h5 className="heading mb-2 text-gray fs-6">Resend OTP in</h5>
                                    <h6 id="minuteId">{'0'+Math.floor((count % (60 * 60)) / (60))} : {Math.floor((count % (60)))<10 ?'0'+Math.floor((count % (60))):Math.floor((count % (60)))}</h6>
                                </div>
                            </div>
                            <form action=" " className="p-4">
                                <div className="form-group text-center">
                                    <label htmlFor="bidI" className="text-gray fs-6">Enter OTP</label>
                                    <div style={{display: "flex",justifyContent: "center"}}>
                                         <div className="inputField4">
                                            <OTPInput isInputNum={true} className=" d-block form-control text-center mx-2 border-0"  value={OTP} onChange={setOTP}  />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <button type = "button" id="match-otp-btn" className="btn dtl_btn" onClick = {matchOtp}>Verify</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <button type = "button" id="resend-otp-btn" className="btn dtl_btn" onClick = {verifyEmail}>Resend</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

ForgotPassword.propTypes = propTypes;
ForgotPassword.defaultProps = defaultProps;
// #endregion

export default ForgotPassword;