/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React, { useEffect, useState } from 'react';
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'
import $ from 'jquery'
import { baseUrl, ontop } from "../Details";
import swal from 'sweetalert';
// #endregion


// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const ContactUs = () => {

    // #region functions
    const validateEmail = () => {
        let emailID = document.getElementById("email").value;
        let emailIDVal = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailIDTrue = emailIDVal.test(emailID);
        if (emailIDTrue) {
            document.getElementById('emailErorr').style.display = "none";
            document.getElementById('emailErorr').innerHTML = "";
            document.getElementById('emailVerified').style.display = "block";
            return true;
        } else {
            document.getElementById('emailErorr').style.display = "block";
            document.getElementById('emailVerified').style.display = "none";
            if (emailID === "") {
                document.getElementById('emailErorr').innerHTML = "** Fill this field";
            }
            else {
                document.getElementById('emailErorr').innerHTML = "** Email is invalid.";
            }
            return false;
        }
    }
    const validateUsername = () => {
        let displayName = (document.getElementById("displayName").value).trim();
        let userNameVal = /^[a-zA-Z ]{6,20}$/;
        let userNameTrue = userNameVal.test(displayName);
        if (userNameTrue) {
            document.getElementById('Username_error').style.display = "none";
            document.getElementById('Username_error').innerHTML = "";
            document.getElementById('username_verified').style.display = "block";
            return true;
        } else {
            document.getElementById('Username_error').style.display = "block";
            document.getElementById('username_verified').style.display = "none";
            if (displayName === "") {
                document.getElementById('Username_error').innerHTML = "** Fill this field";
            } else {
                document.getElementById('Username_error').innerHTML = "** Name must contain six to twenty character, and should be alphabetical.";
            }
            return false;
        }
    }
    const validateSubject = () => {
        let subjectid = document.getElementById("subject").value;
        subjectid = subjectid.trim();
        // /^[a-zA-Z ]*$/
        let subjectVal = /^[a-zA-Z ]{5,50}$/;
        let subjectTrue = subjectVal.test(subjectid);
        if (subjectTrue) {
            document.getElementById('subject_error').style.display = "none";
            document.getElementById('subject_error').innerHTML = "";
            document.getElementById('subject_verified').style.display = "block";
            return true;
        } else {
            document.getElementById('subject_error').style.display = "block";
            document.getElementById('subject_verified').style.display = "none";
            if (subjectid === "") {
                document.getElementById('subject_error').innerHTML = "** Fill this field";
            } else {
                document.getElementById('subject_error').innerHTML = "** Subject must contain 5 to 50 character and Please enter only alphabetical letters.";
            }
            return false;
        }
    }
    const [contactForm, setContactForm] = useState({
        fullname: '',
        email: '',
        country: '',
        subject: '',
        message: '',
        loading: false,
        msg: '',
        messageType: ''
    })
    const handleOnChange = (e) => {
        setContactForm(preState => ({
            ...preState,
            [e.target.name]: e.target.value
        }))
    }
    const submitForm = () => {
        let obj = {
            fullname: contactForm.fullname,
            email: contactForm.email,
            country: contactForm.country,
            subject: contactForm.subject,
            message: contactForm.message,
        }
        if (validateEmail()) {
            if (validateUsername() && validateSubject()) {
                if (!obj.fullname.trim() || !obj.email.trim() || !obj.country.trim() || !obj.subject.trim() || !obj.message.trim())
                    setContactForm(preState => ({
                        ...preState,
                        msg: 'All fields are required.',
                        messageType: 'error'
                    }))
                else {
                    setContactForm(preState => ({
                        ...preState,
                        msg: '',
                        messageType: '',
                        loading: true
                    }))
                    $('.ajax-loader').css("display", "flex");
                    axios.post(`${baseUrl}/contactUs`, obj).then((result) => {
                        if (result.data.success === true) {
                            $('.ajax-loader').css("display", "none");
                            setContactForm(preState => ({
                                ...preState,
                                msg: result.data.msg,
                                messageType: 'success',
                                fullname: '',
                                email: '',
                                country: '',
                                subject: '',
                                message: '',
                                loading: false,
                            }))
                            document.getElementById('emailVerified').style.display = "none";
                            document.getElementById('username_verified').style.display = "none";
                            swal({title: result.data.msg, button: false, icon: "success", timer: 2000 });
                            
                        } else {
                            $('.ajax-loader').css("display", "none");
                            setContactForm(preState => ({
                                ...preState,
                                msg: result.data.msg,
                                messageType: 'error',
                                loading: false,
                            }))
                            swal({title: result.data.msg, button: false, icon: "error", timer: 2000 });
                        }
                    }).catch((err) => {
                        $('.ajax-loader').css("display", "none");
                        setContactForm(preState => ({
                            ...preState,
                            msg: `Something went wrong`,
                            messageType: 'error',
                            loading: false
                        }))
                        swal({title: "Something went wrong ! Please try again later", button: false, icon: "error", timer: 2000 });
                    })
                }
            }
            else {

            }
        }
        else {
            swal("Enter Valid Email", { button: false, icon: "warning", timer: 1000 });
        }
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, [])
    // #endregion
    return <div>

        <div className="ajax-loader-header" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <Header />
        <section className="exploreWrapper">
            <div className="cstm-container">

                <div className="innerExplore" style={{
                    margin: "20px 0px"
                }}>
                    <h2 className="heading text-capitalize">Contact Us </h2>
                    <p className="subheading">We're here to help and answer any question you might have. We look forward to hearing from you 🙂</p>

                </div>


                <div className="white_box  details_tabbx oneForm">

                    <div className='contact-us-form'>
                        <div >
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">

                                        <label>Email Address</label>
                                        <input type="email" id="email" className="form-control"
                                            placeholder="Enter Email Address"
                                            name="email" required="" autocomplete="off"
                                            value={contactForm.email}
                                            onChange={e => handleOnChange(e)}
                                            onKeyUp={validateEmail}
                                        />
                                        <p id="emailVerified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Email is Valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                        <span id="emailErorr" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">

                                        <label>Full Name</label>
                                        <input type="text" className="form-control"
                                            id="displayName"
                                            value={contactForm.fullname}
                                            onChange={e => handleOnChange(e)}
                                            onKeyUp={validateUsername}
                                            placeholder="Enter Full Name" name="fullname" required="" autocomplete="off" />
                                        <p id="username_verified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Name is Valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                        <span id="Username_error" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">

                                        <label>Select Country</label>
                                        <select className="form-control" name="country"
                                            value={contactForm.country}
                                            onChange={e => handleOnChange(e)}
                                        >

                                            <option value="" disabled selected hidden>Select a Country</option>
                                            <option value="AF">Afghanistan</option>
                                            <option value="AX">Åland Islands</option>
                                            <option value="AL">Albania</option>
                                            <option value="DZ">Algeria</option>
                                            <option value="AS">American Samoa</option>
                                            <option value="AD">Andorra</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">Antarctica</option>
                                            <option value="AG">Antigua and Barbuda</option>
                                            <option value="AR">Argentina</option>
                                            <option value="AM">Armenia</option>
                                            <option value="AW">Aruba</option>
                                            <option value="AU">Australia</option>
                                            <option value="AT">Austria</option>
                                            <option value="AZ">Azerbaijan</option>
                                            <option value="BS">Bahamas</option>
                                            <option value="BH">Bahrain</option>
                                            <option value="BD">Bangladesh</option>
                                            <option value="BB">Barbados</option>
                                            <option value="BY">Belarus</option>
                                            <option value="BE">Belgium</option>
                                            <option value="BZ">Belize</option>
                                            <option value="BJ">Benin</option>
                                            <option value="BM">Bermuda</option>
                                            <option value="BT">Bhutan</option>
                                            <option value="BO">Bolivia, Plurinational State of</option>
                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                            <option value="BA">Bosnia and Herzegovina</option>
                                            <option value="BW">Botswana</option>
                                            <option value="BV">Bouvet Island</option>
                                            <option value="BR">Brazil</option>
                                            <option value="IO">British Indian Ocean Territory</option>
                                            <option value="BN">Brunei Darussalam</option>
                                            <option value="BG">Bulgaria</option>
                                            <option value="BF">Burkina Faso</option>
                                            <option value="BI">Burundi</option>
                                            <option value="KH">Cambodia</option>
                                            <option value="CM">Cameroon</option>
                                            <option value="CA">Canada</option>
                                            <option value="CV">Cape Verde</option>
                                            <option value="KY">Cayman Islands</option>
                                            <option value="CF">Central African Republic</option>
                                            <option value="TD">Chad</option>
                                            <option value="CL">Chile</option>
                                            <option value="CN">China</option>
                                            <option value="CX">Christmas Island</option>
                                            <option value="CC">Cocos (Keeling) Islands</option>
                                            <option value="CO">Colombia</option>
                                            <option value="KM">Comoros</option>
                                            <option value="CG">Congo</option>
                                            <option value="CD">Congo, the Democratic Republic of the</option>
                                            <option value="CK">Cook Islands</option>
                                            <option value="CR">Costa Rica</option>
                                            <option value="CI">Côte d'Ivoire</option>
                                            <option value="HR">Croatia</option>
                                            <option value="CU">Cuba</option>
                                            <option value="CW">Curaçao</option>
                                            <option value="CY">Cyprus</option>
                                            <option value="CZ">Czech Republic</option>
                                            <option value="DK">Denmark</option>
                                            <option value="DJ">Djibouti</option>
                                            <option value="DM">Dominica</option>
                                            <option value="DO">Dominican Republic</option>
                                            <option value="EC">Ecuador</option>
                                            <option value="EG">Egypt</option>
                                            <option value="SV">El Salvador</option>
                                            <option value="GQ">Equatorial Guinea</option>
                                            <option value="ER">Eritrea</option>
                                            <option value="EE">Estonia</option>
                                            <option value="ET">Ethiopia</option>
                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                            <option value="FO">Faroe Islands</option>
                                            <option value="FJ">Fiji</option>
                                            <option value="FI">Finland</option>
                                            <option value="FR">France</option>
                                            <option value="GF">French Guiana</option>
                                            <option value="PF">French Polynesia</option>
                                            <option value="TF">French Southern Territories</option>
                                            <option value="GA">Gabon</option>
                                            <option value="GM">Gambia</option>
                                            <option value="GE">Georgia</option>
                                            <option value="DE">Germany</option>
                                            <option value="GH">Ghana</option>
                                            <option value="GI">Gibraltar</option>
                                            <option value="GR">Greece</option>
                                            <option value="GL">Greenland</option>
                                            <option value="GD">Grenada</option>
                                            <option value="GP">Guadeloupe</option>
                                            <option value="GU">Guam</option>
                                            <option value="GT">Guatemala</option>
                                            <option value="GG">Guernsey</option>
                                            <option value="GN">Guinea</option>
                                            <option value="GW">Guinea-Bissau</option>
                                            <option value="GY">Guyana</option>
                                            <option value="HT">Haiti</option>
                                            <option value="HM">Heard Island and McDonald Islands</option>
                                            <option value="VA">Holy See (Vatican City State)</option>
                                            <option value="HN">Honduras</option>
                                            <option value="HK">Hong Kong</option>
                                            <option value="HU">Hungary</option>
                                            <option value="IS">Iceland</option>
                                            <option value="IN">India</option>
                                            <option value="ID">Indonesia</option>
                                            <option value="IR">Iran, Islamic Republic of</option>
                                            <option value="IQ">Iraq</option>
                                            <option value="IE">Ireland</option>
                                            <option value="IM">Isle of Man</option>
                                            <option value="IL">Israel</option>
                                            <option value="IT">Italy</option>
                                            <option value="JM">Jamaica</option>
                                            <option value="JP">Japan</option>
                                            <option value="JE">Jersey</option>
                                            <option value="JO">Jordan</option>
                                            <option value="KZ">Kazakhstan</option>
                                            <option value="KE">Kenya</option>
                                            <option value="KI">Kiribati</option>
                                            <option value="KP">Korea, Democratic People's Republic of</option>
                                            <option value="KR">Korea, Republic of</option>
                                            <option value="KW">Kuwait</option>
                                            <option value="KG">Kyrgyzstan</option>
                                            <option value="LA">Lao People's Democratic Republic</option>
                                            <option value="LV">Latvia</option>
                                            <option value="LB">Lebanon</option>
                                            <option value="LS">Lesotho</option>
                                            <option value="LR">Liberia</option>
                                            <option value="LY">Libya</option>
                                            <option value="LI">Liechtenstein</option>
                                            <option value="LT">Lithuania</option>
                                            <option value="LU">Luxembourg</option>
                                            <option value="MO">Macao</option>
                                            <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                            <option value="MG">Madagascar</option>
                                            <option value="MW">Malawi</option>
                                            <option value="MY">Malaysia</option>
                                            <option value="MV">Maldives</option>
                                            <option value="ML">Mali</option>
                                            <option value="MT">Malta</option>
                                            <option value="MH">Marshall Islands</option>
                                            <option value="MQ">Martinique</option>
                                            <option value="MR">Mauritania</option>
                                            <option value="MU">Mauritius</option>
                                            <option value="YT">Mayotte</option>
                                            <option value="MX">Mexico</option>
                                            <option value="FM">Micronesia, Federated States of</option>
                                            <option value="MD">Moldova, Republic of</option>
                                            <option value="MC">Monaco</option>
                                            <option value="MN">Mongolia</option>
                                            <option value="ME">Montenegro</option>
                                            <option value="MS">Montserrat</option>
                                            <option value="MA">Morocco</option>
                                            <option value="MZ">Mozambique</option>
                                            <option value="MM">Myanmar</option>
                                            <option value="NA">Namibia</option>
                                            <option value="NR">Nauru</option>
                                            <option value="NP">Nepal</option>
                                            <option value="NL">Netherlands</option>
                                            <option value="NC">New Caledonia</option>
                                            <option value="NZ">New Zealand</option>
                                            <option value="NI">Nicaragua</option>
                                            <option value="NE">Niger</option>
                                            <option value="NG">Nigeria</option>
                                            <option value="NU">Niue</option>
                                            <option value="NF">Norfolk Island</option>
                                            <option value="MP">Northern Mariana Islands</option>
                                            <option value="NO">Norway</option>
                                            <option value="OM">Oman</option>
                                            <option value="PK">Pakistan</option>
                                            <option value="PW">Palau</option>
                                            <option value="PS">Palestinian Territory, Occupied</option>
                                            <option value="PA">Panama</option>
                                            <option value="PG">Papua New Guinea</option>
                                            <option value="PY">Paraguay</option>
                                            <option value="PE">Peru</option>
                                            <option value="PH">Philippines</option>
                                            <option value="PN">Pitcairn</option>
                                            <option value="PL">Poland</option>
                                            <option value="PT">Portugal</option>
                                            <option value="PR">Puerto Rico</option>
                                            <option value="QA">Qatar</option>
                                            <option value="RE">Réunion</option>
                                            <option value="RO">Romania</option>
                                            <option value="RU">Russian Federation</option>
                                            <option value="RW">Rwanda</option>
                                            <option value="BL">Saint Barthélemy</option>
                                            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                            <option value="KN">Saint Kitts and Nevis</option>
                                            <option value="LC">Saint Lucia</option>
                                            <option value="MF">Saint Martin (French part)</option>
                                            <option value="PM">Saint Pierre and Miquelon</option>
                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                            <option value="WS">Samoa</option>
                                            <option value="SM">San Marino</option>
                                            <option value="ST">Sao Tome and Principe</option>
                                            <option value="SA">Saudi Arabia</option>
                                            <option value="SN">Senegal</option>
                                            <option value="RS">Serbia</option>
                                            <option value="SC">Seychelles</option>
                                            <option value="SL">Sierra Leone</option>
                                            <option value="SG">Singapore</option>
                                            <option value="SX">Sint Maarten (Dutch part)</option>
                                            <option value="SK">Slovakia</option>
                                            <option value="SI">Slovenia</option>
                                            <option value="SB">Solomon Islands</option>
                                            <option value="SO">Somalia</option>
                                            <option value="ZA">South Africa</option>
                                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                                            <option value="SS">South Sudan</option>
                                            <option value="ES">Spain</option>
                                            <option value="LK">Sri Lanka</option>
                                            <option value="SD">Sudan</option>
                                            <option value="SR">Suriname</option>
                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                            <option value="SZ">Swaziland</option>
                                            <option value="SE">Sweden</option>
                                            <option value="CH">Switzerland</option>
                                            <option value="SY">Syrian Arab Republic</option>
                                            <option value="TW">Taiwan, Province of China</option>
                                            <option value="TJ">Tajikistan</option>
                                            <option value="TZ">Tanzania, United Republic of</option>
                                            <option value="TH">Thailand</option>
                                            <option value="TL">Timor-Leste</option>
                                            <option value="TG">Togo</option>
                                            <option value="TK">Tokelau</option>
                                            <option value="TO">Tonga</option>
                                            <option value="TT">Trinidad and Tobago</option>
                                            <option value="TN">Tunisia</option>
                                            <option value="TR">Turkey</option>
                                            <option value="TM">Turkmenistan</option>
                                            <option value="TC">Turks and Caicos Islands</option>
                                            <option value="TV">Tuvalu</option>
                                            <option value="UG">Uganda</option>
                                            <option value="UA">Ukraine</option>
                                            <option value="AE">United Arab Emirates</option>
                                            <option value="GB">United Kingdom</option>
                                            <option value="US">United States</option>
                                            <option value="UM">United States Minor Outlying Islands</option>
                                            <option value="UY">Uruguay</option>
                                            <option value="UZ">Uzbekistan</option>
                                            <option value="VU">Vanuatu</option>
                                            <option value="VE">Venezuela, Bolivarian Republic of</option>
                                            <option value="VN">Viet Nam</option>
                                            <option value="VG">Virgin Islands, British</option>
                                            <option value="VI">Virgin Islands, U.S.</option>
                                            <option value="WF">Wallis and Futuna</option>
                                            <option value="EH">Western Sahara</option>
                                            <option value="YE">Yemen</option>
                                            <option value="ZM">Zambia</option>
                                            <option value="ZW">Zimbabwe</option>
                                        </select>
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">

                                        <label>Select Subject</label>
                                        <input type="text" className="form-control"
                                            id="subject"
                                            onKeyUp={validateSubject}
                                            value={contactForm.subject}
                                            onChange={e => handleOnChange(e)}
                                            placeholder="Enter Subject" name="subject" required="" autocomplete="off" />
                                        <p id="subject_verified" style={{ display: "none", color: "green", padding: " 0 10px" }}></p>
                                        <span id="subject_error" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>

                                    </div>
                                </div>
                            </div>






                        </div>

                        <div>
                            <div className="form-group">

                                <label>Message</label>
                                <textarea
                                    value={contactForm.message}
                                    onChange={e => handleOnChange(e)}
                                    className="form-control" placeholder="" name="message"></textarea>

                            </div>
                        </div>

                        <button
                            disabled={contactForm.loading}
                            onClick={submitForm} className="btn dtl_btn" name="" >
                            Submit </button>
                        {/* {contactForm.messageType === "error" && (
                            <p className='error-msg'>{contactForm.msg}</p>
                        )}
                        {contactForm.messageType === "success" && (
                            <p className='success-msg'>{contactForm.msg}</p>
                        )} */}

                    </div>

                </div>


            </div>
        </section>
        <Footer />
    </div>;
}

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;
// #endregion

export default ContactUs;