/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../Details';
import logo from '../Images/logo.png'	
import fb from '../Images/fb.png'	
import insta from '../Images/insta.png'	
import twt from '../Images/twt.png'	
import telegram from '../Images/telegram.png'
import ScrollTop from './common/ScrollTop'

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class Footer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			socialLinks: [],
			Facebook: '',
			Instagram: '',
			Twitter: '',
			Telegram: ''
		};
	}
	componentDidMount() {
		this.getSocials()
	}
	getSocials = () => {
		axios.get(`${baseUrl}/getSocialLinks`).then((result) => {
			if (result.data.success) {
				this.setState({ socialLinks: result.data.data })
				for (let item of result.data.data) {
					this.setState({ [item.name]: item.link })
				}
			}
		}).then((err) => {
			console.error({ err })
		})
	}

	checkIfLinks = (name) => {

	}
	render() {
		return <div>
			<footer className="footer bannerS ">
				<div className="vertx ">
					<div className="container ">
						<div className="innerFooter vertxGrid ">
							<div className="logo_p foot ">
								<img src={logo} alt=" " />
								<p className="text-gray mt-3 ">OneTo11 is a gaming ecosystem with games for every gamer persona and implementing the Play-To-Earn model across the platform.</p>
							</div>
							<div className="quick_L foot ">
								<h3>Quick Links</h3>
								<ul className="p-0 ">
									<li><Link to="/aboutus">About Us</Link></li>
									<li><a href="https://www.oneto11.io/#team" target = "_blank" rel = "noreferrer">Team</a></li>
									<li><a href="https://oneto11static.azureedge.net/onetoio/assets/file/oneto11_whitepaper.pdf" target = "_blank" rel="noreferrer" >White Paper</a></li>
									
									<li><Link to="/Login">Login</Link></li>
									<li><Link to="/Register">Registration</Link>
									</li>
								</ul>
							</div>
							<div className="contact foot ">
								<h3>Contact Us</h3>
								<ul className="p-0 ">
									<li>
										<a href="mailto:info@oneto11.io">info@oneto11.io</a>
									</li>
									<li><Link to="/ContactUs">Contact Us</Link></li>
								</ul>
							</div>
							<div className="Social foot">
								<h3>Social Connect</h3>
								<ul className="p-0 ">	
									{this.state.Facebook && (	
										<li>	
											<a href={this.state.Facebook} target="_blank" rel="noreferrer" >	
												<img src={fb} alt="" />	
												<span className="text-gray ">Facebook</span>	
											</a>	
										</li>	
									)}	
									{this.state.Instagram && (	
										<li>	
											<a href={this.state.Instagram} target="_blank" rel="noreferrer" >	
												<img src={insta} alt="" />	
												<span className="text-gray ">Instagram</span>	
											</a>	
										</li>	
									)}	
									{this.state.Twitter && (	
										<li>	
											<a href={this.state.Twitter} target="_blank" rel="noreferrer" >	
												<img src={twt} alt="" />	
												<span className="text-gray ">Twitter</span>	
											</a>	
										</li>	
									)}	
									{this.state.Telegram && (	
										<li>	
											<a href={this.state.Telegram} target="_blank" rel="noreferrer" >	
												<img src={telegram} alt="" />	
												<span className="text-gray ">Telegram</span>	
											</a>	
										</li>	
									)}	
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="smFooter ">
					<div className="container ">
						<div className="innersmFooter d-flex align-items-center justify-content-between ">
							<p className="m-0 fs-18 ">© OneTo11, All rights reserved.</p>
							<div>
								<a href="/terms" className="text-white text-decoration-none fs-18 ">Terms</a>
								<span className="mx-3 ">|</span>
								<a href="/privacy-policy" className="text-white text-decoration-none fs-18 ">Privacy Policy</a>
							</div>
						</div>
					</div>
				</div>
			</footer>
            <ScrollTop/>
			<script src="js/jquery.min.js"></script>
			<script src="js/bootstrap.bundle.min.js"></script>
			<script src="js/stats.js"></script>
			<script src="js/cstum_jqury.js"></script>
		</div>;
	}
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
// #endregion

export default Footer;