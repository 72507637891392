/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import Moment from 'react-moment';
import { CopyToClipboard } from "react-copy-to-clipboard";
import $ from "jquery";
import swal from 'sweetalert';
import Header from './Header'
import Footer from './Footer'
import CountDown from "./CountDown";
import { ontop } from '../Details';
import avtar1 from '../Images/avtar1.png'
import { tokenToUsd, baseUrl, profileUrl, chainUrl, contract, tokenContract, web3, ContractAddress, imgsrc, Oneto11, nftMint721Address, blockexplorer, nftMint721Contract, chain } from '../Details';
import { getOneAuction } from "../helpers/helper"
import ItemActivity from "./common/ItemActivity";
import SocialShare from "./common/SocialShare";
//#endregion
const propTypes = {};
const defaultProps = {};

/**
 * 
 */
const PlaceBid = () => {
    var userIdCheck = sessionStorage.getItem("userid");
    const history = useHistory();
    const search = useLocation().search;
    const [reason, setReason] = useState('');
    const [comments, setComments] = useState('');
    const [imgUrl, setImageUrl] = useState('');
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [likesNum, setLikesNum] = useState(0);
    const [nftData, setNftData] = useState({})
    const [bidTokenId, setBidTokenId] = useState('')
    const [lastBidAmt, setLastBidAmt] = useState(0);
    const [reqBalance, setReqBalance] = useState(0);
    const [hash, setHash] = useState('');
    const [bidAmount, setBidAmount] = useState('');
    const [bidTime, setBidTime] = useState({})
    const [socialUrl, setSocialUrl] = useState(window.location.href);
    const [wishList, setWishList] = useState(true);
    const [baseUri, setBaseUri] = useState('');
    const [liked, setLiked] = useState(false);
    const [user, setUser] = useState('')
    const [userName, setUserName] = useState('')
    const [userByAdd, setuserByAdd] = useState(false);
    const [userImage, setUserImage] = useState('')
    const now = Math.floor((new Date()).getTime() / 1000);
    const [historyData, setHistoryData] = useState([]);
    const [bidData, setBidData] = useState([]);

    // #region functions

    //Wishlist 
    const addWishlist = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = bidTokenId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/addinwishlist`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            swal("Added into wishlist", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setWishList(!wishList);
                        } else {
                            swal(response.data.msg, { button: false, icon: "warning", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to add nft in wishlist", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    //remove wishlist
    const removeWishList = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = bidTokenId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/removefromwishlist`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            swal("Removed from wishlist", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setWishList(!wishList);
                        } else {
                            swal(response.data.msg, { button: false, icon: "warning", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to add nft in wishlist", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }

    const handleReport = () => {

        let userId = (sessionStorage.getItem("userid"));
        let Reason = reason.trim();
        let Comment = comments.trim();
        let nftId = bidTokenId;
        if (userId === '' || userId === null || userId === undefined) {
            swal("Please login to report", { button: false, icon: "warning", timer: 2000 });
            return setTimeout(() => { history.push('/Login') }, 2000);
        };
        if (Reason === null || Reason === '' || Reason === undefined) {
            return swal("Please enter valid reason", { button: false, icon: "warning", timer: 2000 });
        }
        if (Comment === '' || Comment === null || Comment === undefined) {
            return swal("Please enter valid comment", { button: false, icon: "warning", timer: 2000 });
        };
        if (nftId.trim() === '' || nftId.trim() === null || nftId.trim() === undefined) {
            return swal("There is some issue while submitting your request please try again later", { button: false, icon: "warning", timer: 2000 });
        }
        $('.ajax-loader').css("display", "flex");
        var data = qs.stringify({
            'userid': userId,
            'nftid': nftId,
            'reason': Reason,
            'comments': Comment
        });
        var config = {
            method: 'post',
            url: `${baseUrl}/add-report`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    $('.ajax-loader').css("display", "none");
                    swal("Report submitted successfully", { button: false, icon: "success", timer: 2000 });
                    setReason('');
                    setComments('');
                    document.getElementById('reportclose').click()
                } else {
                    $('.ajax-loader').css("display", "none");
                    swal("There is some techincal issue! please try again later", { button: false, icon: "success", timer: 2000 });
                }
            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loader').css("display", "none");
            });
    }

    //function to count likes on nft
    const countLikes = useCallback(() => {
        const tokenId = new URLSearchParams(search).get('tokenNo');
        if (tokenId !== null && tokenId !== '' && tokenId !== undefined) {
            var config = {
                method: 'get',
                url: `${baseUrl}/count-likes?nftid=${tokenId}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            axios(config)
                .then(function (response) {
                    if (response.data.success === true) {
                        setLikesNum(response.data.data)
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }, [search])

    // Add likes
    const addLikes = () => {

        let userId = sessionStorage.getItem("userid");
        let Id = bidTokenId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'likedby': userId,
                    'likedto': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/add-likes`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            swal("Liked successfully", { button: false, icon: "success", timer: 2000 });
                            $('.ajax-loader').css("display", "none");
                            setLiked(true);
                            countLikes();
                        } else {
                            if (response.status === 203) {
                                var data = qs.stringify({
                                    'likedby': userId,
                                    'likedto': Id
                                });
                                var config = {
                                    method: 'post',
                                    url: `${baseUrl}/remove-likes`,
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    data: data
                                };

                                axios(config)
                                    .then(function (response) {
                                        if (response.data.success === true) {
                                            swal("Unliked successfully", { button: false, icon: "success", timer: 2000 });
                                            $('.ajax-loader').css("display", "none");
                                            setLiked(false);
                                            countLikes();
                                        }
                                    })
                                    .catch(function (error) {
                                        console.error(error);
                                        $('.ajax-loader').css("display", "none");
                                    });
                            }
                            else {
                                swal(response.data.msg, { button: false, icon: "success", timer: 2000 });
                                $('.ajax-loader').css("display", "none");
                            }

                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        $('.ajax-loader').css("display", "none");
                    });
            } else {
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        } else {
            swal("Please log In to like Nft", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    const checkLike = () => {
        let userId = sessionStorage.getItem("userid");
        let Id = bidTokenId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                var data = qs.stringify({
                    'userid': userId,
                    'nftid': Id
                });
                var config = {
                    method: 'post',
                    url: `${baseUrl}/check-likes`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.success === true) {
                            if (response.data.data === 1) {
                                setLiked(true);
                            } else {
                                setLiked(false);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }
        }
    }
    //Open Place bid modal
    const openbox = async (token) => {
        let address = sessionStorage.getItem("address");
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                let lastBid;
                if (web3.givenProvider !== null && web3.givenProvider.chainId === chain) {
                    lastBid = await contract.methods.getAuction(token).call();
                } else {
                    lastBid = await getOneAuction(token)
                }
                // let lastBid = await contract.methods.getAuction(token).call();
                let highestBid = lastBid.highestBid;

                if (highestBid === '0') {
                    setLastBidAmt(parseInt(lastBid.price));
                } else {
                    setLastBidAmt(parseInt(highestBid));
                }
                setBidTokenId(token);
            } else {

                document.getElementById("closeButton").click();
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            document.getElementById("closeButton").click();
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }


    }
    // Approve token
    const approveBal = async () => {
        let userId = sessionStorage.getItem("userid");
        let address = sessionStorage.getItem("address");
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (address !== '' && address !== null && address !== undefined) {
                let reqbal = reqBalance;
                let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
                if (reqbal !== "" && reqbal > 0) {
                    if (parseInt(reqbal) <= parseInt(UserTokenBalance) / 10 ** 18) {
                        await tokenContract.methods.approve(ContractAddress, (await web3.utils.toWei(reqbal, "ether"))).send({ from: address }, function (err, result) {
                            if (err) {
                                swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                            }
                            if (result) {
                                swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                            }
                        }).on('receipt', function (receipt) {
                            if (receipt.status === true) {
                                $('.ajax-loader').css("display", "none");
                                setTimeout(() => {
                                    swal({ title: "Your token balance approved", icon: "success", background: "#808080", timer: 2000 });
                                    document.getElementById("rebalaId").style.display = "none";
                                }, 2000);
                            } else {
                                $('.ajax-loader').css("display", "none");
                                swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                            }
                        })
                    }
                    else {
                        swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                    }
                }
                else {
                    swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
                }
            } else {
                swal("Please Connect to the wallet", { button: false, icon: "warning", timer: 2000 });
            }
        } else {
            swal("Please log In", { button: false, icon: "warning", timer: 2000 });
        }

    }
    //place Bid
    const placebid = async (amt, Id) => {
        let address = sessionStorage.getItem("address");
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                let autionDetails = await contract.methods.getAuction(Id).call();
                let endtime = autionDetails.endTime;
                let starttime = autionDetails.startTime;
                const now = Math.floor((new Date()).getTime() / 1000);
                if (parseInt(starttime) < now) {
                    if (parseInt(endtime) > now) {
                        if (amt !== "" && amt > 0) {
                            let allowance = await tokenContract.methods.allowance(address, ContractAddress).call();
                            let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();

                            if (amt > Number(autionDetails.price) / 10 ** 18) {
                                if (amt <= Number(UserTokenBalance) / 10 ** 18) {
                                    if (amt <= Number(allowance) / 10 ** 18) {
                                        if (amt > (autionDetails.highestBid / 10 ** 18)) {
                                            await contract.methods.bid(Id, (await web3.utils.toWei(amt, "ether"))).send({ from: address }, function (err, result) {
                                                if (err) {
                                                    swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                                                    document.getElementById("closeButton").click();
                                                }
                                                if (result) {
                                                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                                    document.getElementById("closeButton").click();
                                                    setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                                                }
                                            }).on('receipt', function (receipt) {
                                                if (receipt.status === true) {
                                                    $('.ajax-loader').css("display", "none");
                                                    swal({ title: "Your bid is successfully placed", icon: "success", background: "#808080", timer: 2000 });
                                                    setTimeout(() => {
                                                        document.getElementById("closeButton").click();
                                                    }, 2000);
                                                } else {
                                                    $('.ajax-loader').css("display", "none");
                                                    swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                                                }
                                            })
                                        }
                                        else {
                                            swal({ title: "Your bid amount is low From Highest bid.", icon: "error", background: "#808080", timer: 2000 });
                                        }
                                    }
                                    else {
                                        // swal({ title: "Please approve the token", icon: "error", background: "#808080", timer: 2000 });
                                        document.getElementById("rebalaId").style.display = "block";
                                    }
                                }
                                else {
                                    swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                                }
                            }
                            else {
                                swal({ title: "Please enter more than last price", icon: "error", background: "#808080", timer: 2000 });
                            }
                        }
                        else {
                            swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
                        }
                    } else {
                        swal({ title: "This Auction is over", icon: "error", background: "#808080", timer: 2000 });
                    }
                } else {
                    swal({ title: "This Auction isn't started yet", icon: "error", background: "#808080", timer: 2000 });
                }

            } else {

                document.getElementById("closeButton").click();
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            document.getElementById("closeButton").click();
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }
    }
    // close popup
    const closebutton = () => {
        var showcls = document.getElementById("bid");
        showcls.classList.remove("show");
        showcls.classList.remove("d-block");
        showcls.style.backgroundColor = '#0000';
    }
    const checkList = () => {
        let userId = sessionStorage.getItem('userid')
        if (userId !== null) {
            const tokenId = new URLSearchParams(search).get('tokenNo');
            const userId = sessionStorage.getItem('userid');
            var data = qs.stringify({
                'userid': userId,
                'nftid': tokenId
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/wishlistcheck`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response.data.success === true) {
                        setWishList(false);
                    } else {
                        setWishList(true);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }
    const nftSeller = (nftid) => {
        return new Promise((resolve, reject) => {
            var config = {
                method: 'get',
                url: `${baseUrl}/market-nft-owner?nftid=${nftid}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
            };
            axios(config).then(function (response) {
                console.log(response.data, 'checking darta')
                if (response.data.success === true) {
                    if (response.data.data[0].username) {
                        resolve(response.data.data[0])
                    } else {
                        setuserByAdd(true)
                        resolve(response.data.data[0].seller)
                    }

                } else {
                    resolve('0x0000000000000000000000')
                }
            }).catch(function (error) {
                console.error(error);
            });
        })

    }
    const getHistory = (tokenId) => {
        axios.post(`${baseUrl}/getTokenHistory`, {
            tokenId: tokenId
        }).then((res) => {
            let dt = [];
            if (res.data.success && res.data.data)
                dt = res.data.data;
            setHistoryData(dt)
        }).catch((err) => {

        })
    }

    const getBidData = (nftId) => {
        axios.post(`${baseUrl}/getBidHistory`, {
            nftId: nftId
        }).then((res) => {
            let dt = [];
            if (res.data.success && res.data.data)
                dt = res.data.data;
            setBidData(dt)
        }).catch((err) => {

        })
    }
    const convertPrice = (n) => {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }
    // #endregion

    //use effect region
    useEffect(() => {
        checkList();
    }, [wishList]);

    useEffect(() => {
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== undefined && userId !== '') {
            setUser(userId)
        }
    }, [sessionStorage.getItem("userid")]);

    useEffect(() => {
        checkLike();
    }, [liked, user, bidTokenId]);


    useEffect(() => {
        const fetchNft = () => {

            const tokenId = new URLSearchParams(search).get('tokenNo');

            if (tokenId === null || tokenId === undefined || tokenId === '') {
                history.push('/')
            }
            // setPrice(buyprice);
            setBidTokenId(tokenId);
            getHistory(tokenId)
            getBidData(tokenId)
            var idArr = [];
            idArr.push(tokenId);

            var data = qs.stringify({
                'id': JSON.stringify(idArr)
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/nft`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };

            axios(config)
                .then(async function (response) {
                    if (response.data.success === true) {
                        let data = JSON.parse((response.data.data[0].ipfs));
                        setImageUrl(data.image);
                        setName(data.name);
                        setDesc(data.description);
                        setHash(`${blockexplorer}/token/${nftMint721Address}?a=${tokenId}`)
                        setBaseUri(data.uri);
                        var nft;
                        if (web3.givenProvider !== null && web3.givenProvider.chainId === chain) {
                            nft = await contract.methods.getAuction(tokenId).call();
                        } else {
                            nft = await getOneAuction(tokenId)
                        }

                        setNftData(nft);
                        setBidTime(pre => ({
                            ...pre,
                            end: nft.endTime,
                            start: nft.endTime
                        }))

                        //     const uri = await nftMint721Contract.methods.getbaseURI().call();
                        //    setBaseUri(uri);
                        const user = await nftSeller(tokenId);
                        if (typeof (user) === 'object') {
                            setUserName(user.username)
                            setUserImage(user.profileImage)
                        } else {
                            setUserName(user)
                        }
                    }

                })
                .catch(function (error) {
                    console.error(error);
                });

        }
        fetchNft();
    }, [history, search])

    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
        countLikes();
    }, [countLikes]);
    // #endregion

    return <div>

        <Header />
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <section className="exploreWrapper">
            <div className="card-details-wrapper">
                <div className="cstm-container">
                    <div className="innr_bck_box">
                        <Link className="btn viewBtn" to="/">
                            <i className="ri-arrow-left-line" style={{ fontSize: " 22px" }}></i>
                            <p className="m-0">Back to home</p>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 dtl_lft_bx">
                            <img className="img-fluid" src={imgUrl} alt="" />
                        </div>
                        <div className="col-lg-6 details_box">
                            <h3 className="cardHeading headingC fw-bold">{name}</h3>
                            <div className="views_box_btn">
                                <div className="d-flex" style={{ gap: "6px" }}>
                                    <div className="views_bx">
                                        {/* <div className="vies_cnt">1 of+- 1</div> */}
                                        <div className="hrt_cnt" onClick={addLikes}>
                                            {liked ? <i className="ri-heart-fill"></i> : <i className="ri-heart-line"></i>}{likesNum}
                                        </div>
                                    </div>
                                    {!userByAdd ? <a href={profileUrl + userName} id='userbtn' className="nm_ank">
                                        <img className="img-fluid" style={{ borderRadius: "50%", height: "30px", width: "30px" }} src={imgsrc + userImage} alt="" />{userName}
                                    </a> : <a href={chainUrl + userName} className="nm_ank">
                                        <img className="img-fluid" style={{ borderRadius: "50%", height: "30px", width: "30px" }} src={avtar1} alt="" />{(<div className="copyLink d-flex">
                                            <input
                                                className="m-0" style={{ fontWeight: "500", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                type="text"
                                                defaultValue={userName}
                                                placeholder="Type some text here"
                                                disabled={true}
                                            />
                                            <CopyToClipboard text={userName} onCopy={() => { swal("Address Copied !!", { button: false, icon: "success", timer: 2000 }); }}>
                                                <div className="copy-area">
                                                    <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                                </div>
                                            </CopyToClipboard>
                                        </div>)}
                                    </a>}
                                </div>

                                <div className="share_box">
                                    <div className="sharebtn_down dropdown">
                                        <button className="btn sharebtnd" id="social" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="ri-share-line"></i>
                                        </button>
                                        <ul className="dropdown-menu socialicon" aria-labelledby="social">
                                            <SocialShare url={socialUrl} img={imgUrl} title={name} desc={desc} />

                                        </ul>
                                    </div>
                                    <div className="sharebtn_down dropdown report">
                                        <button className="btn sharebtnd" id="report" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="ri-more-fill"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="report">
                                            <li>
                                                <Link to="/" data-bs-toggle="modal" data-bs-target="#reportM">
                                                    <i className="ri-flag-line"></i> Report
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown vpabtnbx">
                                <button className="btn vpbtn dropdown-toggle" type="button" id="vpadpdn" data-bs-toggle="dropdown" aria-expanded="false">
                                    View proof of authenticity
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="vpadpdn">
                                    <li>
                                        <a rel="noreferrer" href={baseUri + bidTokenId} target="_blank">
                                            <span><img src="img/ipfs.png" alt="" className="me-2" /></span>
                                            <span>View on IPFS</span>
                                            <span><i className="ri-external-link-line"></i> </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a rel="noreferrer" href={hash} target="_blank">
                                            <span><img src="img/polygon.png" alt="" className="me-2" /></span>
                                            <span>View on Polyscan</span>
                                            <span><i className="ri-external-link-line"></i></span>
                                        </a>
                                    </li>
                                </ul>

                                {wishList ? <button className="btn vpbtn mx-3" type="button" onClick={addWishlist}>Add to Wishlist</button> : <button className="btn vpbtn mx-3" type="button" onClick={removeWishList}>Remove from Wishlist</button>}
                            </div>
                            <div className="details_tabbx">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="active btn" id="pills-Details-tab" data-bs-toggle="pill" data-bs-target="#pills-Details" type="button" role="tab" aria-controls="pills-Details" aria-selected="true">Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="btn" id="pills-Bid-tab" data-bs-toggle="pill" data-bs-target="#pills-Bid" type="button" role="tab" aria-controls="pills-Bid" aria-selected="false" onClick={getBidData}>Bid</button>
                                    </li>

                                </ul>
                                <div className="hrbx hr"></div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-Details" role="tabpanel" aria-labelledby="pills-Details-tab">
                                        <p>{desc}</p>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Bid" role="tabpanel" aria-labelledby="pills-Bid-tab">
                                        {bidData.length > 0 ?
                                            <div className="bid_box"
                                                style={{
                                                    maxHeight: "250px",
                                                    "overflow-x": "hidden"
                                                }}>
                                                {bidData.map((item) => {
                                                    const user = item.userData && item.userData.length > 0 ? item.userData[0] : ''
                                                    const bidder = item.bidder;
                                                    return (
                                                        <div className="bid_box_r" key={item._id}>
                                                            <div className="userC" style={{ backgroundImage: `url(${item.userData && item.userData.length > 0 ? imgsrc + user.profileImage : avtar1})` }}  ></div>
                                                            <div className="bid_box_r_d">
                                                                <p className="headingC">Bid Placed {Oneto11}{convertPrice(item.amount / 1000000000000000000)} by {user ? <a href={profileUrl + user.displayName}>{user.displayName}</a> : (<div className="copyLink d-flex">
                                                                    <input
                                                                        className="m-0" style={{ fontWeight: "500", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                                        type="text"
                                                                        defaultValue={bidder}
                                                                        placeholder="Type some text here"
                                                                        disabled={true}
                                                                    />
                                                                    <CopyToClipboard text={bidder} onCopy={() => { swal("Address Copied !!", { button: false, icon: "success", timer: 2000 }); }}>
                                                                        <div className="copy-area">
                                                                            <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                                                        </div>
                                                                    </CopyToClipboard>
                                                                </div>)}</p>
                                                                <Moment date={item.date} format={"DD/MM/YYYY, hh:mm"} />
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            :
                                            <div className="bid_box">
                                                Bid not found.
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="numbersbx">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="n_box">
                                            <p className="ttl_l color_text">Minimum bid</p>
                                            <h5 className="price_l">Initial price: {Oneto11}{(nftData.price) / 10 ** 18} / <b>~${tokenToUsd((nftData.price) / 10 ** 18)}</b><span className="txt_sm color_text"> </span></h5>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="n_box">
                                            <p className="ttl_l color_text">countdown</p>
                                            {/* <div className="price_l"> <CountDown key = {bidTokenId} end={bidTime.end} start={bidTime.start} /></div> */}
                                            <div className="price_l"> <CountDown key={bidTokenId} end={nftData.endTime} start={nftData.startTime} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hrbx hr2"></div>
                            {nftData.startTime < now && nftData.endTime > now ? <button className="btn dtl_btn" data-bs-toggle={userIdCheck !== null && userIdCheck !== '' && userIdCheck !== undefined ? 'modal' : ''} data-bs-target="#bid" onClick={() => { openbox(bidTokenId) }} >
                                Place Bid
                            </button> : null}

                        </div>
                    </div>
                </div>
            </div>
            <div className="ItemActivity">
                <ItemActivity tokenId={bidTokenId} />

            </div>
        </section>
        < Footer />

        <div className="modal commonModal oneForm fade" id="reportM" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" id="reportclose" aria-label="Close"><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Report this item</h3>
                            <form action=" " className="m-0">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">Reason:</label>
                                    <select name="" className="form-control" id="" value={reason} onChange={(event) => { setReason(event.target.value) }}>
                                        <option value=''>Select a reason</option>
                                        <option value="Purchase">Purchase</option>
                                        <option value="Support">Support</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="text-gray fs-6">Additional comments:</label>
                                    <textarea name="" id="" className="form-control" value={comments} onChange={(event) => { setComments(event.target.value) }} placeholder="Explain why you are concerned about it."></textarea>
                                </div>
                            </form>
                            <div className="btnM">
                                <button className="btn bid_btn" onClick={handleReport}>Report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade commonModal oneForm" id="bid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton"><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-4 text-capitalize">Place Bid</h3>
                            <div className="form-group mt-2" style={{ display: "none" }} id="rebalaId">
                                <p style={{ color: "red", border: "1px solid red", borderRadius: "5px", padding: "0 10px", textAlign: "center", margin: "20px 0" }}>Your token balance is low, please approve tokens !</p>
                                <label htmlFor="bidI" className="text-gray fs-6">Approve Your Token</label>
                                <input type="number" min="0" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} className="form-control mb-2" value={reqBalance} onChange={(event) => { setReqBalance(event.target.value) }} />
                                <div className="hr hrbx"></div>
                                <div className="btnM">
                                    <button className="btn bid_btn " id="approveID" onClick={() => { approveBal() }} >Approve</button>
                                </div>
                            </div>
                            <form action=" " className="">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">You must bid more than <span>{Oneto11} {lastBidAmt / 10 ** 18} </span></label>
                                    <input type="number" min="0" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} className="form-control" value={bidAmount} onChange={(event) => { setBidAmount(event.target.value) }} id="bidI" />
                                    <input type="hidden" className="form-control" value={bidTokenId} id="" />
                                </div>
                            </form>

                            <div className="bidMoney"></div>
                            <div className="hr hrbx"></div>
                            <div className="btnM">
                                <button className="btn bid_btn" onClick={() => { placebid(bidAmount, bidTokenId) }}>Bid</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>;
}

PlaceBid.propTypes = propTypes;
PlaceBid.defaultProps = defaultProps;
// #endregion

export default PlaceBid;