/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState}from 'react';
import DealCountDown from '../DealCountDown';
import { Oneto11 } from '../../Details';
import LikesCount from './LikesCount';


export default function Deal(props) {
    const {item} = props;
    const [urlRedirect, setUrlRedirect] = useState(`/Buynow?tokenNo=${item.id}`)
    const now = Math.floor((new Date()).getTime() / 1000);
  return (
    <>
    {parseInt(item.details.endTime) > now ?
    (<div className="dealsCrd" key={item.id}>
    <div className="dealsCard" style={{position:"relative"}}>
        <div className="dealsIMg bannerS" style={{ backgroundImage: `url(${(JSON.parse(item.ipfs)).image})` }}>
            <a  className="redirect-btn dels_ank text-decoration-none" href={urlRedirect} ></a>
            <div className="stockPrice">
                <div className="">
                    <h6>{(JSON.parse(item.ipfs)).name}</h6>
                </div>
                <div className="deal-price">
                    <div className="leftStock">
                        <p>
                            <span>Deal Price</span>
                            <span>{Oneto11} {(parseInt(item.details.price) / 10 ** 18)}</span>
                        </p>
                    </div>
                    <div className="rightStock">
                        <div className="rightStock">
                            <p>
                                {/* <span style={{ fontSize: "16px", color: "#183B56", textTransform: "uppercase", fontWeight: "600" }}>Deal End</span> */}
                                <span className="rightStock rightDeal"><DealCountDown end={item.details.endTime} start = {item.details.startTime}/></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='dealLike' style={{position:"absolute", top: "20px", left: "20px"}}>
            <LikesCount nftId = {item.id}/>
        </div>
    </div>
</div>):null}
    </>
  )
}
