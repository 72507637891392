/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import { ontop, nftMint721Contract, baseUrl } from '../Details';
import { MultiSelect } from "react-multi-select-component";
import qs from 'qs';
import axios from 'axios'
import swal from 'sweetalert';
// #endregion
const propTypes = {};
const defaultProps = {};

const CreateNewSale = () => {

    const[allNftDetails, setAllNftDetails] = useState([]);
    const [selected, setSelected] = useState([]);
    const history = useHistory();

    // #region functions
    const fetchdata = async() => {
        let address = sessionStorage.getItem("address");
        await nftMint721Contract.methods.tokenOwner(address).call().then((nftid) => {
            var data = qs.stringify({
                'id': JSON.stringify(nftid)
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/nft`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };
            axios(config)
                .then(async function (response) {
                    let allData = response.data.data;
                    let options = [];
                    let arr = {};
                    for (let i = 0; i < allData.length; i++) {
                        arr['label'] = JSON.parse(allData[i].ipfs).name;
                        arr['value'] = allData[i].NftId;
                        // arr['image'] = JSON.parse(allData[i].ipfs).image;
                        options.push(arr);
                        arr = {};  
                    }
                    setAllNftDetails(options);
                })
                .catch(function (error) {
                    console.error(error);
                });
        })
    }
    //My remove choice function in React class
    const removeChoice = (e) => {
        const newSelect = selected.filter(
          item => item.label !== e.target.value
        );
        setSelected(newSelect);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if(selected.length > 0){
            history.push({
                pathname:  '/ItemSale',
                state: {
                    data : selected
                }
            });
        }else{
            swal("Please select atleast one item", { button: false, icon: "error", timer: 2000 });
        }
        
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);

    useEffect(() => {
        fetchdata();
    }, []);
    // #endregion
    return <div>
        <Header />
        <section className="exploreWrapper">
            <div className="cstm-container">
                <div className="innr_bck_box">
                    <div className="ibb_l">
                        <Link className="btn viewBtn" to="/MyItems">
                            <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>Back to home
                        </Link>
                    </div>
                </div>
                <div className="loginInner">
                    <div>
                        <h1 className="fw-bold headingC">Create New Sale</h1>
                    </div>
                    <div className="mt-5 w-75 loginData mb-5 bg-white cstmfrm shadow-sm">
                        <form action="" className="">
                            <div className="form-group">
                                <label htmlFor="" className="cstm_lbl">Select NFT</label>
                                <MultiSelect
                                    className="form-control"
                                    options={allNftDetails}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy={"Select"}
                                />
                            </div>
                            <div className="nft_list_g">
                                <ul className="nlg_ul">
                                    {selected.length > 0 ? selected.map((item, i) => (
                                        <li key = {i}>
                                            {/* <span className="icon_i"><img src={item.image} alt="" /></span> */}
                                            <span className="name_ttl">{item.label}</span>
                                            {/* <Link className="crs_btn_r" to="/"><img src="img/cross_icon.png" alt="" /></Link> */}
                                            <button className="crs_btn_r" style={{backgroundImage: "url(../img/cross_icon.png)",border: "0",width:"30px",height: "30px",backgroundColor: "#0000"}} type="button" id="remove-choice" onClick={removeChoice} value={item.label}></button>
                                        </li>
                                    )) : null}

                                </ul>
                                <ul className="nlg_ul">
                                </ul>
                            </div>
                            <div className="hrbx hr"></div>
                            <div className="loginBtn d-flex align-items-center justify-content-between">
                                <button className="btn btn-primary rounded-pill shadow" onClick = {handleSubmit}>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>;
}

CreateNewSale.propTypes = propTypes;
CreateNewSale.defaultProps = defaultProps;
// #endregion

export default CreateNewSale;