/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import avtar1 from '../../Images/avtar1.png'
import axios from 'axios';
import Moment from 'react-moment';
import $ from 'jquery';
import swal from 'sweetalert'
import { baseUrl, imgsrc, contract, profileUrl } from '../../Details';

export default function OfferHistoryModal({ onClose, token }) {

    const [state, setState] = useState({
        loader: true,
        history: []
    })

    useEffect(() => {
        axios.post(`${baseUrl}/getofferHistory`, {
            nftId: token
        }).then((res) => {
            let dt = [];
            if (res.data.success && res.data.data)
                dt = res.data.data;
            setState(preState => ({
                ...preState,
                loader: false,
                history: dt
            }))

        }).catch((err) => {
            console.error(err)
        })
    }, [])

    const recieveOffer = async (token) => {
        let add;

        if (token === "" || token === null || token === undefined) {
            swal({ title: "Something went wrong try again letter", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }

        await contract.methods.saleDetailsByTokenId(token).call().then(data => {
            add = data.seller;
        })

        if (add === "" || add === null || add === undefined) {
            swal({ title: "Please connect to the wallet", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }
        if (add !== (sessionStorage.getItem('address'))) {
            swal({ title: "Please connect to the same wallet, you placed sale with", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        }
        else {

            await contract.methods.reciveOffer(token).send({ from: add }, function (err, result) {
                if (err) {
                    swal({ title: "Transaction Error", icon: "error", background: "#808080", timer: 2000 });
                }
                if (result) {
                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                    setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                }
            }).on('receipt', function (receipt) {
                if (receipt.status === true) {
                    $('.ajax-loader').css("display", "none");
                    swal({ title: "Offer received successfully ", icon: "success", background: "#808080", timer: 2000 });

                } else {
                    $('.ajax-loader').css("display", "none");
                    swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                }
            })
        }
    }

    return (
        <div className="modal fade commonModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false"
            style={{
                display: "block",
                background: "rgba(0, 0, 0, 0.3)",
                opacity: 1
            }}
        >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" onClick={onClose}><i
                            className="ri-close-line"></i></button>

                        {
                            state.loader ?

                                <div className="ajax-loaderCtn" style={{ display: "flex" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <p id="loaderText" style={{ color: "#000" }}>Loading....</p>
                                        <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt="" />
                                    </div>
                                </div>

                                :
                                <div className="m-history-wrapper"
                                    style={{
                                        maxHeight: "320px",
                                        "overflow-x": "hidden"
                                    }}>
                                    <h3 className="heading fs-5 text-capitalize mb-3">Offers</h3>
                                    <div className="cardHistoy d-flex align-items-center justify-content-center flex-column">
                                        {state.history.map((item) => {
                                            const user = item.userData && item.userData.length > 0 ? item.userData[0] : '';
                                            const highest = Math.max.apply(Math, state.history.map(function (o) {
                                                return o.amount / 10 ** 18
                                            }))
                                            const amount = item.amount / 10 ** 18;
                                            return (
                                                <div className="hCard border" key={item._id}>
                                                    <div className="userC" style={{ backgroundImage: `url(${item.userData && item.userData.length > 0 ? imgsrc + user.profileImage : avtar1})` }}></div>
                                                    <div>
                                                        <p className="fs-16 mb-1 headingC" style={{ width: "200px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{amount} amount by {user ? <a href={profileUrl + user.displayName} rel="noreferrer" target="_blank">{user.displayName}</a> : item.offerer}</p>
                                                        <p className="m-0 headingC">
                                                            <Moment date={item.date} format={"DD/MM/YYYY, hh:mm"} />
                                                        </p>
                                                        {amount >= highest ? <button className="bid_btn btn" onClick={() => { recieveOffer(item.nftId) }} >Receive Offer</button> : ''}

                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    {!state.loader && state.history.length === 0 && (
                                        <div className="cardHistoy d-flex align-items-center justify-content-center flex-column">
                                            Offers not found.
                                        </div>
                                    )}
                                </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}
