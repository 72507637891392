/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
export default function socialShare(props) {
    const {url,img, desc, title } = props
    return ( 
    <>
    <li><a className="profile-share-icon" href={`http://www.facebook.com/sharer.php?u=${url}`} target={'_blank'} rel="noreferrer"><i className="ri-facebook-line"></i></a></li>
    <li><a className="profile-share-icon" href={`https://twitter.com/share?url=${url}`} target={'_blank'} rel="noreferrer" ><i className="ri-twitter-line"></i></a></li>
    <li><a className="profile-share-icon" href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} target={'_blank'} rel="noreferrer" ><i className="ri-linkedin-line"></i></a></li>
    <li><a className="profile-share-icon" href={`https://api.whatsapp.com/send?text=${url}`} data-action="share/whatsapp/share" target={'_blank'} rel="noreferrer"><i className="ri-whatsapp-line"></i></a></li>
    </>
    )
}