/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link, useHistory,useLocation} from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import Header from './Header'
import $ from "jquery";
import Footer from './Footer'
import swal from 'sweetalert';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { baseUrl,ontop, nftMint721Contract,nftMint721Address,imgsrc,chain,web3,blockexplorer } from '../Details';
import { getSoldNFTOwner } from "../helpers/helper"
import avtar1 from '../Images/avtar1.png'
import ItemActivity from './common/ItemActivity';
import SocialShare from "./common/SocialShare";
// #endregion

const propTypes = {};
const defaultProps = {};


const SoldOut = () => {
    const history = useHistory();
    const location = useLocation();
    if(location.state){
        localStorage.setItem("buyer", location.state.userId);
    }
    const search = useLocation().search;
	const [reason, setReason] = useState('');
	const [comments, setComments] = useState('');
	const [buyTokneId, setBuyTokneId] = useState('');
	const [price, setPrice] = useState();
	const [imgUrl, setImageUrl] = useState('');
	const [name, setName] = useState('');
	const [userName, setUserName] = useState('');
	const [userData, setUserData] = useState({});
	const [desc, setDesc] = useState('');
	const [likesNum, setLikesNum] = useState(0)
	const [reqBalance, setReqBalance] = useState(0)
    const [hash, setHash] = useState('');
    const [socialUrl, setSocialUrl] = useState(window.location.href);
    const [userByAdd , setuserByAdd] = useState(false);
    const [buyer, setBuyer] = useState();
    const[baseUri, setBaseUri] = useState('');
    const[liked, setLiked] = useState(false);
    const[user,setUser] = useState('');
   
    //region function
    const countLikes = () => {
		return new Promise((resolve) => {
			const tokenId = new URLSearchParams(search).get('tokenNo');
			if(tokenId !== null || tokenId !== '' || tokenId !== undefined){
				const config = {
					method: 'get',
					url: `${baseUrl}/count-likes?nftid=${tokenId}`,
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Access-Control-Allow-Origin': '*'
						}
					};
					
					axios(config)
					.then(function (response) {
					if(response.data.success === true){
						resolve(setLikesNum(response.data.data))
					}
					})
					.catch(function (error) {
					console.error(error);
					});
			}
		})
		
		
	}
	// Add likes
	const addLikes = () => {
        $('.ajax-loader').css("display", "flex");
        let userId = sessionStorage.getItem("userid");
        let Id = buyTokneId;
        if(userId !== '' && userId !== null && userId !== undefined){
            if(Id !== '' && Id !== null && Id !== undefined){
                const data = qs.stringify({
                    'likedby': userId,
                    'likedto': Id 
                  });
                  const config = {
                    method: 'post',
                    url: `${baseUrl}/add-likes`,
                    headers: { 
                      'Content-Type': 'application/x-www-form-urlencoded',
                      'Access-Control-Allow-Origin': '*'
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                    if(response.data.success === true){
                        swal("Liked successfully", { button: false, icon: "success", timer: 2000 });
                        $('.ajax-loader').css("display", "none");
                        setLiked(true);
                        countLikes();
                    }
                    else if(response.status === 203){
                        const data = qs.stringify({
                            'likedby': userId,
                            'likedto': Id
                        });
                          const config = {
                            method: 'post',
                            url: `${baseUrl}/remove-likes`,
                            headers: { 
                              'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            data : data
                          };
                          
                          axios(config)
                          .then(function (response) {
                            if (response.data.success === true) {
                                swal("Unliked successfully", { button: false, icon: "success", timer: 2000 });
                                $('.ajax-loader').css("display", "none");
                                setLiked(false);
                                countLikes();
                            }
                          })
                          .catch(function (error) {
                            console.error(error);
                            $('.ajax-loader').css("display", "none");
                          });
                    }
                    else{
                        swal(response.data.msg, { button: false, icon: "error", timer: 2000 });
                        $('.ajax-loader').css("display", "none");
                    }
                  })
                  .catch(function (error) {
                    console.error(error);
                    $('.ajax-loader').css("display", "none");
                  });
            }else{
                swal("Some issue while processing your request", { button: false, icon: "warning", timer: 2000 });
                $('.ajax-loader').css("display", "none");
            }
        }else{
            swal("Please log In to like NFT", { button: false, icon: "warning", timer: 2000 });
            $('.ajax-loader').css("display", "none");
        }
    }
    //handle report
    const handleReport = () => {

		let userId = (sessionStorage.getItem("userid"));
		let Reason = reason.trim();
		let Comment = comments.trim();
		let nftId = buyTokneId;
		if (userId === '' || userId === null || userId === undefined) {
			return swal("Please login to report", { button: false, icon: "warning", timer: 2000 });
		};
		if (Reason === null || Reason === '' || Reason === undefined) {
			return swal("Please enter valid reason", { button: false, icon: "warning", timer: 2000 });
		}
		if (Comment === '' || Comment === null || Comment === undefined) {
			return swal("Please enter valid comment", { button: false, icon: "warning", timer: 2000 });
		};
		if (nftId.trim() === '' || nftId.trim() === null || nftId.trim() === undefined) {
			return swal("There is some issue while submitting your request please try again later", { button: false, icon: "warning", timer: 2000 });
		}
		$('.ajax-loader').css("display", "flex");
		const data = qs.stringify({
			'userid': userId,
			'nftid': nftId,
			'reason': Reason,
			'comments': Comment
		});
		const config = {
			method: 'post',
			url: `${baseUrl}/add-report`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Access-Control-Allow-Origin': '*'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					$('.ajax-loader').css("display", "none");
					swal("Report submitted succesfully", { button: false, icon: "success", timer: 2000 });
					setReason('');
					setComments('');
                    document.getElementById('reportclose').click();
				} else {
					$('.ajax-loader').css("display", "none");
					swal("There is some techincal issue! please try again later", { button: false, icon: "success", timer: 2000 });
				}
			})
			.catch(function (error) {
				console.error(error);
				$('.ajax-loader').css("display", "none");
			});
	}

    const checkLike = () =>{
        let userId = sessionStorage.getItem("userid");
        let Id = buyTokneId;
        if (userId !== '' && userId !== null && userId !== undefined) {
            if (Id !== '' && Id !== null && Id !== undefined) {
                const data = qs.stringify({
                    'userid': userId,
                    'nftid': Id 
                });
                const config = {
                    method: 'post',
                    url: `${baseUrl}/check-likes`,
                    headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data : data
                };
                
                axios(config)
                .then(function (response) {
                    if(response.data.success === true){
                        if(response.data.data === 1){
                            setLiked(true);
                        }else{
                            setLiked(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
            }
        }
    }
    //endregion

    //use effects
    useEffect(() => {
        var body = document.body;
		body.classList.remove("connectWalletWrapper");
        ontop();
        
        countLikes();
    }, []);
    
    useEffect(()=>{
        const fetchNft = async() =>{
            const tokenId = new URLSearchParams(search).get('tokenNo');
            if(tokenId === null || tokenId === undefined || tokenId === ''){
                history.push('/')
            }else{
                let owner;
                if(web3.givenProvider !== null && web3.givenProvider.chainId === chain){
                    owner = await nftMint721Contract.methods.ownerOf(tokenId).call()
                }else{
                    owner = await getSoldNFTOwner(tokenId)
                }
                
                    setBuyer(owner)
                    var config = {
                        method: 'get',
                        url: `${baseUrl}/userdetails-address?address=${owner}`,
                        headers: { 
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        }
                      };
                      
                      axios(config)
                      .then(function (response) {
                          if(response.data.success === true){
                            let username = response.data.data.displayName;
                            setUserName(username);
                            setUserData(response.data.data)
                          }else if(response.status === 203){
                            setuserByAdd(true)
                          }
                        
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                // })
        
                // setPrice(buyprice);
                setBuyTokneId(tokenId);
                var idArr = [];
                idArr.push(tokenId);
                const data = qs.stringify({
                    'id': JSON.stringify(idArr)
                });
                  config = {
                    method: 'post',
                    url: `${baseUrl}/nft`,
                    headers: { 
                      'Content-Type': 'application/x-www-form-urlencoded',
                      'Access-Control-Allow-Origin': '*'
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(async function (response) {
                      if(response.data.success === true){
                        var data = response.data.data;
                        window.test = data;
                        data = JSON.parse((data[0].ipfs));
                        setImageUrl(data.image);
                        setName(data.name);
                        setDesc(data.description);
                        setHash(`${blockexplorer}/token/${nftMint721Address}?a=${tokenId}`)
                        setBaseUri(data.uri)
                        var access = document.getElementById('userbtn');
                        if(userName !== ''){
                            access.href = `/profile/${userName}`;
                        }
    
                        // const uri = await nftMint721Contract.methods.getbaseURI().call();
                        // setBaseUri(`${uri}${tokenId}`);
                      } else{
                        swal("Invalid deatails", { button: false, icon: "warning", timer: 2000 });
                        history.push('/');
                  }
                     
                    
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
            }
        }
        fetchNft();
    },[history, search, userName, buyer]);

    useEffect(()=>{
        let userId = sessionStorage.getItem("userid");
        if(userId !== null && userId !== undefined && userId !==''){
            setUser(userId)
        }
    },[sessionStorage.getItem("userid")]);

    useEffect(()=>{
        checkLike();
    },[liked,user,buyTokneId]);


    return <div>
            <Header/>
            <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
                <section className="exploreWrapper">
                    <div className="card-details-wrapper">
                        <div className="cstm-container">
                            <div className="viewAllbtnWrapper text-start">
                                <Link className="btn viewBtn rounded-pill mb-5" to="/">
                                    <i className="ri-arrow-left-line fs-4 me-2"></i>
                                    <p className="m-0">Back to home</p>
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 dtl_lft_bx">
                                    <img className="img-fluid" src={imgUrl} alt=""/>
                                </div>
                                <div className="col-lg-6 details_box">
                                    <h3 className="cardHeading headingC fw-bold">{name}</h3>
                                    <div className="views_box_btn">
                                        <div className="views_bx">
                                            {/* <div className="vies_cnt">1 of 1</div> */}
                                            <div className="hrt_cnt" onClick={addLikes}>
                                            {liked ? <i className="ri-heart-fill"></i> : <i className="ri-heart-line"></i>}{likesNum}
                                            </div>
                                        </div>
                                        <div className="share_box">
                                            <div className="sharebtn_down dropdown">
                                                <button className="btn sharebtnd" id="social" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="ri-share-line"></i>
                                                </button>
                                                <ul className="dropdown-menu socialicon" aria-labelledby="social">
                                                   <SocialShare url={socialUrl}/>
                                                </ul>
                                            </div>
                                            <div className="sharebtn_down dropdown report">
                                                <button className="btn sharebtnd" id="report" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="ri-more-fill"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="report">
                                                    <li>
                                                        <Link to="/" data-bs-toggle="modal" data-bs-target="#reportM">
                                                        <i className="ri-flag-line"></i> Report
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown vpabtnbx">
                                        <button className="btn vpbtn dropdown-toggle" type="button" id="vpadpdn" data-bs-toggle="dropdown" aria-expanded="false">
                                        View proof of authenticity
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="vpadpdn">
                                            <li>
                                            <a rel="noreferrer" href={baseUri}  target = "_blank">
                                                <span><img src="img/ipfs.png" alt="" className="me-2"/></span>
                                                <span>View on IPFS</span>
                                                <span><i className="ri-external-link-line"></i> </span>
                                                </a>
                                            </li>
                                            <li>
                                            <a rel="noreferrer" href={hash} target = "_blank">                                        
                                                <span><img src="img/polygon.png" alt="" className="me-2"/></span>
                                                <span>View on Polyscan</span>                                        
                                                <span><i className="ri-external-link-line"></i></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="details_tabbx">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="active btn" id="pills-Details-tab" >Details</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* <button className="btn" id="pills-Bid-tab" data-bs-toggle="pill" data-bs-target="#pills-Bid" type="button" role="tab" aria-controls="pills-Bid" aria-selected="false">Bid</button> */}
                                            </li>
                                            {/* <li className="nav-item" role="presentation">
                                                <button className="btn" id="pills-History-tab" data-bs-toggle="pill" data-bs-target="#pills-History" type="button" role="tab" aria-controls="pills-History" aria-selected="false">History</button>
                                            </li> */}
                                        </ul>
                                        <div className="hrbx hr"></div>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-Details" role="tabpanel" aria-labelledby="pills-Details-tab">
                                                <p>{desc}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="numbersbx">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="n_box">
                                                    {/* <p className="ttl_l color_text">Price</p>
                                                    <h4 className="price_l">{(price/10**18)}<span className="txt_sm color_text">&nbsp;1To11</span></h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hrbx hr2"></div>
                                    <div className="align-items-center d-flex justify-content-between">
                                       {!userByAdd ? <a href='' id = 'userbtn' className="nm_ank">
                                            <img className="img-fluid" style = {{borderRadius:"50%",height:"30px",width:"30px"}} src={imgsrc+userData.profileImage} alt=""/>{userName}
                                        </a> : <a href=''  className="nm_ank">
                                            <img className="img-fluid" src={avtar1} alt=""/>{(<div className="copyLink d-flex">
                                                        <input
                                                            className="m-0" style={{ fontWeight: "500", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                            type="text"
                                                            defaultValue={buyer}
                                                            placeholder="Type some text here"
                                                            disabled = {true}
                                                        />
                                                        <CopyToClipboard text={buyer} onCopy={() => { swal("Address Copied !!", { button: false, icon: "success", timer: 2000 }); }}>
                                                            <div className="copy-area">
                                                                <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                                            </div>
                                                        </CopyToClipboard>
                                                    </div>) }
                                        </a>} 
                                        <button className="btn soldout">Sold Out</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ItemActivity tokenId={buyTokneId} />
                </section>
            <Footer/>
            <div className="modal commonModal oneForm fade" id="reportM" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" id="reportclose" aria-label="Close"><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Report this item</h3>
                            <form action=" " className="m-0">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">Reason:</label>
                                    <select name="" className="form-control" id="" value={reason} onChange={(event) => { setReason(event.target.value) }}>
                                        <option value=''>Select a reason</option>
                                        <option value="Purchase">Purchase</option>
                                        <option value="Support">Support</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="text-gray fs-6">Additional comments:</label>
                                    <textarea name="" id="" className="form-control" value={comments} onChange={(event) => { setComments(event.target.value) }} placeholder="Explain why you are concerned about it."></textarea>
                                </div>
                            </form>
                            <div className="btnM">
                                <button className="btn bid_btn" onClick={handleReport}>Report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

SoldOut.propTypes = propTypes;
SoldOut.defaultProps = defaultProps;
// #endregion

export default SoldOut;