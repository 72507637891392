/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery'
import { contract, tokenContract, web3, ContractAddress,Oneto11 } from '../../Details';

export default function Bidmodal(props) {
    const history = useHistory();
    const [tokenNo, setTokenNo] = useState(props.token);
    const [bidAmount, setBidAmount] = useState('');
    const [bidTokenId, setBidTokenId] = useState('');
    const [lastBidAmt, setLastBidAmt] = useState(0);
    const [reqBalance, setReqBalance] = useState(0);

    useEffect(() => {
        openbidbox()
    }, [props.show])

    const openbidbox = async () => {
        let address = sessionStorage.getItem("address");
        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== '' && userId !== undefined) {
            if (address !== null && address !== '' && address !== undefined) {
                let token = tokenNo;
                let lastBid = await contract.methods.getAuction(token).call();
                let highestBid = lastBid.highestBid;
                if (highestBid === '0') {
                    setLastBidAmt(parseInt(lastBid.price));
                } else {
                    setLastBidAmt(parseInt(highestBid));
                }
                setBidTokenId(token);
            } else {

                document.getElementById("closeButton").click();
                swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/ConnectWallet') }, 2000);
            }
        }
        else {
            document.getElementById("closeButton").click();
            swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { history.push('/Login') }, 2000);
        }
        
    }

    if (!props.show) {
        return null;
    } else {

        // Approve token
        const approveBal = async () => {
            let address = sessionStorage.getItem('address');
            let reqbal = reqBalance;
            let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
            UserTokenBalance = await web3.utils.fromWei(UserTokenBalance, "ether")

            if (reqbal !== "" && reqbal > 0) {
                if (parseInt(UserTokenBalance) >= parseInt(reqbal)) {
                    await tokenContract.methods.approve(ContractAddress, (await web3.utils.toWei(reqbal, "ether"))).send({ from: address }, function (err, result) {
                        if (err) {
                            swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                        }
                        if (result) {
                            swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                            setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                        }
                    }).on('receipt', function (receipt) {
                        if (receipt.status === true) {
                            $('.ajax-loader').css("display", "none");
                            setTimeout(() => {
                                swal({ title: "Your token balance approved", icon: "success", background: "#808080", timer: 2000 });
                                document.getElementById("rebalaId").style.display = "none";
                            }, 2000);
                        } else {
                            $('.ajax-loader').css("display", "none");
                            swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                        }
                    })
                }
                else {
                    swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                }
            }
            else {
                swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
            }

        }
        //place Bid
        const placebid = async (e, amt, Id) => {
            e.preventDefault();
            let address = sessionStorage.getItem("address");
            let userId = sessionStorage.getItem("userid");
            if (userId !== null && userId !== '' && userId !== undefined) {
                if (address !== null && address !== '' && address !== undefined) {
                    let autionDetails = await contract.methods.getAuction(Id).call();
                    let endtime = autionDetails.endTime;
                    let starttime = autionDetails.startTime
                    const now = Math.floor((new Date()).getTime() / 1000);
                    if(parseInt(starttime) < now){
                        if (parseInt(endtime) > now) {
                            if (amt !== "" && amt > 0) {
    
                                let allowance = await tokenContract.methods.allowance(address, ContractAddress).call();
                                let UserTokenBalance = await tokenContract.methods.balanceOf(address).call();
    
                                if (amt > Number(autionDetails.price) / 10 ** 18) {
                                    if (amt <= Number(UserTokenBalance) / 10 ** 18) {
                                        if (amt <= Number(allowance) / 10 ** 18) {
                                            if (amt > Number(autionDetails.highestBid / 10 ** 18)) {
                                                await contract.methods.bid(Id, (await web3.utils.toWei(amt, "ether"))).send({ from: address }, function (err, result) {
                                                    if (err) {
                                                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                                                        document.getElementById("closeButton").click();
                                                    }
                                                    if (result) {
                                                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);
                                                    }
                                                }).on('receipt', function (receipt) {
                                                    if (receipt.status === true) {
                                                        $('.ajax-loader').css("display", "none");
                                                        swal({ title: "Your bid is successfully placed", icon: "success", background: "#808080", timer: 2000 });
                                                        setTimeout(() => {
                                                            document.getElementById("closeButton").click();
                                                        }, 2000);
    
    
                                                    } else {
                                                        $('.ajax-loader').css("display", "none");
                                                        swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                                                    }
                                                })
                                            }
                                            else {
                                                swal({ title: "Your bid amount is low From Highest bid.", icon: "error", background: "#808080", timer: 2000 });
                                            }
                                        }
                                        else {
                                            // swal({ title: "Please approve the token", icon: "error", background: "#808080", timer: 2000 });
                                            document.getElementById("rebalaId").style.display = "block";
                                        }
                                    }
                                    else {
                                        swal({ title: "Token amount is low.", icon: "error", background: "#808080", timer: 2000 });
                                    }
                                }
                                else {
                                    swal({ title: "Please enter more than last price", icon: "error", background: "#808080", timer: 2000 });
                                }
    
    
                            }
                            else {
                                swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
                            }
                        } else {
                            swal({ title: "This Auction is over", icon: "error", background: "#808080", timer: 2000 });
                        }
                    }else {
                        swal({ title: "This Auction isn't started", icon: "error", background: "#808080", timer: 2000 });
                    }
                    


                } else {

                    document.getElementById("closeButton").click();
                    swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                    setTimeout(() => { history.push('/ConnectWallet') }, 2000);
                }
            }
            else {
                document.getElementById("closeButton").click();
                swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                setTimeout(() => { history.push('/Login') }, 2000);
            }
        }

        const closebutton = () => {
            var showcls = document.getElementById("bid");
            showcls.classList.remove("show");
            showcls.classList.remove("d-block");
            showcls.style.backgroundColor = '#0000';
        }

        return (
            <div>
                <div className="ajax-loader" style={{ display: "none" }}>
                    <div style={{ textAlign: "center" }}>
                        <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                        <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
                    </div>
                </div>
                <div className="modal fade show d-block commonModal oneForm" id="bid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton" onClick={props.onClose}><i
                                    className="ri-close-line"></i></button>
                                <div className="m-history-wrapper">
                                    <h3 className="heading fs-4 mb-4 text-capitalize">Place Bid</h3>
                                    <div className="form-group mt-2 mb-5" style={{ display: "none" }} id="rebalaId">
                                        <p style={{color:"red",border: "1px solid red",borderRadius: "5px",padding: "0 10px",textAlign: "center",margin: "20px 0"}}>Your token balance is low, please approve tokens !</p>
                                        <label htmlFor="bidI" className="text-gray fs-6">Approve Your Token</label>
                                        <input type="number" min="0" onKeyPress={(event) => {  if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} className="form-control mb-2" onChange={(event) => { setReqBalance(event.target.value); }} />
                                        <div className="hr hrbx"></div>
                                        <div className="btnM">
                                            <button className="btn bid_btn " id="approveID" onClick={() => { approveBal(); }}>Approve</button>
                                        </div>
                                    </div>
                                    <form onSubmit={() => { placebid(bidAmount, bidTokenId); }} className="">
                                        <div className="form-group">
                                            <label htmlFor="bidI" className="text-gray fs-6">You must bid more than <span> {Oneto11} {(lastBidAmt) / (10 ** 18)}</span></label>
                                            <input type="number" min="0" className="form-control" value={bidAmount}  onKeyPress={(event) => {  if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} onChange={(event) => { setBidAmount(event.target.value)}} id="bidI" />
                                            <input type="hidden" className="form-control" value={bidTokenId} id="" />
                                        </div>
                                        <div className="btnM">
                                            <button type = "submit" className="btn bid_btn" onClick={(e) => { e.preventDefault();placebid(e,bidAmount, bidTokenId); }}>Bid</button>
                                        </div>
                                    </form>

                                    <div className="bidMoney"></div>
                                    <div className="hr hrbx"></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="modal fade commonModal" id="watchListing" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton" onClick={closebutton}><i
                                    className="ri-close-line"></i></button>
                                <div className="m-history-wrapper">
                                    <h3 className="heading fs-4 text-center mb-3 text-capitalize">Your Bid Successfuly Added</h3>
                                    <p className="text-center text-gray">your bid <span className="fw-bold"> {Oneto11} ({bidAmount})</span> has been listing to our database
                                    </p>
                                    <div className="hr hrbx"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }

}
