import React, { useState, useEffect, useCallback } from 'react';
import {baseUrl, } from '../../Details';
import axios from 'axios';

function SearchBox() {
    const [searchVal, setSearchVal] = useState('')
    const [searchData, setSearchData] = useState([])


    const searchNft = useCallback(() => {
        axios.post(`${baseUrl}/search-nft`, {
            search: searchVal
        }).then((result) => {
            if (result.data.success) {
                setSearchData(result.data.data.slice(0, 6))
            } else {
                setSearchData([])
            }
        }).catch((err) => {
            setSearchData([])
            console.error(err)
        })
    }, [searchVal])

    const enterSearch = (event) =>{
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            let url= `/allSearch?searchVal=${searchVal}`
            window.location.href = url;
        }
    }

    useEffect(() => {
        if (searchVal) searchNft()
        else setSearchData([])
    }, [searchNft, searchVal]);
  return (
    <div>
        <form className="d-flex m-0 ms-5">
            <input onKeyPress={(event)=>{enterSearch(event)}} className="form-control me-2" type="search" placeholder="Search" aria-label="Search"
                name="search" value={searchVal} onChange={(e) => {
                    setSearchVal(e.target.value.replace(/[^\w\s]/gi, ""))
                }} />
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15.369" height="15.373" viewBox="0 0 15.369 15.373">
                <defs>
                    <clipPath id="a">
                        <rect width="15.369" height="15.373" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#a)">
                    <g transform="translate(-547.461 -43)">
                        <path
                            d="M19.689,18.754l-4.275-4.315a6.092,6.092,0,1,0-.925.937l4.247,4.287a.658.658,0,0,0,.929.024A.662.662,0,0,0,19.689,18.754Zm-9.061-3.326a4.81,4.81,0,1,1,3.4-1.409A4.78,4.78,0,0,1,10.628,15.428Z"
                            transform="translate(542.961 38.507)" fill="#fff" />
                    </g>
                </g>
            </svg>
            {searchData && searchData.length > 0 && searchVal && (
                <div className='search-list-outer'>
                    <div className='search-list-inner'>
                    <p className="recentTxt" style={{padding: "15px 10px 5px",fontSize:"12px"}}>RECENT SEARCH</p>
                        {searchData.map((item) => {
                            const data = JSON.parse(item.ipfs)
                            return (

                                <a href={`/allSearch?searchVal=${searchVal}`}>
                                    <div className='search-item' key={item._id}>
                                        {data.name}
                                    </div>
                                </a>
                            )
                        })}
                        <a href={`/allSearch?searchVal=${searchVal}`}>
                            <div className='show-all'>
                                Show All
                            </div>
                        </a>
                    </div>
                </div>
            )}
        </form>
    </div>
  )
}

export default SearchBox