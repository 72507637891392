/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState} from 'react';
import HistoryItem from './HistoryItem';
import LikesCount from './LikesCount';
import { Oneto11,tokenToUsd } from '../../Details';
export default function SoldCrad(props) {
    const {item} = props;
    const [showHistory, setShowHistory] = useState(false);
    let url = `/SoldOut?tokenNo=${item.nftId}`
  return (
    <div>
        <div>
        <div className="card" key = {item.nftId} style={{position:"relative"}}>
            <div style={{position:"relative"}} >
                <a href= {url} style={{position:"relative"}}>
                    <div className="cardImg">
                        {'soldData' in item ?
                            <img src={(JSON.parse(item.soldData[0].ipfs)).image} className="card-img-top" alt="..."/> : <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                        }
                        
                    </div>
                </a>
                <LikesCount nftId = {item.nftId}/>
            </div>
            
            <div className="card-body">
                <a href= {url} className="card-text text-decoration-none">
                    {'soldData' in item ? 
                        <span>{(JSON.parse(item.soldData[0].ipfs)).name}</span> : <span>{(JSON.parse(item.ipfs)).name}</span>
                    }
                
                </a>
                <div className="d-flex mb-2 justify-content-between w-100 align-items-baseline">
                       <span className = "pricePlusLike">Price: {Oneto11}{Number((item.amount))/10**18} / <b>~${tokenToUsd(Number((item.amount)) / 10 ** 18)}</b> </span>
                       
                </div>
                <div className="history_View justify-content-end">
                    <button className="bid_btn btn" style={{backgroundColor:"#FD507E",pointerEvents:"none"}}>Sold</button>
                </div>
            </div>
            {/* <img src="img/soldout.png" className="" alt="" style={{position:"absolute",width: "70px",right: "5px"}} /> */}
            
        </div>
    </div>
    {showHistory && (
        <HistoryItem token={item.nftId} onClose={() => setShowHistory(false)} />
        )
        }
    </div>
  )
}