/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */

// #region constants
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import qs from 'qs';
import $ from "jquery";
import axios from 'axios';
import Particles from "react-tsparticles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SearchBox from "./common/SearchBox";
import Deal from './common/Deal';
import Auction from './common/Auction';
import Sale from './common/Sale';
import SoldCrad from './common/SoldCard';
import PriceComponent from './common/PriceComponent';
import profileImg from '../Images/profile.jpg'
import swal from 'sweetalert';
import { imgsrc, baseUrl, contract, tokenContract, web3, ontop, formatCount, Oneto11, chainUrl, chain } from '../Details';
import { allNFTSort, DealNftSort, getRunningDeal, getRunningAuctions, getRunningSale } from "../helpers/helper"
import logo from '../Images/logo.png'
import fb from '../Images/fb.png'
import insta from '../Images/insta.png'
import twt from '../Images/twt.png'
import telegram from '../Images/telegram.png'
import NewListCard from './common/NewListCard';
import ScrollTop from './common/ScrollTop';

var arr1 = false, arr2 = false, arr3 = false;
var batch;
// #endregion

const propTypes = {};
const defaultProps = {};


const Home = () => {
    // #region functions
    var address = sessionStorage.getItem("address");
    const particlesInit = (main) => {
    };
    const particlesLoaded = (container) => {
    };
    //particle hooks need to load first
    useEffect(() => {
        setInterval(refresh, 1000);
        $('#particles-js canvas').attr('style', 'display: none;');
        $('#particles-js canvas').attr('style', 'position: absolute !important;width: 100% !important; height: 100% !important;z-index: 0 !important; top: 0px !important; left: 0px !important; pointer-events: initial;');
    });

    const history = useHistory();
    const [userBalance, SetUserBalance] = useState('');
    const [userAddress, SetUserAddress] = useState('');
    const [saledata, setSaledata] = useState([]);
    const [auctionData, setAuctionData] = useState([]);
    const [dealData, setDealData] = useState([]);
    const [lowToHigh, setLowToHigh] = useState([]);
    const [highToLow, setHighToLow] = useState([]);
    const [dealHighToLow, setDealHighToLow] = useState([]);
    const [dealLowToHigh, setDealLowToHigh] = useState([]);
    const [soldData, setSoldData] = useState([]);
    const [cricxData, setCricxData] = useState([]);
    const [evolutionxData, setEvolutionxData] = useState([]);
    const [cricxDealData, setCricxDealData] = useState([]);
    const [evolutionxDealData, setEvolutionxDealData] = useState([]);
    const [displayName, setDisplayName] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [social, setSocial] = useState({
        Facebook: '',
        Instagram: '',
        Twitter: '',
        Telegram: ''
    });
    const [userCount, setuserCount] = useState({
        users: '3M',
        dau: 70000,
        mau: 50000,
        revenu: 3500000
    });
    const [searchVal, setSearchVal] = useState('')
    const [searchData, setSearchData] = useState([])
    const [newList, setNewList] = useState([])
    const [newDeal, setNewDeal] = useState([])

    const [text, setText] = useState("");
    // const [isCopied, setIsCopied] = useState(false);
    const onCopyText = () => {
        swal("Address Copied !!", { button: false, icon: "success", timer: 2000 });
    };

    const userDetails = () => {

        let userId = sessionStorage.getItem("userid");
        if (userId !== null && userId !== undefined && userId !== '') {
            $('.ajax-loader').css("display", "flex");
            var config = {
                method: 'get',
                url: `${baseUrl}/user-profile?userid=${userId}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            axios(config)
                .then(function (response) {
                    if (response.data.success === true) {
                        let data = response.data.data;
                        setDisplayName(data.displayName);
                        let imgname = data.profileImage;
                        let imgurl = imgsrc + imgname.trim();
                        console.log("ProfileImage", imgurl);
                        setProfileImage(imgurl)
                        $('.ajax-loader').css("display", "none");
                    } $('.ajax-loader').css("display", "none");

                })
                .catch(function (error) {
                    console.error(error);
                    $('.ajax-loader').css("display", "none");
                });
        }
    }

    useEffect(() => {
        userDetails();
    }, [])

    const getSocials = () => {
        axios.get(`${baseUrl}/getSocialLinks`).then((result) => {
            if (result.data.success) {
                if (result.data.data) {
                    for (let item of result.data.data) {
                        setSocial(val => ({
                            ...val,
                            [item.name]: item.link
                        })
                        )
                    }
                }

            }
        }).then((err) => {
            console.error({ err })
        })
    }
    const getUserCount = () => {
        axios.post(`${baseUrl}/user-count`).then((result) => {
            if (result.data.success) {

                setuserCount({
                    users: '3M',
                    dau: 70000,
                    mau: 50000,
                    revenu: 3500000
                })

            }
        }).then((err) => {
            console.error({ err })
        })
    }

    const allNftDetails = async () => {
        let getArray = setInterval(async () => {
            if (arr1 && arr2) {
                clearInterval(getArray);
                const data = await allNFTSort(getArray, window.auction, window.sale);
                setCricxData(data.cricx);
                setEvolutionxData(data.evolutionx);
                setLowToHigh(data.LowToHigh);
                setHighToLow(data.HighToLow)
            }
        }, 5000);
    }
    const dealNftDetails = async () => {
        let getArray = setInterval(async () => {
            if (arr3) {
                clearInterval(getArray);
                const data = await DealNftSort(getArray, window.deal);
                setCricxDealData(data.cricx);
                setEvolutionxDealData(data.evolutionx);
                setDealLowToHigh(data.LowToHigh);
                setDealHighToLow(data.HighToLow)
            }
        }, 5000);
    }


    const refresh = async () => {
        let userId = sessionStorage.getItem("userid");
        if (userId !== "" && userId !== null && userId !== undefined) {
            let addressLogin = sessionStorage.getItem("address");
            if (addressLogin !== null) {
                var balance = await tokenContract.methods.balanceOf(addressLogin).call();
                SetUserBalance(balance);
                SetUserAddress(addressLogin);

                document.getElementById("beforeWalletConnect").style.display = "none";
                document.getElementById("afterWalletConnect").style.display = "block";
                let metamaskAdd = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if (metamaskAdd[0] !== addressLogin) {
                    web3.eth.getAccounts()
                        .then((account) => {
                            sessionStorage.setItem("address", account[0]);
                        })
                }
            }
        }
    }

    //Logout
    const Logout = () => {
        sessionStorage.removeItem('userid');
        sessionStorage.removeItem('address');
        swal("Logout Successfully", { button: false, icon: "success", timer: 2000 });
        setTimeout(() => { history.push('/'); window.location.reload(false); }, 2000);
    }

    const loginCheck = () => {
        let userId = sessionStorage.getItem("userid");
        if (userId === "" || userId === null || userId === undefined) {
            history.push('/Login');
        } else {
            history.push('/ConnectWallet');
        }
    }

    const Newlisted = () => {
        $('.ajax-loader').css("display", "flex");

        var config = {
            method: 'get',
            url: `${baseUrl}/getnewlisted`,
            headers: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
            }
        };

        axios(config)
            .then(function (response) {

                var dataArr = [];
                var data = response.data.data;
                console.log(data)
                for (let i = 0; i < data.length; i++) {
                    if ("AuctionToken" in data[i]) {
                        console.log('inside')
                        var now = Math.floor((new Date()).getTime() / 1000);
                        console.log(now, 'current time')
                        if (parseInt(data[i].endTime) > now && parseInt(data[i].startTime) < now) {
                            dataArr.push(data[i])
                        }
                    } else {
                        dataArr.push(data[i])
                    }
                }
                if (dataArr.length > 8) {
                    dataArr.length = 8;
                }
                setNewList(dataArr);
                $('.ajax-loader').css("display", "none");
            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loader').css("display", "none");
            });
    }

    const NewDealList = () => {
        $('.ajax-loader').css("display", "flex");
        var config = {
            method: 'get',
            url: `${baseUrl}/getnewdeals`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config)
            .then(function (response) {
                var dataArr = [];
                var data = response.data.data;

                if (data.length > 8) {
                    data.length = 8
                }
                for (let i = 0; i < data.length; i++) {

                    var now = Math.floor((new Date()).getTime() / 1000);
                    if (parseInt(data[i].endTime) > now && parseInt(data[i].startTime) < now) {
                        dataArr.push(data[i])
                    }
                }

                setNewDeal(dataArr);
                $('.ajax-loader').css("display", "none");
            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loader').css("display", "none");
            });
    }

    // Get auction list
    const getAuctionList = async (AuctionLength) => {
        let batch2 = new web3.BatchRequest();
        let auctionArr = {};
        let AuctiontokenIdArr;
        let address = sessionStorage.getItem("address");

        if (AuctionLength === '0') {
            arr1 = true;
            $('.ajax-loader').css("display", "none");
        }
        for (let i = 0; i < AuctionLength; i++) {
            batch2.add(contract.methods.auctionDetails(i).call.request((address, (error, auctionInfo) => {
                if (error) throw error;
                auctionArr[auctionInfo.tokenId] = auctionInfo.auctionInfo;
                AuctiontokenIdArr = Object.keys(auctionArr);

                if (AuctionLength - 1 === i) {

                    var data = qs.stringify({
                        'id': JSON.stringify(AuctiontokenIdArr)
                    });

                    var config = {
                        method: 'post',
                        url: `${baseUrl}/nft`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            let res = response.data.data;
                            console.log('nft api respnse', response.data);
                            let NFTDetail = [];
                            let NFTSet = {};

                            for (var i = 0; i < res.length; i++) {
                                var now = Math.floor((new Date()).getTime() / 1000);
                                if (parseInt(auctionArr[AuctiontokenIdArr[i]]['endTime']) > now && parseInt(auctionArr[AuctiontokenIdArr[i]]['startTime']) < now) {
                                    NFTSet['id'] = AuctiontokenIdArr[i];
                                    NFTSet['ipfs'] = res[i].ipfs;
                                    NFTSet['details'] = auctionArr[AuctiontokenIdArr[i]];
                                    NFTSet['price'] = auctionArr[AuctiontokenIdArr[i]]['price'];
                                    NFTDetail.push(NFTSet);
                                    NFTSet = {};
                                }
                            }
                            $('.ajax-loader').css("display", "none");
                            $('#exploreSecHome').show();
                            setAuctionData(NFTDetail);

                            if (NFTDetail.length > 4) {
                                NFTDetail.length = 4;
                            }
                            window.auction = NFTDetail;
                            arr1 = true;
                        })
                        .catch(function (error) {
                            console.error('', error);
                            $('.ajax-loader').css("display", "none");
                        });
                }
            })));
        }
        batch2.execute();
    }
    // Get sale list
    const getSaleList = async (saleLength) => {
        let batch1 = new web3.BatchRequest();
        let arr = {};
        let tokenIdArr;
        let address = sessionStorage.getItem("address");
        if (saleLength === '0') {
            arr2 = true;
            $('.ajax-loader').css("display", "none");
        }

        for (let i = 0; i < saleLength; i++) {
            batch1.add(contract.methods.saleDetails(i).call.request((address, (error, saleInfo) => {
                if (error) throw error;

                arr[saleInfo.tokenId] = saleInfo.price;
                tokenIdArr = Object.keys(arr);
                if (saleLength - 1 === i) {

                    var data = qs.stringify({
                        'id': JSON.stringify(tokenIdArr)
                    });
                    console.log("Data", data);
                    var config = {
                        method: 'post',
                        url: `${baseUrl}/nft`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            let res = response.data.data;
                            let NFTDetail = [];
                            let NFTSet = {};
                            if (res.length > 4) {
                                res.length = 4
                            }
                            for (var i = 0; i < res.length; i++) {
                                NFTSet['id'] = tokenIdArr[i];
                                NFTSet['ipfs'] = res[i].ipfs;
                                NFTSet['price'] = arr[tokenIdArr[i]];
                                NFTDetail.push(NFTSet);
                                NFTSet = {};
                            }
                            $('.ajax-loader').css("display", "none");
                            $('#exploreSecHome').show();

                            window.sale = NFTDetail;
                            arr2 = true;
                            console.log("NFTDetail", NFTDetail);
                            setSaledata(NFTDetail);
                        })
                        .catch(function (error) {
                            console.error(error);
                            $('.ajax-loader').css("display", "none");
                        });
                }
            })));
        }
        batch1.execute();

    }

    const getDealList = async (saleLength) => {

        let batch3 = new web3.BatchRequest();
        let dealArr = {};
        let dealTokenIdArr;
        let address = sessionStorage.getItem("address");

        if (saleLength === '0') {
            arr3 = true;
            $('.ajax-loader').css("display", "none");
        }
        for (let i = 0; i < saleLength; i++) {
            batch3.add(contract.methods.dealDetails(i).call.request((address, (error, dealInfo) => {
                if (error) throw error;
                dealArr[dealInfo.tokenId] = dealInfo.dealInfo;
                dealTokenIdArr = Object.keys(dealArr);
                if (saleLength - 1 === i) {
                    var data = qs.stringify({
                        'id': JSON.stringify(dealTokenIdArr)
                    });

                    var config = {
                        method: 'post',
                        url: `${baseUrl}/nft`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            let res = response.data.data;
                            let NFTDetail = [];
                            let NFTSet = {};

                            for (var i = 0; i < res.length; i++) {
                                var now = Math.floor((new Date()).getTime() / 1000);
                                if (parseInt(dealArr[dealTokenIdArr[i]]['endTime']) > now && parseInt(dealArr[dealTokenIdArr[i]]['startTime']) < now) {
                                    NFTSet['id'] = dealTokenIdArr[i];
                                    NFTSet['ipfs'] = res[i].ipfs;
                                    NFTSet['details'] = dealArr[dealTokenIdArr[i]];
                                    NFTSet['price'] = dealArr[dealTokenIdArr[i]]['price'];
                                    NFTDetail.push(NFTSet);
                                    NFTSet = {};
                                }
                            }
                            $('.ajax-loader').css("display", "none");
                            $('#dealsSecHome').show();
                            if (NFTDetail.length > 6) {
                                NFTDetail.length = 6
                            }
                            window.deal = NFTDetail;
                            arr3 = true;
                            setDealData(NFTDetail);

                        })
                        .catch(function (error) {
                            console.error(error);
                            $('.ajax-loader').css("display", "none");
                        });
                }


            })));
        }
        batch3.execute();
    }
    //sold nft details
    const soldnftdetails = () => {
        var config = {
            method: 'get',
            url: `${baseUrl}/getSoldHistory`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
        };

        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    let obj = response.data.data
                    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
                        setSoldData([])
                    } else {
                        if (obj.lengh > 8) {
                            obj.length = 8;
                        }
                        setSoldData(obj)
                    }
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }


    const searchNft = useCallback(() => {
        axios.post(`${baseUrl}/search-nft`, {
            search: searchVal
        }).then((result) => {
            if (result.data.success) {
                setSearchData(result.data.data.slice(0, 6))
            } else {
                setSearchData([])
            }
        }).catch((err) => {
            setSearchData([])
            console.error(err)
        })
    }, [searchVal])

    const enterSearch = (event) => {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            let url = `/allSearch?searchVal=${searchVal}`
            window.location.href = url;
        } else {

        }
    }

    const handleWallet = () => {
        $('.ajax-loader').css("display", "flex");
        let address = sessionStorage.getItem('address');
        let userId = sessionStorage.getItem('userid');
        if (userId === '' || userId === null || userId === undefined) {
            swal({ title: "Please logIn and connect to wallet", icon: "error", background: " #808080", timer: 2000 });
        } else if (address === '' || address === null || address === undefined) {
            swal({ title: "Please logIn and connect to wallet", icon: "error", background: " #808080", timer: 2000 });
        } else {
            var data = qs.stringify({
                'id': userId,
                'userAddress': address
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/userAddress`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response.data.success === true) {
                        $('.ajax-loader').css("display", "none");
                        swal({ title: "Wallet address is saved as primary wallet", icon: "success", timer: 2000 });
                    } else {
                        $('.ajax-loader').css("display", "none");

                        swal(response.data.msg, {
                            timer: 3000,
                            icon: "warning",
                            button: false,
                        });
                    }
                })
                .catch(function (error) {
                    $('.ajax-loader').css("display", "none");
                    console.error(error);
                });
        }
    }
    const getData = async () => {
        const sale = await getRunningSale();
        const auction = await getRunningAuctions();
        const deal = await getRunningDeal();

        setAuctionData(auction);
        setSaledata(sale);
        setDealData(deal);
        window.sale = sale
        window.auction = auction
        window.deal = deal
        arr1 = true; arr2 = true; arr3 = true;
        $('.ajax-loader').css("display", "none");
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        if (searchVal) searchNft()
        else setSearchData([])
    }, [searchNft, searchVal])

    useEffect(() => {

        if (web3.givenProvider !== null && web3.givenProvider.chainId === chain) {
            //console.log("Enter Here");
            batch = new web3.BatchRequest();
            batch.add(contract.methods.totalSale().call.request(('0x0000000000000000000000000000000000000000', (error, totalSale) => { if (error) throw error; getSaleList(totalSale) })));
            batch.add(contract.methods.totalAuction().call.request(('0x0000000000000000000000000000000000000000', (error, totalAuction) => { if (error) throw error; getAuctionList(totalAuction) })));
            batch.add(contract.methods.totalDeal().call.request(('0x0000000000000000000000000000000000000000', (error, totalDeal) => { if (error) throw error; getDealList(totalDeal) })));
            batch.execute();
        } else {
            getData()
        }
        allNftDetails();
        dealNftDetails();
    }, []);

    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, [])

    useEffect(() => {
        soldnftdetails();
        getSocials();
        getUserCount();
    }, [])

    useEffect(() => {
        $('#particles-js canvas').attr('style', 'display: none;');
        const receiptInterval = setInterval(function () {
            const element = document.querySelector('#particles-js canvas')
            const position = element.style.position;
            $('#particles-js canvas').attr('style', 'position: absolute !important;width: 100% !important; height: 100% !important;z-index: 0 !important; top: 0px !important; left: 0px !important; pointer-events: initial;');
            if (position === "absolute") {
                $('#particles-js canvas').attr('style', 'position: absolute !important;width: 100% !important; height: 100% !important;z-index: 0 !important; top: 0px !important; left: 0px !important; pointer-events: initial;');
                clearInterval(receiptInterval);
            }

        }, 1000);
        $('#particles-js canvas').attr('style', 'display: none;');
    }, [])
    // #endregion

    return <div>
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <header className="oneto11Hero bannerS">
            <div id="background">
                <Particles className="particle-wrapper"
                    id="particles-js"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        "particles": {
                            "number": {
                                "value": 80,
                                "density": {
                                    "enable": true,
                                    "value_area": 800
                                }
                            },
                            "color": {
                                "value": "#ffffff"
                            },
                            "shape": {
                                "type": "circle",
                                "stroke": {
                                    "width": 0,
                                    "color": "#000000"
                                },
                                "polygon": {
                                    "nb_sides": 5
                                },
                                "image": {
                                    "src": "img/github.svg",
                                    "width": 100,
                                    "height": 100
                                }
                            },
                            "opacity": {
                                "value": 0.5,
                                "random": false,
                                "anim": {
                                    "enable": false,
                                    "speed": 1,
                                    "opacity_min": 0.1,
                                    "sync": false
                                }
                            },
                            "size": {
                                "value": 5,
                                "random": true,
                                "anim": {
                                    "enable": false,
                                    "speed": 40,
                                    "size_min": 0.1,
                                    "sync": false
                                }
                            },
                            "line_linked": {
                                "enable": false,
                                "distance": 150,
                                "color": "#ffffff",
                                "opacity": 0.4,
                                "width": 1
                            },
                            "move": {
                                "enable": true,
                                "speed": 2,
                                "direction": "none",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "attract": {
                                    "enable": false,
                                    "rotateX": 600,
                                    "rotateY": 1200
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "push"
                                },
                                "resize": true
                            },
                            "modes": {
                                "grab": {
                                    "distance": 400,
                                    "line_linked": {
                                        "opacity": 1
                                    }
                                },
                                "bubble": {
                                    "distance": 400,
                                    "size": 40,
                                    "duration": 2,
                                    "opacity": 8,
                                    "speed": 3
                                },
                                "repulse": {
                                    "distance": 200
                                },
                                "push": {
                                    "particles_nb": 4
                                },
                                "remove": {
                                    "particles_nb": 2
                                }
                            }
                        },
                        "retina_detect": true,
                        "config_demo": {
                            "hide_card": false,
                            "background_color": "#b61924",
                            "background_image": "",
                            "background_position": "50% 50%",
                            "background_repeat": "no-repeat",
                            "background_size": "cover"
                        }
                    }}
                />

            </div>
            <div className="oneto11_navbar">
                <div className="cstm-container">
                    <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                        <Link className="navbar-brand" to="/"><img src="img/logo.png" width="100%" alt="" /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <SearchBox />
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link text-white px-3 fs-6" to="/BuyOnly">Buy Only</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-white px-3 fs-6 active" aria-current="page" to="/BidOnly">Bid Only</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white px-3 fs-6" href="#aboutbxtop">About us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white px-3 fs-6" href="/Deals">Deals</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-white px-3 fs-6" to="/ContactUs">Contact US</Link>
                                </li>
                                <li className="nav-item" id="beforeWalletConnect">
                                    <a className="nav-link text-white px-3 fs-6 wallet" href="" onClick={loginCheck}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.56" height="18.056" viewBox="0 0 19.56 18.056" className="me-2">
                                            <g transform="translate(-3.375 -4.499)">
                                                <path
                                                    d="M20.113,11.25H6.2a2.823,2.823,0,0,0-2.821,2.821v7.9A2.823,2.823,0,0,0,6.2,24.791H20.113a2.823,2.823,0,0,0,2.821-2.821v-7.9A2.823,2.823,0,0,0,20.113,11.25Z"
                                                    transform="translate(0 -2.237)" fill="#fff" />
                                                <path
                                                    d="M17.613,4.547,6.569,6.7C5.722,6.893,4.5,7.744,4.5,8.778a2.962,2.962,0,0,1,2.3-.893H20.3V6.921a2.7,2.7,0,0,0-.654-1.768h0A2.224,2.224,0,0,0,17.613,4.547Z"
                                                    transform="translate(-0.373)" fill="#fff" />
                                                <circle cx="1.5" cy="1.5" r="1.5" transform="translate(18.529 14.498)"
                                                    fill="#0661dc" />
                                            </g>
                                        </svg>
                                        <span onClick={loginCheck}>Connect Wallet</span>
                                    </a>
                                </li>
                                <li className="nav-item dropdown" id="afterWalletConnect" style={{ display: "none" }}>
                                    <Link to="/ConnectWallet" className="nav-link text-white px-3 fs-6 wallet walletF" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.56" height="18.056" viewBox="0 0 19.56 18.056" className="me-2">
                                            <g transform="translate(-3.375 -4.499)">
                                                <path
                                                    d="M20.113,11.25H6.2a2.823,2.823,0,0,0-2.821,2.821v7.9A2.823,2.823,0,0,0,6.2,24.791H20.113a2.823,2.823,0,0,0,2.821-2.821v-7.9A2.823,2.823,0,0,0,20.113,11.25Z"
                                                    transform="translate(0 -2.237)" fill="#fff" />
                                                <path
                                                    d="M17.613,4.547,6.569,6.7C5.722,6.893,4.5,7.744,4.5,8.778a2.962,2.962,0,0,1,2.3-.893H20.3V6.921a2.7,2.7,0,0,0-.654-1.768h0A2.224,2.224,0,0,0,17.613,4.547Z"
                                                    transform="translate(-0.373)" fill="#fff" />
                                                <circle cx="1.5" cy="1.5" r="1.5"
                                                    transform="translate(18.529 14.498)" fill="#0661dc" />
                                            </g>
                                        </svg>
                                        <span className="d-flex"><p style={{ width: "60px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{formatCount((userBalance / 10 ** 18).toFixed(8))}</p>

                                            {displayName ? (<p>{displayName}</p>) : (<p>Matic</p>)}

                                        </span>

                                        {profileImage ? (<div className="userFace"><img src={profileImage} alt="" /></div>) : (<div className="userFace"><img src="img/avtar2.png" alt="" /></div>)}
                                    </Link>
                                    <ul className="dropdown-menu wltdrp" aria-labelledby="navbarDropdown">
                                        <div className="copyLink d-flex">
                                            <a href={chainUrl + userAddress} rel="noreferrer" target="_blank"><input
                                                className="m-0" style={{ fontWeight: "500", cursor: "pointer", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                type="text"
                                                value={userAddress}
                                                placeholder="Type some text here"
                                                onChange={(event) => setText(event.target.value)}
                                                disabled={true}
                                            />
                                            </a>
                                            <CopyToClipboard text={userAddress} onCopy={onCopyText}>
                                                <div className="copy-area">
                                                    <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                                </div>
                                            </CopyToClipboard>

                                        </div>


                                        <div className="list_grd">
                                            <div className="prfs_w">
                                                {profileImage ? (<div className="userAvtar">
                                                    <img src={profileImage} alt="" /></div>) : (<div className="userAvtar"><img src="img/avtar2.png" alt="" /></div>)}
                                                <div className="pw_dat">
                                                    <p>Balance</p>
                                                    <p>{Oneto11}{formatCount((userBalance / 10 ** 18).toFixed(8))}</p>
                                                </div>
                                            </div>
                                            <div className="hr hrbx"></div>
                                            <div className="prfs_lstng">
                                                <ul>
                                                    <li><button className="setBtn" style={{
                                                        border: "0", backgroundColor: "transparent", display: "flex", alignItems: "center", color: "#183B56", padding: "4px 0px",
                                                        fontSize: "17px"
                                                    }} type="button" onClick={handleWallet}><i className="ri-wallet-3-fill"></i>&nbsp;&nbsp;&nbsp;Set Wallet</button><p style={{ fontSize: "13px", padding: "0px 0 10px" }}>(set this wallet as your primary wallet)</p></li>
                                                    <li><Link to="/MyItems"><i className="ri-landscape-fill"></i>My items</Link></li>
                                                    <li><Link to="/UpdateProfile"><i className="ri-edit-line"></i>Edit Profile</Link></li>
                                                    <li><Link to="/MyWishlist"><i className="ri-heart-fill"></i>My Wishlist</Link></li>
                                                    <li><Link to="/Claim"><i className="ri-numbers-fill"></i>My Claim</Link></li>
                                                    {/* <li><Link to="/" onClick=""><i className="ri-logout-box-line"></i>Logout</Link></li> */}
                                                    <li><Link to="/" onClick={Logout}><i className="ri-logout-box-line"></i>Logout</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="heroWrapper">
                <div className="cstm-container">
                    <div className="heroContainerWrapper">
                        <div className="heroContent animate__zoomInDown animate__animated mainHeader">
                            <p className="nft text-white text-uppercase fs-5 fw-bold">Oneto11 NFT marketplace</p>
                            <h3 className="heroHeading text-white">Collect Digital Items</h3>
                            <p className="fs-5 heroPara">World's First Blockchain Gaming Ecosystem. Multiple Play-To-Earn games to generate consistent income.
                            </p>
                            <Link to="/Explore" className="oneBtn btn mt-4">Explore</Link>

                            <div className="traction-wrapper">
                                <h4>
                                    {/* <span>{formatCount(userCount?.users)}</span> */}
                                    <span>3M</span>
                                    <span>Users</span>
                                </h4>
                                <h4>
                                    <span>{formatCount(userCount?.dau)}</span>
                                    <span>DAU</span>
                                </h4>
                                <h4>
                                    <span>{formatCount(userCount?.mau)}</span>
                                    <span>MAU</span>
                                </h4>
                                <h4>
                                    <span>${formatCount(userCount?.revenu)}</span>
                                    <span>Revenue</span>
                                </h4>
                            </div>
                        </div>
                        <div className="heroImg">
                            <img src="img/heroImg.png" className="animate__headShake animate__animated " alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section className="exploreWrapper mt-0" id="bynow_bx">
            <div className="cstm-container">
                <div className="innerExplore exptab_bx">
                    <h2 className="heading">Explore</h2>
                    <ul className="nav nav-pills animate__slideInDown animate__animated" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-CricX-tab" data-bs-toggle="pill" data-bs-target="#pills-CricX" type="button" role="tab" aria-controls="pills-CricX" aria-selected="false">CricX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-EvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-EvolutionX" type="button" role="tab" aria-controls="pills-EvolutionX" aria-selected="false">EvolutionX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BuyOnly-tab" data-bs-toggle="pill" data-bs-target="#pills-BuyOnly" type="button" role="tab" aria-controls="pills-BuyOnly" aria-selected="false">Buy Only</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BidOnly-tab" data-bs-toggle="pill" data-bs-target="#pills-BidOnly" type="button" role="tab" aria-controls="pills-BidOnly" aria-selected="false">Bid Only</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-NewListed-tab" onClick={Newlisted} data-bs-toggle="pill" data-bs-target="#pills-NewListed" type="button" role="tab" aria-controls="pills-NewListed" aria-selected="false">New Listed</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-SOLD-tab" onClick={soldnftdetails} data-bs-toggle="pill" data-bs-target="#pills-SOLD" type="button" role="tab" aria-controls="pills-SOLD" aria-selected="false">SOLD</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-High-tab" data-bs-toggle="pill" data-bs-target="#pills-High" type="button" role="tab" aria-controls="pills-High" aria-selected="false">High to Low Price</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-Low-tab" data-bs-toggle="pill" data-bs-target="#pills-Low" type="button" role="tab" aria-controls="pills-Low" aria-selected="false">Low to High Price</button>
                        </li>
                    </ul>
                </div>


                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer" id="All">
                                {/* Auction Nft */}
                                {auctionData.length > 0 ? auctionData.map((item) => (
                                    <Auction key={item.id} item={item} />
                                )) : null}
                                {/* sale NFT */}

                                {saledata.length > 0 ? saledata.map((item) => (
                                    <Sale key={item.id} item={item} />
                                )) : null}
                                {soldData.length > 0 ? soldData.map((item) => (
                                    <SoldCrad key={item.nftId} item={item} />
                                )) : null}
                                {saledata.length === 0 && auctionData.length === 0 && soldData.length === 0 ? <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div> : null}
                            </div>

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-CricX" role="tabpanel" aria-labelledby="pills-CricX-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">

                                {cricxData.length > 0 ? cricxData.map((item) => (
                                    <>
                                        {item.details ? <Auction key={item.id} item={item} /> : <Sale key={item.id} item={item} />}
                                    </>
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    No result found
                                </div>}

                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-EvolutionX" role="tabpanel" aria-labelledby="pills-EvolutionX-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {evolutionxData.length > 0 ? evolutionxData.map((item) => (
                                    <>
                                        {item.details ? <Auction key={item.id} item={item} /> : <Sale key={item.id} item={item} />}
                                    </>
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    No result found
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div id="buyonlybx" style={{ position: "relative", top: "-100px" }}></div>
                    <div className="tab-pane fade" id="pills-BuyOnly" role="tabpanel" aria-labelledby="pills-BuyOnly-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer" id="buyAll">
                                {saledata.length > 0 ? saledata.map((item) => (

                                    <Sale key={item.id} item={item} />
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}

                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-BidOnly" role="tabpanel" aria-labelledby="pills-BidOnly-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer" id="bidOnly">
                                {auctionData.length > 0 ? auctionData.map((item) => (
                                    <Auction key={item.id} item={item} />
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}

                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-NewListed" role="tabpanel" aria-labelledby="pills-NewListed-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">

                                {newList.length > 0 ? newList.map((item, i) => (
                                    <NewListCard key={i} item={item} />
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}
                            </div>

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-SOLD" role="tabpanel" aria-labelledby="pills-SOLD-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {soldData.length > 0 ? soldData.map((item) => (
                                    <SoldCrad key={item.nftId} item={item} />
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-High" role="tabpanel" aria-labelledby="pills-High-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {highToLow.length > 0 ? highToLow.map((item) => (
                                    <PriceComponent key={item.id} item={item} />
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}

                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-Low" role="tabpanel" aria-labelledby="pills-Low-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {lowToHigh.length > 0 ? lowToHigh.map((item) => (
                                    <PriceComponent key={item.id} item={item} />
                                )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}

                            </div>
                        </div>
                    </div>
                    <div className="viewAllbtnWrapper text-center" id="exploreSecHome" style={{ display: "none" }}>
                        <Link className="btn viewBtn" to="/Explore">
                            <i className="ri-restart-line"></i>
                            View all items
                        </Link>
                    </div>

                </div>
            </div>
        </section>

        <div id="dealbxtop" style={{ position: "relative", top: "-100px" }}></div>
        <section className="bg-white exploreWrapper" id="deal_bx">
            <div className="cstm-container">
                <div className="innerExplore dls_bx-m">
                    <h2 className="heading">Deals</h2>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-BAll" type="button" role="tab" aria-controls="pills-BAll" aria-selected="true">All</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BCricX-tab" data-bs-toggle="pill" data-bs-target="#pills-BCricX" type="button" role="tab" aria-controls="pills-BCricX" aria-selected="false">CricX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BEvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-BEvolutionX" type="button" role="tab" aria-controls="pills-BEvolutionX" aria-selected="false">EvolutionX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BNewListed-tab" onClick={NewDealList} data-bs-toggle="pill" data-bs-target="#pills-BNewListed" type="button" role="tab" aria-controls="pills-BNewListed" aria-selected="false">New Listed</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BHigh-tab" data-bs-toggle="pill" data-bs-target="#pills-BHigh" type="button" role="tab" aria-controls="pills-BHigh" aria-selected="false">High to Low Price</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BLow-tab" data-bs-toggle="pill" data-bs-target="#pills-BLow" type="button" role="tab" aria-controls="pills-BLow" aria-selected="false">Low to High Price</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-BAll" role="tabpanel" aria-labelledby="pills-All-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {/* Deal data */}
                            {dealData.length > 0 ? dealData.map((item) => (
                                <Deal key={item.id} item={item} />
                            )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}

                        </div>

                    </div>
                    <div className="tab-pane fade" id="pills-BCricX" role="tabpanel" aria-labelledby="pills-BCricX-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {cricxDealData.length > 0 ? cricxDealData.map((item) => (
                                <NewListCard key={item.DealToken} item={item} />
                            )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                No result found
                            </div>}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-BEvolutionX" role="tabpanel" aria-labelledby="pills-BEvolutionX-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {evolutionxDealData.length > 0 ? evolutionxDealData.map((item) => (
                                <NewListCard key={item.DealToken} item={item} />
                            )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                No result found
                            </div>}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-BNewListed" role="tabpanel" aria-labelledby="pills-BNewListed-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {newDeal.length > 0 ? newDeal.map((item) => (
                                <NewListCard key={item.DealToken} item={item} />
                            )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                No result found
                            </div>}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-BHigh" role="tabpanel" aria-labelledby="pills-BHigh-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {/* Deal data */}
                            {dealHighToLow.length > 0 ? dealHighToLow.map((item) => (
                                <Deal key={item.id} item={item} />
                            )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-BLow" role="tabpanel" aria-labelledby="pills-BLow-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {/* Deal data */}
                            {dealLowToHigh.length > 0 ? dealLowToHigh.map((item) => (

                                <Deal key={item.id} item={item} />
                            )) : <div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>No result found</div>}

                        </div>
                    </div>
                    <div className="viewAllbtnWrapper text-center" id="dealsSecHome" style={{ display: "none" }}>
                        <Link className="btn viewBtn" to="/Deals">
                            <i className="ri-restart-line"></i>
                            View all items
                        </Link>
                    </div>

                </div>

            </div>
        </section>
        <section className="giveway_wrapper bannerS">
            <div className="container">
                <div className="vertx">
                    <div className="giveway_content">
                        <h3 className="heading mb-5 text-white">Giveaway / Drops</h3>
                        <p className="para">Trade NFTs at lowest fees on OneTo11 marketplace. List, exhibit and sell NFTs created on the OneTo11 Ecosystem. Showcase individual NFTs or collections to trade with the world.
                        </p>
                        <button className="btn viewBtn">
                            Join Now <i className="ri-arrow-right-line"></i>
                        </button>
                    </div>
                    <div className="giveway_img cardX">
                        <div className="card">
                            <div className="cardImg">
                                <img src="img/lohli.png" className="card-img-top" alt="..." />
                            </div>
                            <div className="card-body">
                                <Link to="/PlaceBid" className="card-text text-decoration-none">
                                    <span>William Watson</span>
                                    <div className="history_View justify-content-between w-100">
                                        <div className="d-flex align-items-center">
                                            <div className="users me-2" style={{ backgroundImage: `url(${profileImg})` }}>
                                            </div>
                                            <p className="m-0 ">@xander_hall...</p>
                                        </div>
                                        <p className="m-0 text-green ">0,054 ETH</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="aboutbxtop" style={{ position: "relative", top: "-100px" }}></div>
        <section className="aboutWrapper exploreWrapper" id="aboutbx">
            <div className="container ">
                <div className="text-center ">
                    <h1 className="heading ">ABOUT US</h1>
                    <p className="para text-gray lh-lg ">OneTo11 is a gaming ecosystem with games for every gamer persona and implementing the Play-To-Earn model across the platform.
                    </p>
                    <p className="mt-4 para text-gray lh-lg ">We are using the power of DeFi and NFTs to facilitate consistent income generation for gamers via gameplay and time investment. Our ecosystem comprises Fantasy Sports app, Mobile gaming App, Gamified learning App, RPG/Metaverse game,
                        native crypto token of the platform and NFT marketplace.
                    </p>
                </div>
            </div>
        </section>

        <footer className="footer bannerS ">
            <div className="vertx ">
                <div className="container ">
                    <div className="innerFooter vertxGrid ">
                        <div className="logo_p foot ">
                            <img src={logo} alt=" " />
                            <p className="text-gray mt-3 ">OneTo11 is a gaming ecosystem with games for every gamer persona and implementing the Play-To-Earn model across the platform.</p>
                        </div>
                        <div className="quick_L foot ">
                            <h3>Quick Links</h3>
                            <ul className="p-0 ">
                                <li><Link to="/aboutus">About Us</Link></li>
                                <li><a href="https://www.oneto11.io/#team" target="_blank" rel="noreferrer">Team</a></li>
                                <li><a href="https://oneto11static.azureedge.net/onetoio/assets/file/oneto11_whitepaper.pdf" target="_blank" rel="noreferrer">White Paper</a></li>
                                
                                <li><Link to="/Login">Login</Link></li>
                                <li><Link to="/Register">Registration</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="contact foot ">
                            <h3>Contact Us</h3>
                            <ul className="p-0 ">
                                <li>
                                    <Link to="mailto:info@oneto11.io">info@oneto11.io</Link>
                                </li>
                                <li><Link to="/ContactUs">Contact Us</Link></li>
                            </ul>



                        </div>
                        <div className="Social foot ">
                            <h3>Social Connect</h3>
                            <ul className="p-0 ">
                                {social.Facebook && (
                                    <li>
                                        <a href={social.Facebook} target="_blank" rel="noreferrer">
                                            <img src={fb} alt="" />
                                            <span className="text-gray ">Facebook</span>
                                        </a>
                                    </li>
                                )}
                                {social.Instagram && (
                                    <li>
                                        <a href={social.Instagram} target="_blank" rel="noreferrer">
                                            <img src={insta} alt="" />
                                            <span className="text-gray ">Instagram</span>
                                        </a>
                                    </li>
                                )}
                                {social.Twitter && (
                                    <li>
                                        <a href={social.Twitter} target="_blank" rel="noreferrer">
                                            <img src={twt} alt="" />
                                            <span className="text-gray ">Twitter</span>
                                        </a>
                                    </li>
                                )}
                                {social.Telegram && (
                                    <li>
                                        <a href={social.Telegram} target="_blank" rel="noreferrer"  >
                                            <img src={telegram} alt="" />
                                            <span className="text-gray ">Telegram</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="smFooter ">
                <div className="container ">
                    <div className="innersmFooter d-flex align-items-center justify-content-between ">
                        <p className="m-0 fs-18 ">© OneTo11, All rights reserved.</p>
                        <div>
                            <a href="/terms" className="text-white text-decoration-none fs-18 ">Terms</a>
                            <span className="mx-3 ">|</span>
                            <a rel="noreferrer" target='_blank' href="https://www.oneto11.io/privacy-policy.html" className="text-white text-decoration-none fs-18 ">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <ScrollTop />
    </div>;
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;
// #endregion

export default Home;
