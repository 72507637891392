/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';
import Sale from './common/Sale';
import Auction from './common/Auction';
import Deal from './common/Deal';
import $ from "jquery";
import Header from './Header'
import Footer from './Footer'
import { ontop, contract, baseUrl } from '../Details';
import SoldCard from './common/SoldCard'
//#endregion

const propTypes = {};
const defaultProps = {};

const MyWishlist = () => {

    const [saledata, setSaledata] = useState([]);
    const [auctionData, setAuctionData] = useState([]);
    const [dealData, setDealData] = useState([]);
    const [soldData, setSoldData] = useState([]);
    const [lowToHigh, setLowToHigh] = useState([]);
    const [highToLow, setHighToLow] = useState([]);
    const [cricxData, setCricxData] = useState([]);
    const [evolutionxData, setEvolutionxData] = useState([]);

    // #region functions
    const wishListfetch = (next) => {
        let userId = sessionStorage.getItem("userid");
        var config = {
            method: 'get',
            url: `${baseUrl}/wishlistdata?userid=${userId}`,
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };
       

        axios(config)
            .then(async function (response) {

                let allData = response.data.data;
                let NftArr = []

                for (let i = 0; i < allData.length; i++) {
                    let nftid = parseInt(allData[i].nftId);
                    NftArr.push(nftid);
                }
                next(NftArr)
            })
    }

    const NftDetails = async (Arr) => {

        var data = qs.stringify({
            'id': JSON.stringify(Arr)
        });

        if (Arr.length === 0) {
            $('.ajax-loaderCtn').css("display", "none");
        }
        
        var config = {
            method: 'post',
            url: `${baseUrl}/nft`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        };

        let saleArr = [];
        let auctionArr = [];
        let dealArr = [];
        let soldArr = [];

        axios(config)
            .then(async function (response) {
                let allData = response.data.data;
                let arr = {};
                for (let i = 0; i < allData.length; i++) {

                    let nftid = parseInt(allData[i].NftId);

                    // eslint-disable-next-line no-loop-func
                    await contract.methods.checkMarket(nftid).call().then(async (nftSpace) => {
                        if (nftSpace === 'Sale') {
                            await contract.methods.getSellTokenPrice(nftid).call().then((nftdata) => {
                                arr['price'] = nftdata;
                                arr['id'] = allData[i].NftId;
                                arr['ipfs'] = allData[i].ipfs;
                                arr['type'] = nftSpace;
                                saleArr.push(arr);
                                arr = {};
                            });
                        } else if (nftSpace === 'Auction') {
                            await contract.methods.getAuction(nftid).call().then((nftdata) => {
                                var now = Math.floor((new Date()).getTime() / 1000);
                                if(parseInt( nftdata.endTime) > now){
                                    arr['price'] = nftdata.price;
                                    arr['id'] = allData[i].NftId;
                                    arr['ipfs'] = allData[i].ipfs;
                                    arr['type'] = nftSpace;
                                    arr['details'] = nftdata;
                                    auctionArr.push(arr);
                                    arr = {};
                                }
                            });
                        } else if (nftSpace === 'Deal') {
                            // dealArr.push(allData[i]);

                            await contract.methods.dealDetailsByTokenId(nftid).call().then((nftdata) => {
                                arr['price'] = nftdata.price;
                                arr['id'] = allData[i].NftId;
                                arr['ipfs'] = allData[i].ipfs;
                                arr['type'] = nftSpace;
                                arr['details'] = nftdata;
                                dealArr.push(arr);
                                arr = {};
                            });
                        }else{
                            arr['nftId'] = allData[i].NftId;
                            arr['ipfs'] = allData[i].ipfs;
                            arr['type'] = nftSpace;
                            soldArr.push(arr);
                            arr = {};
                        }
                    })
                }
                setSaledata(saleArr);
                setAuctionData(auctionArr);
                setDealData(dealArr);
                setSoldData(soldArr)
                
                $('.ajax-loaderCtn').css("display", "none");
                var merge = [];
                let cricx = [];
                let evolutionx = [];
                merge = saleArr.concat(auctionArr);
                // merge = merge.concat(dealArr);
                for (var k = 0; k < merge.length; k++) {
                    if (merge[k] === undefined)
                        merge.splice(k, 1);
                }

                for (var i = 0; i < merge.length; i++) {
                    for (var j = i; j < merge.length; j++) {
                        if (parseInt(merge[i].price) > parseInt(merge[j].price)) {
                            let temp = merge[i];
                            merge[i] = merge[j];
                            merge[j] = temp;
                        }
                    }
                }
                for (let i = 0; i < merge.length; i++) {
                let category = JSON.parse((merge[i].ipfs)).category;
                 if(category === "cricx"){
                     cricx.push(merge[i])
                 }else if(category === "evolutionx"){
                     evolutionx.push(merge[i])
                 }
             }
             setCricxData(cricx);
             setEvolutionxData(evolutionx);

                setLowToHigh(merge);
                const reversed = [...merge].reverse();
                setHighToLow(reversed)
            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loaderCtn').css("display", "none");
            });    
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
        wishListfetch(NftDetails);
    }, []);
    // #endregion
    return <div>
        <Header />
        <section className="exploreWrapper">
            <div className="cstm-container">
                <div className="innr_bck_box">
                    <Link className="btn viewBtn" to="/ItemSale">
                        <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>
                        <p className="m-0">Back to home</p>
                    </Link>
                </div>
                <div className="innerExplore exptab_bx">
                    <h2 className="heading">Wishlist</h2>
                    <ul className="nav nav-pills animate__slideInDown animate__animated" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-CricX-tab" data-bs-toggle="pill" data-bs-target="#pills-CricX" type="button" role="tab" aria-controls="pills-CricX" aria-selected="false">CricX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-EvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-EvolutionX" type="button" role="tab" aria-controls="pills-EvolutionX" aria-selected="false">EvolutionX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BuyOnly-tab" data-bs-toggle="pill" data-bs-target="#pills-BuyOnly" type="button" role="tab" aria-controls="pills-BuyOnly" aria-selected="false">Buy Only</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BidOnly-tab" data-bs-toggle="pill" data-bs-target="#pills-BidOnly" type="button" role="tab" aria-controls="pills-BidOnly" aria-selected="false">Bid Only</button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-NewListed-tab" data-bs-toggle="pill" data-bs-target="#pills-NewListed" type="button" role="tab" aria-controls="pills-NewListed" aria-selected="false">New Listed</button>
                        </li> */}
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-SOLD-tab" data-bs-toggle="pill" data-bs-target="#pills-SOLD" type="button" role="tab" aria-controls="pills-SOLD" aria-selected="false">SOLD</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-High-tab" data-bs-toggle="pill" data-bs-target="#pills-High" type="button" role="tab" aria-controls="pills-High" aria-selected="false">High to Low Price</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-Low-tab" data-bs-toggle="pill" data-bs-target="#pills-Low" type="button" role="tab" aria-controls="pills-Low" aria-selected="false">Low to High Price</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                        <div className="ajax-loaderCtn" style={{ display: "flex" }}>	
                            <div style={{ textAlign: "center" }}>	
                                <p id="loaderText" style={{ color: "#000" }}>Loading....</p>	
                                <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt="" />	
                            </div>	
                        </div>
                        <div className="playerCard cardX animate__fadeIn animate__animated">
                            <div className="innerPlayer">

                                {saledata.length > 0 ? (saledata.map((item) => (
                                    <Sale key={item.id} item={item} />
                                ))) : null}

                                {auctionData.length > 0 ? (auctionData.map((item) => (
                                    <Auction key={item.id} item={item} />
                                ))) : null}
                                {/* {dealData.length > 0 ? (dealData.map((item) => (
                                    <Deal key={item.id} item={item} />
                                ))) : null} */}

                                {saledata.length === 0 && auctionData.length === 0 && dealData.length === 0 ? <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-CricX" role="tabpanel" aria-labelledby="pills-CricX-tab">
                        <div className="playerCard cardX">
                        <div className="innerPlayer">
                                {cricxData.length > 0 ? (cricxData.map((item) => {
                                    return( item.type === 'Sale'? <Sale key={item.id} item={item} /> : <Auction key={item.id} item={item} />)
                                    
                                    })) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-EvolutionX" role="tabpanel" aria-labelledby="pills-EvolutionX-tab">
                        <div className="playerCard cardX">
                        <div className="innerPlayer">
                                {evolutionxData.length > 0 ? (evolutionxData.map((item) => {
                                    return( item.type === 'Sale'? <Sale key={item.id} item={item} /> : <Auction key={item.id} item={item} />)
                                    
                                    })) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                        </div>
                        </div>
                    </div>
                    {/* buy Only tab */}
                    <div className="tab-pane fade" id="pills-BuyOnly" role="tabpanel" aria-labelledby="pills-BuyOnly-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {saledata.length > 0 ? (saledata.map((item) => (
                                    <Sale key={item.id} item={item} />
                                ))) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>

                    {/* Bid Only tab */}
                    <div className="tab-pane fade" id="pills-BidOnly" role="tabpanel" aria-labelledby="pills-BidOnly-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {auctionData.length > 0 ? (auctionData.map((item) => (
                                    <Auction key={item.id} item={item} />
                                ))) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-NewListed" role="tabpanel" aria-labelledby="pills-NewListed-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                <div className="card">
                                    <div className="time_btn bid_btn">7d 12h 39m 44s</div>
                                    <div className="cardImg">
                                        <img src="img/cardImg1.png" className="card-img-top" alt="..." />
                                    </div>
                                    <div className="card-body">
                                        <Link to="/PlaceBid" className="card-text text-decoration-none">
                                            <span>William Watson</span>
                                            <span>Price: 2.4000 ETH/ ~$4769.88</span>
                                        </Link>
                                        <div className="history_View">
                                            <p className="text-gray">4 in stock</p>
                                            <button className="bid_btn btn" data-bs-toggle="modal" data-bs-target="#bid">
                                                Place Bid
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-SOLD" role="tabpanel" aria-labelledby="pills-SOLD-tab">
                        <div className="playerCard cardX">
                            {/* <div className="innerPlayer"> */}
                            <div className="innerPlayer">
                                {soldData.length > 0 ? (soldData.map((item) => (
                                    <SoldCard key={item.id} item={item} />
                                ))) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-High" role="tabpanel" aria-labelledby="pills-High-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {highToLow.length > 0 ? (highToLow.map((item) => {
                                    return (
                                       item.type === 'Sale' ? <Sale key={item.id} item={item} /> : item.type === 'Auction' ? <Auction key={item.id} item={item} /> : null
                                    )

                                })) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-Low" role="tabpanel" aria-labelledby="pills-Low-tab">
                        <div className="playerCard cardX">
                            <div className="innerPlayer">
                                {lowToHigh.length > 0 ? (lowToHigh.map((item) => {
                                    return (
                                        item.type === 'Deal' ? <Deal key={item.id} item={item} /> : item.type === 'Sale' ? <Sale key={item.id} item={item} /> : item.type === 'Auction' ? <Auction key={item.id} item={item} /> : null
                                    )

                                })) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <Footer />
        

    </div>;
}

MyWishlist.propTypes = propTypes;
MyWishlist.defaultProps = defaultProps;
// #endregion

export default MyWishlist;