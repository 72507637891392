/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState}from 'react';
import CountDown from '../CountDown';
import BidHistoryModal from './BidHistoryModal';
import HistoryItem from './HistoryItem'
import OfferHistoryModal from './OfferHistoryModal';
import { Oneto11,contract,tokenToUsd } from '../../Details';
import LikesCount from './LikesCount';
import swal from 'sweetalert';	
import $ from 'jquery'

export default function MyNftCard(props) {
    const {item} = props;
    
    const [showBidHistory, setBidShowHistory] = useState(false);
    const [showOfferHistory, setOfferShowHistory] = useState(false);
    const [reload, setReload] = useState(false);
    const [url, setUrl] = useState('');

    

    const cancelAuction = async(nftid, add) =>{	
        let address = sessionStorage.getItem('address');	
        if (add === address) {
            let auctionDetails =  await contract.methods.getAuction(nftid).call();
            
            if(auctionDetails.totalBids === '0'){
                await contract.methods.cancelAuction(nftid).send({ from: add }, function (err, result) {	
                    if (err) {	
                        swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });	
                    }	
                    if (result) {	
                        swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });	
                        setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);	
                    }	
                }).on('receipt', function (receipt) {	
                    if (receipt.status === true) {	
                        $('.ajax-loader').css("display", "none");	
                        setTimeout(() => {	
                            swal({ title: "Auction Cancelled", icon: "success", background: "#808080", timer: 2000 });	
                            document.getElementById("rebalaId").style.display = "none";	
                        }, 2000);
                        props.callBack(true);
                        window.location.reload(false);

                    } else {	
                        $('.ajax-loader').css("display", "none");	
                        swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });	
                    }	
                })	
            }else {	
                swal({ title: "Auction can not be cancelled beacuse of existing bids", icon: "error", background: "#808080", timer: 2000 });	
            }
            
        }	
        else {	
            swal({ title: "Your current Address Incorrect", icon: "error", background: "#808080", timer: 2000 });	
        }	
    }	
    const cancelSale = async(nftid,add) =>{	
        let address = sessionStorage.getItem('address');		
        	
        if (add === address) {	
            
            await contract.methods.cancelSell(nftid).send({ from: add }, function (err, result) {	
                if (err) {	
                    swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });	
                }	
                if (result) {	
                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });	
                    setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);	
                }	
            }).on('receipt', function (receipt) {	
                if (receipt.status === true) {	
                    $('.ajax-loader').css("display", "none");	
                    setTimeout(() => {	
                        swal({ title: "Sale Cancelled ", icon: "success", background: "#808080", timer: 2000 });
                    }, 2000);	
                    props.callBack(true);
                    window.location.reload(false);
                } else {	
                    $('.ajax-loader').css("display", "none");	
                    swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });	
                }	
            })	
        }	
        else {	
            swal({ title: "Your current is  Address Incorrect", icon: "error", background: "#808080", timer: 2000 });	
        }	
    }
    const cancelDeal = async(nftid,add) =>{
        let address = sessionStorage.getItem('address');		
        	
        if (add === address) {	
            
            await contract.methods.cancelDeal(nftid).send({ from: add }, function (err, result) {	
                if (err) {	
                    swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });	
                }	
                if (result) {	
                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });	
                    setTimeout(() => { $('.ajax-loader').css("display", "flex"); }, 2000);	
                }	
            }).on('receipt', function (receipt) {	
                if (receipt.status === true) {	
                    $('.ajax-loader').css("display", "none");	
                    setTimeout(() => {	
                        swal({ title: "Deal Cancelled ", icon: "success", background: "#808080", timer: 2000 });
                    }, 2000);	
                    props.callBack(true);
                    window.location.reload(false);
                } else {	
                    $('.ajax-loader').css("display", "none");	
                    swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });	
                }	
            })	
        }	
        else {	
            swal({ title: "Only admin can cancel the deal", icon: "error", background: "#808080", timer: 2000 });	
        }	
    }

    useEffect(()=>{
        const getUrl = async(tokenId) => {

            await contract.methods.checkMarket(tokenId).call().then((data)=>{
                if(data === 'Auction'){
                    setUrl(`/PlaceBid?tokenNo=${tokenId}`);    
                }else if(data === 'Sale'){
                    setUrl(`/Buynow?tokenNo=${tokenId}`);
                }else if(data === 'Deal'){
                    setUrl(`/Buynow?tokenNo=${tokenId}`);
                }else{
                    setUrl(`/SoldOut?tokenNo=${tokenId}`);
                }
            })       
        }
        getUrl(item.nftId);
    },[item])

  return (
    <div>
        {item.userDealdata ? <>
            <div className="card" key = {item.nftId}>
            <div className="time_btn bid_btn"><CountDown end={parseInt(item.endTime)} start={parseInt(item.startTime)} /></div> 
            <div style={{position:"relative"}} >
                <a href={url} >
                    <div className="cardImg">
                        <img src={ (JSON.parse(item.userDealdata[0].ipfs)).image} className="card-img-top" alt="..." />
                    </div>
                    <img src='img/dealicon1.png' alt = "" className='dealicon' style={{position:"absolute",width: "75px",left: "8px",top:"0px",backgroundColor: "#fff",
        borderRadius: "15px"}}/>
                    
                </a>
                <LikesCount nftId = {item.nftId}/>
            </div>
            
            <div className="card-body">
                <a href={url} className="card-text text-decoration-none">
                    <span>{(JSON.parse(item.userDealdata[0].ipfs)).name }</span>
                    <div className='d-flex justify-content-between w-100 align-items-baseline'>
                        <span className='pricePlusLike' >Price: {Oneto11} {item.amount/10**18} / <b>~${tokenToUsd(item.amount/10**18)}</b></span>  
                    </div>
                    
                </a>
                <div className="history_View">
                    <button className='bid_btn btn' onClick={() => cancelDeal(item.nftId,item.seller)}>Cancel Deal</button>
                </div>
            </div>
        </div>
        </>: item.userAuctiondata || item.userSaledata ? 
        <>
        <div className="card" key = {item.nftId}>
            {item.userAuctiondata ? <div className="time_btn bid_btn"><CountDown end={parseInt(item.endTime)} start={parseInt(item.startTime)} /></div> : null}
            <div style={{position:"relative"}} >
                <a href={url} style={{position:"relative"}}>
                    <div className="cardImg">
                        <img src={item.userAuctiondata ? (JSON.parse(item.userAuctiondata[0].ipfs)).image : item.userSaledata ? (JSON.parse(item.userSaledata[0].ipfs)).image : item.userDealdata ? ((JSON.parse(item.userDealdata[0].ipfs)).image) : null} className="card-img-top" alt="..." />
                    </div>
                </a>
                <LikesCount nftId = {item.nftId}/>
            </div>
            
            <div className="card-body">
                <a href={url} className="card-text text-decoration-none">
                    <span>{item.userAuctiondata ? (JSON.parse(item.userAuctiondata[0].ipfs)).name : item.userSaledata ? (JSON.parse(item.userSaledata[0].ipfs)).name : item.userDealdata ? (JSON.parse(item.userDealdata[0].ipfs)).name : null}</span>
                    <div className='d-flex justify-content-between w-100 align-items-baseline'>
                        <span className='pricePlusLike' >Price:{Oneto11} {item.amount/10**18} / <b>~${tokenToUsd(item.amount/10**18)}</b></span>
                        
                    </div>
                    
                </a>
                <div className="history_View">
                {item.userAuctiondata ? <div className='d-flex justify-content-between w-100'><button className="bid_btn btn" onClick={() => setBidShowHistory(true)}>	
                        Bids	
                    </button><button className='bid_btn btn' onClick={() => cancelAuction(item.nftId,item.seller)}>Cancel Auction</button></div>  : item.userSaledata ? <div className='d-flex justify-content-between w-100'><button className="bid_btn btn" onClick={() => setOfferShowHistory(true)}>	
                        Offers	
                    </button><button className='bid_btn btn ' onClick={() => cancelSale(item.nftId, item.seller)}>Cancel Sale</button></div> : null}
                </div>
            </div>
        </div>
        {showBidHistory && (
            <HistoryItem token={item.nftId} onClose={() => setBidShowHistory(false)} />
            // <BidHistoryModal token={item.nftId} key = {item.nftId}  myitems = {true} callBack={setReload}  onClose={() => setBidShowHistory(false)} />
        )
        }
        {showOfferHistory && (
            <OfferHistoryModal token={item.nftId} key = {item.nftId} callBack={setReload} onClose={() => setOfferShowHistory(false)} />
        )
        }
        </>:null
    }
        
    </div>
  )
}

