/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from 'sweetalert';
import axios from 'axios';
import { baseUrl, Oneto11,profileUrl,chainUrl} from '../../Details';
import Moment from 'react-moment';
const ItemActivity = ({ tokenId }) => {
    const [state, setState] = useState({
        auction: [],
        mint: [],
        transfer: [],
        sale: [],
        bid: [],
        offer: [],
        combinedData: [],
        loadedFirst: false,
        loading: true,
        auctionShow: false,
        mintShow : false,
        transferShow: false,
        saleShow:false,
        bidShow:false,
        offerShow:false,
        
    })
    useEffect(() => {
        if (tokenId && !state.loadedFirst) {
            setState(preState => ({
                ...preState,
                loadedFirst: true
            }))
            getFilterActivity(tokenId)
        }
    }, [tokenId])
    const onCopyText = () => {
        swal("Address Copied !!", { button: false, icon: "success", timer: 2000 });
    };
    useEffect(() => {
        if (state.loadedFirst)
            getFilterActivity(tokenId)
    }, [state.auctionShow, state.transferShow, state.mintShow, state.saleShow, state.bidShow, state.offerShow])

    const getFilterActivity = (token) => {
        let filter = ''
        if (state.auctionShow)
            filter = 'auction';
        if (state.transferShow)
            filter = filter ? `${filter},transfer` : 'transfer'
        if (state.mintShow)
            filter = filter ? `${filter},mint` : 'mint'
        if (state.saleShow)
            filter = filter ? `${filter},sale` : 'sale'
        if (state.bidShow)
        filter = filter ? `${filter},bid` : 'bid'
        if (state.offerShow)
        filter = filter ? `${filter},offer` : 'offer'
        
        
        axios.post(`${baseUrl}/getAllActivity`, {
            tokenId: token,
            filter: filter
        }).then((res) => {
            
            if (res.data.success && res.data.data) {
                console.log(res.data.data)
                setState(preState => ({
                    ...preState,
                    loading: false,
                    ...res.data.data
                }))
            }
        }).catch((err) => {
        })
    }
    useEffect(() => {
        
        if (state.auction.length || state.transfer.length || state.mint.length ||  state.sale.length || state.bid.length ||state.offer.length){
            setAllData()
        }else{
            setState(preState => ({
                ...preState,
                combinedData: []
            }))
        }
            
    }, [state.auction, state.transfer, state.mint, state.sale, state.bid, state.offer])

    const setAllData = () => {
        let auction = state.auction;
        let transfer = state.transfer;
        let mint = state.mint;
        let sale = state.sale;
        let bid = state.bid;
        let offer = state.offer;

        var combinedArr = auction.concat( transfer, mint, sale,bid, offer);
        // var combinedArr = combinedArr.concat(sale);
        
        combinedArr.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        })
        setState(preState => ({
            ...preState,
            combinedData: combinedArr
        }))
    }
    
    
    const convertPrice = (n) => {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }
    const updateFilterKey = (key, value) => {
        setState(preState => ({
            ...preState,
            [key]: value
        }))
    }
    return (
        <div className="ItemActivity">
            <div className="cstm-container">
                <div className="innerItemActivity">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i className="ri-arrow-up-down-fill me-3"></i><span>Item Activity</span>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="itemactionfilter">
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Filter
                                            </button>
                                            <ul className="dropdown-menu shadow" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                    <span className="dropdown-item"
                                                        onClick={() => updateFilterKey('auctionShow', !state.auctionShow)}
                                                        style={{ display: 'flex', cursor: 'pointer' }}>
                                                        <label className="checkBox">
                                                            <input type="checkbox" checked={state.auctionShow}  onChange={e => {}}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span>Auction</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="dropdown-item"
                                                        onClick={() => updateFilterKey('mintShow', !state.mintShow)}
                                                        style={{ display: 'flex', cursor: 'pointer' }}>
                                                        <label className="checkBox">
                                                            <input type="checkbox" checked={state.mintShow}  onChange={e => {}}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span>Mints</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="dropdown-item"
                                                        onClick={() => updateFilterKey('transferShow', !state.transferShow)}
                                                        style={{ display: 'flex', cursor: 'pointer' }}>
                                                        <label className="checkBox">
                                                            <input type="checkbox" checked={state.transferShow}  onChange={e => {}}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span>Transfers</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="dropdown-item"
                                                        onClick={() => updateFilterKey('saleShow', !state.saleShow)}
                                                        style={{ display: 'flex', cursor: 'pointer' }}>
                                                        <label className="checkBox">
                                                            <input type="checkbox" checked={state.saleShow}  onChange={e => {}}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span>Sales</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="dropdown-item"
                                                        onClick={() => updateFilterKey('bidShow', !state.bidShow)}
                                                        style={{ display: 'flex', cursor: 'pointer' }}>
                                                        <label className="checkBox">
                                                            <input type="checkbox" checked={state.bidShow}  onChange={e => {}} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span>Bids</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="dropdown-item"
                                                        onClick={() => updateFilterKey('offerShow', !state.offerShow)}
                                                        style={{ display: 'flex', cursor: 'pointer' }}>
                                                        <label className="checkBox">
                                                            <input type="checkbox" checked={state.offerShow}  onChange={e => {}}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span>Offers</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="filterData">
                                            {state.auctionShow && (
                                                <div className="fiterContent">
                                                    <span>Auction</span>
                                                    <span className="fltclosbtn"
                                                        onClick={() => updateFilterKey('auctionShow', false)}
                                                        style={{ cursor: 'pointer' }}><i className="ri-close-line"></i></span>
                                                </div>
                                            )}
                                            {state.mintShow && (
                                                <div className="fiterContent">
                                                    <span>Mints</span>
                                                    <span className="fltclosbtn"
                                                        onClick={() => updateFilterKey('mintShow', false)}
                                                        style={{ cursor: 'pointer' }}><i className="ri-close-line"></i></span>
                                                </div>
                                            )}
                                            {state.transferShow && (
                                                <div className="fiterContent">
                                                    <span>Transfers</span>
                                                    <span className="fltclosbtn"
                                                        onClick={() => updateFilterKey('transferShow', false)}
                                                        style={{ cursor: 'pointer' }}><i className="ri-close-line"></i></span>
                                                </div>
                                            )}
                                            {state.saleShow && (
                                                <div className="fiterContent">
                                                    <span>Sale</span>
                                                    <span className="fltclosbtn"
                                                        onClick={() => updateFilterKey('saleShow', false)}
                                                        style={{ cursor: 'pointer' }}><i className="ri-close-line"></i></span>
                                                </div>
                                            )}
                                            {state.bidShow && (
                                                <div className="fiterContent">
                                                    <span>Bids</span>
                                                    <span className="fltclosbtn"
                                                        onClick={() => updateFilterKey('bidShow', false)}
                                                        style={{ cursor: 'pointer' }}><i className="ri-close-line"></i></span>
                                                </div>
                                            )}
                                            {state.offerShow && (
                                                <div className="fiterContent">
                                                    <span>Offers</span>
                                                    <span className="fltclosbtn"
                                                        onClick={() => updateFilterKey('offerShow', false)}
                                                        style={{ cursor: 'pointer' }}><i className="ri-close-line"></i></span>
                                                </div>
                                            )}
                                            
                                            {(state.auctionShow || state.mintShow || state.transferShow || state.saleShow || state.bidShow ||state.offerShow ) && (
                                                <div className="fiterbtn">
                                                    <span className="clralbtn"
                                                        onClick={() => setState(preState => ({
                                                            ...preState,
                                                            transferShow: false,
                                                            mintShow: false,
                                                            auctionShow: false,
                                                            saleShow : false,
                                                            bidShow : false,
                                                            offerShow : false,
                                                           
                                                        }))}
                                                        style={{ cursor: 'pointer' }}>Clear All</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="act_tbl" style={{position:"relative",maxHeight: "390px", overflow: "auto"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Event</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">From</th>
                                                    <th scope="col">To</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            {state.combinedData.length > 0 ?
                                                <tbody>
                                                    {state.combinedData.map((item) => {
                                                        return (
                                                            <tr key={item._id}>
                                                                <td className="text-gray">
                                                                    {item.type === "Transfer" ? 
                                                                        <><i className="ri-arrow-left-right-line me-2"></i> Transfer</>: 
                                                                        item.type === "Bid" ? <> <span className="iconify" data-icon="bxs:hand"></span> Bid</> :                                                                    
                                                                        item.type === "Offer" ? <> <i className="ri-hand-coin-fill"></i> Offer</> :
                                                                        item.type === "Auction" ? <><span className="iconify" data-icon="ri:auction-fill"></span> Auction </> :
                                                                        item.type === "Sale" ? <><i className="ri-shopping-cart-fill"></i> Sale</>:
                                                                        item.type === "Mint" ? <><span className="iconify" data-icon="fa-solid:cart-arrow-down"></span> Mint</>: null
                                                                                                                                        
                                                                        }
                                                                </td>
                                                                <td>{Oneto11}{item.price ? convertPrice(item.price / 1000000000000000000) : '--'}</td>
                                                                <td className=''>
                                                                    {   
                                            
                                                                        item.userFrom ? <div><a href={profileUrl+item.userFrom} >{item.userFrom}</a></div> :item.from ? 
                                                                        <div className='d-flex justify-content-left align-items-center'>
                                                                            <a href= {chainUrl+item.from} rel="noreferrer" target="_blank">
                                                                            <div>{(item.from).substr(0, 8) + '......' + (item.from).substr((item.from).length-8, (item.from).length)}</div>
                                                                            </a>
                                                                            <div className='pl-3'>
                                                                                <CopyToClipboard text={item.from} onCopy={onCopyText}>
                                                                                <div className="copy-area">
                                                                                <button style={{ border: 0, backgroundColor:"#0000"}}><i className="ri-file-copy-line"></i></button>
                                                                                </div>
                                                                                </CopyToClipboard>  
                                                                            </div>
                                                                            
                                                                        </div> : '--'
                                                                        
                                                                    }
                                                                </td>
                                                                <td className=''>
                                                                    {
                                                                         item.userTo ? <div><a href={profileUrl+item.userTo} >{item.userTo}</a></div> : item.to ? 
                                                                        <div className='d-flex justify-content-left align-items-center'>
                                                                            <a href= {chainUrl+item.to} rel="noreferrer" target="_blank">
                                                                            <div>{(item.to).substr(0, 8) + '......' + (item.to).substr((item.to).length-8, (item.to).length)}</div>
                                                                            </a>
                                                                            <div className='pl-3'>
                                                                                <CopyToClipboard text={item.to} onCopy={onCopyText}>
                                                                                <div className="copy-area">
                                                                                <button style={{     border: 0, backgroundColor:"#0000"}}><i className="ri-file-copy-line"></i></button>
                                                                                </div>
                                                                                </CopyToClipboard>  
                                                                            </div>
                                                                            
                                                                        </div> : '--'
                                                                    }
                                                                </td>
                                                                <td><Moment date={item.date}  format={"DD/MM/YYYY, hh:mm"}/></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td style={{
                                                            position: "absolute",
                                                            left: "40%",
                                                            fontSize: "1.5rem",
                                                            fontWeight: "500",
                                                            border: "0px",
                                                            top: "46px",
                                                        }}>No Activity Found.</td>
                                                    </tr>
                                                </tbody>  
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ItemActivity