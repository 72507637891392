/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import CountDown from '../CountDown';
import Bidmodal from './Bidmodal';
import { Oneto11,tokenToUsd } from '../../Details';
import HistoryItem from "./HistoryItem";
import LikesCount from './LikesCount';
export default function Auction(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { item } = props;
    const [show, setShow] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [urlRedirect, setUrlRedirect] = useState(`/PlaceBid?tokenNo=${item.id}`);
    const now = Math.floor((new Date()).getTime() / 1000);
    const start = parseInt(item.details.startTime);
    const end = parseInt(item.details.endTime);


    return (
        <>
            {end > now ?
            <>
            <div className="card" key={item.id}>
                <div style={{position:"relative"}} >
                    <a href={urlRedirect} >
                        <div className="time_btn bid_btn"><CountDown end={item.details.endTime} start={item.details.startTime} /></div>
                        <div className="cardImg">
                            <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="" />
                        </div>
                    </a>
                    <LikesCount nftId = {item.id}/>
                </div>
            
            <div className="card-body">
                <a href={urlRedirect} className="redirect-btn card-text text-decoration-none">
                    <span>{(JSON.parse(item.ipfs)).name}</span>
                    <div className='d-flex justify-content-between w-100 align-items-baseline'>
                        <span className='pricePlusLike'>Price: {Oneto11}{(item.details.highestBid) / 10 ** 18||(item.details.price) / 10 ** 18} / <b>~${Number(item.details.highestBid) > 0 ? tokenToUsd(Number(item.details.highestBid) / 10 ** 18) : tokenToUsd(Number(item.details.price) / 10 ** 18)}</b></span>
                        
                    </div>
                </a>
                <div className="history_View">
                    <button className="btn bg-transparent ps-0" onClick={() => setShowHistory(true)}>
                        <i className="ri-history-line"></i>
                        <span>View History</span>
                    </button>
                    {start < now && end > now ? <button className="bid_btn btn" onClick={() => { setShow(true) }} >
                        Place Bid
                    </button> : null}
                </div>
            </div>
        </div>
        <div className="modelboxAll">
            <Bidmodal show={show} token={item.id} onClose={() => setShow(false)} />
        </div>
        {showHistory && (
            <HistoryItem token={item.id} onClose={() => setShowHistory(false)} />
        )

        }
            </>
        :null}
            
        </>

    )
}
