/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import bg_head from '../Images/bg_head.png'
import Header from './Header'
import $ from "jquery";
import swal from 'sweetalert';
import Footer from './Footer'
import { baseUrl, ontop,imgsrc } from '../Details';

var usernameChange = false;
var imgData;
// #endregion

const propTypes = {};
const defaultProps = {};

const UpdateProfile = () => {

    const [displayName, setDisplayName] = useState('');
    const [customUrl, setCustomUrl] = useState('');
    const [email, setEmail] = useState('Email');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [bio, setBio] = useState('Bio');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [DiscordName, setDiscordName] = useState('');
    const [emailNotification, setEmailNotification] = useState(false);
    const [bidNotification, setBidNotification] = useState(false);
    const [purchageNotification, setPurchaseNotification] = useState(false);
    const [followedNotification, setFollowedNotification] = useState(false);
    const [profileImg, setProfileImg] = useState('');
    const [imgValue, setImageValue] = useState('');

    // #region functions
    const userDetails = () => {
        $('.ajax-loader').css("display", "flex");
        let userId = sessionStorage.getItem("userid");
        var config = {
            method: 'get',
            url: `${baseUrl}/user-profile?userid=${userId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    let data = response.data.data;
                    setDisplayName(data.displayName);
                    let imgname = data.profileImage;
                    let imgurl = imgsrc+imgname.trim();
                    setImageValue(imgurl)
                    setEmail(data.email);
                    setBio(data.bio)
                    setCustomUrl(`https://nft.oneto11.io/profile/${data.displayName}`)
                    if (data.socialMedia) {
                        let Social = JSON.parse(data.socialMedia)
                        if (Social.facebook) {
                            setFacebook(Social.facebook)
                        }
                        if (Social.twitter) {
                            setTwitter(Social.twitter)
                        }
                        if (Social.discordname) {
                            setDiscordName(Social.discordname)
                        }
                    }
                    if (data.notification) {
                        let notify = JSON.parse(data.notification)
                        if (notify.email) {
                            setEmailNotification(notify.email)
                        }
                        if (notify.bids) {
                            setBidNotification(notify.bids)
                        }
                        if (notify.items) {
                            setPurchaseNotification(notify.items)
                        }
                        if (notify.followers) {
                            setFollowedNotification(notify.followers)
                        }
                    } $('.ajax-loader').css("display", "none");
                } $('.ajax-loader').css("display", "none");

            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loader').css("display", "none");
            });
    }

    const validateUsername = () => {
        let displayName = document.getElementById("displayName").value;
        let userNameVal = /^(?=.*[a-z])(?=.*\d)[A-Za-z\d_]{6,10}$/;
        let userNameTrue = userNameVal.test(displayName);
        if (userNameTrue) {
            document.getElementById('UsernameError').style.display = "none";
            document.getElementById('UsernameError').innerHTML = "";
            document.getElementById('UsernameVerified').style.display = "block";
            return true;
        } else {
            if (displayName === "") {
                document.getElementById('UsernameError').innerHTML = "** Fill this field";
            } else {
                document.getElementById('UsernameError').innerHTML = "** Name can contain six to 10 character, and should be alphanumeric.";
                document.getElementById('UsernameError').style.display = "block";
                document.getElementById('UsernameVerified').style.display = "none";
            }
            return false;
        }
    }

    const validatePassword = () => {

        let pass = document.getElementById('password').value;
        let confirmPass = document.getElementById("confirmPassword").value;
        let passVal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$^!%*?&_])[A-Za-z\d@#$^!%*?&_]{8,}$/;
        let passValTrue = passVal.test(pass);
        if (passValTrue) {
            document.getElementById('passError').style.display = "none";
            document.getElementById('passError').innerHTML = "";
            document.getElementById('passwordVerified').style.display = "block";
            if (pass === confirmPass) {
                document.getElementById('confirmPass_error').style.display = "none";
                document.getElementById('confirmPass_error').innerHTML = "";
                document.getElementById('confirmpass_verified').style.display = "block";
                return true;
            } else {
                document.getElementById('confirmPass_error').style.display = "block";
                document.getElementById('confirmpass_verified').style.display = "none";
                if (confirmPass === "") {
                    document.getElementById('confirmPass_error').innerHTML = "** Fill this field";
                } else {
                    document.getElementById('confirmPass_error').style.display = "block";
                    document.getElementById('confirmPass_error').innerHTML = "Password and cofirm password didnt match";
                }
                return false;
            }
        } else {
            if (pass === "") {
                document.getElementById('passError').innerHTML = "** Fill this field";
                document.getElementById('passError').style.display = "block";
                document.getElementById('passwordVerified').style.display = "none";
            }
            else {
                document.getElementById('passError').innerHTML = "**Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
                document.getElementById('passError').style.display = "block";
                document.getElementById('passwordVerified').style.display = "none";
            }
            return false;
        }
    }

    const updateProfile = () => {
        let userId = sessionStorage.getItem("userid");
        let displayname = displayName;
        let Password = password;
        let ConfirmPassword = confirmPassword;
        let Bio = bio;
        let Facebook = facebook;
        let Twitter = twitter;
        let discordname = DiscordName;
        let EmailNotification = emailNotification;
        let BidNotification = bidNotification;
        let PurchageNotification = purchageNotification;
        let FollowedNotification = followedNotification;
        let dp_image = imgData;
        // let dp_image = profileImg;
      
        var jsonObj = [];
        var jsonObj2 = [];
        var social = {};
        var notify = {};
        if (Facebook !== '') {
            social["facebook"] = Facebook;
        }
        if (Twitter !== '') {
            social["twitter"] = Twitter;
        }
        if (discordname !== '') {
            social["discordname"] = discordname;
        }
        jsonObj.push(social);
        if (EmailNotification !== false) {
            notify["email"] = EmailNotification;
        }
        if (BidNotification !== false) {
            notify["bids"] = BidNotification;
        }
        if (PurchageNotification !== false) {
            notify["items"] = PurchageNotification;
        }
        if (FollowedNotification !== false) {
            notify["followers"] = FollowedNotification;
        }
        jsonObj2.push(notify);

        var form = new FormData();
        form.append("userid", userId);
        form.append("displayname", displayname);
        if (usernameChange) {
            const nametest = validateUsername();
            if (nametest === false) {
                swal("Invalid username please enter a valid username", {
                    timer: 3000,
                    icon: "warning",
                    button: false,
                });
                return;
            }
        }
        if (Bio !== '') {
            form.append("bio", Bio);
        }
        if (jsonObj.length > 0) {
            let socialdata = JSON.stringify(jsonObj[0])
            form.append("socialmedia", socialdata);
        }
        if (jsonObj2.length > 0) {
            let notifydata = JSON.stringify(jsonObj2[0])
            form.append("notification", notifydata);
        }

        if (dp_image !== undefined || dp_image !== null || dp_image !== '') {
            form.append("dp_files", dp_image);
        }

        $('.ajax-loader').css("display", "flex");
        var config = {
            method: 'post',
            url: `${baseUrl}/update-profile`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            },
            data: form
        };

        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    $('.ajax-loader').css("display", "none");
                    swal(response.data.msg, {
                        timer: 2000,
                        icon: "success",
                        button: false,
                    });
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                    setTimeout(() => { window.location.reload(false);}, 2000);
                }
                else {
                    $('.ajax-loader').css("display", "none");
                    swal(response.data.msg, {
                        timer: 3000,
                        icon: "warning",
                        button: false,
                    });
                }
            })
            .catch(function (error) {
                console.error(error);
                $('.ajax-loader').css("display", "none");
            });


    }
    // #endregion

    //region useeffect
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
        userDetails();
    }, [])

    useEffect(() => {
        var readURL = function (input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $('.profile-pic').attr('src', e.target.result);
                    imgData = input.files[0];
                }

                reader.readAsDataURL(input.files[0]);
            }
        }
        $(".file-upload").on('change', function () {
            readURL(this);
        });

        $(".upload-button").on('click', function () {
            $(".file-upload").click();
        });
    }, []);

    useEffect(() => {
        setCustomUrl(`https://nft.oneto11.io/profile/${displayName.replace(/\s+/g, '')}`)
    }, [displayName]);
    // #endregion
    return <div>
        <Header />
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt="" />
            </div>
        </div>
        <section className="exploreWrapper pt-0">
            <div className="profile_head mgBt" style={{ backgroundImage: `url(${bg_head})`, marginBottom: 0 }}>

            </div>
            <div className="profile_head mobileHead" style={{ zIndex: 0, height: 0,width:'100%' }}>
                <div className="cstm-container">
                    <div className="prf_l">

                        <div className="avatar-upload upload12">
                            <div className="avatar-edit">
                                <input type="file" className="file-upload p-image" id="imageUpload" name="myfile" onChange={(event) => { setProfileImg(event.target.value); }} value={profileImg} />
                                <label htmlFor="imageUpload"></label>
                            </div>
                            <div className="avatar-preview">
                                <div id="imagePreview">
                                    <img className="profile-pic" src={imgValue} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="user_data">
                            <h3>{displayName}</h3>
                            <p>{email}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cstm-container">
                <div className="registerbox">
                    <div className="row cstmfrm">
                        <div className="col-lg-6">
                            <div className="cstmfrmbx">
                                <h3>🍉 Account info</h3>
                                <div className="form-group">
                                    <label htmlFor="" className="cstm_lbl">Display name</label>
                                    <input type="text" id="displayName" className="form-control" placeholder="Enter Display Name" onChange={(event) => { setDisplayName(event.target.value); usernameChange = true }} value={displayName} onKeyUp={validateUsername} />
                                    <p id="UsernameVerified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Username is Valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                    <span id="UsernameError" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="cstm_lbl">Custom URL</label>
                                    <input type="text" className="form-control" placeholder="https://nft.oneto11.io/Profile/user" defaultValue={customUrl} disabled={true} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="cstm_lbl d-flex justify-content-between">Email <span className="fw-normal" style={{ fontSize: "13px" }}>Your email for marketplace notifications</span>
                                    </label>
                                    <div className="f_emal_bx">
                                        <input type="email" className="form-control" id="email" placeholder="enter your mail id" value={email} disabled />
                                        <div id="verifiedIcon" style={{ position: "absolute", right: "15px", top: "15px" }}><img src="img/check2.png" alt=".." style={{ width: "28px" }} /></div>
                                        {/* <Link to="/" data-bs-toggle="modal" data-bs-target="#Verifyemail">Confirm</Link>     */}
                                    </div>
                                </div>

                                <div className="form-group mb-0">
                                    <label htmlFor="" className="cstm_lbl">Bio</label>
                                    <textarea className="form-control" onChange={(event) => { setBio(event.target.value); }} value={bio}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cstmfrmbx">
                            <h3>👨 Social media ‍</h3>
                                        <div className="form-group">
                                            <label htmlFor="" className="cstm_lbl">Facebook</label>
                                            <div className="input-box">
                                                <span clasName="prefix">https://www.facebook.com/</span>
                                                <input type="text" className="form-control onfocusOutline" placeholder="Facebook username" onChange = {(event)=>{setFacebook(event.target.value);}} value = {facebook}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="cstm_lbl">Twitter</label>
                                            <div className="input-box">
                                            <span clasName="prefix">https://twitter.com/</span>
                                                <input type="text" className="form-control onfocusOutline" placeholder="Twitter username" onChange = {(event)=>{setTwitter(event.target.value);}} value = {twitter}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="cstm_lbl">Instagram</label>
                                            <div className="input-box">
                                            <span clasName="prefix">https://www.instagram.com/</span>
                                                <input type="text" className="form-control onfocusOutline" placeholder="Instagram username" onChange = {(event)=>{setDiscordName(event.target.value);}} value = {DiscordName}/>
                                            </div>
                                        </div>
                                <div className="switch_bx">
                                    <h3>📮 Notifications</h3>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={() => { setEmailNotification(!emailNotification);  }} checked={emailNotification} />
                                        <span className="cstmslider"></span>
                                        Email Notifications
                                    </label>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={(event) => { setBidNotification(!bidNotification); }} checked={bidNotification} />
                                        <span className="cstmslider"></span>
                                        New Bids
                                    </label>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={(event) => { setPurchaseNotification(!purchageNotification); }} checked={purchageNotification} />
                                        <span className="cstmslider"></span>
                                        Item Purchased
                                    </label>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={(event) => { setFollowedNotification(!followedNotification); }} checked={followedNotification} />
                                        <span className="cstmslider"></span>
                                        People Followed
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hrbx hr"></div>
                    <div>
                        <button className="btn dtl_btn" type="button" onClick={updateProfile}>Update Profile</button>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <div className="modal commonModal oneForm fade" id="Verifyemail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close"><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Verify your email id</h3>
                            <form action=" " className="p-4">
                                <div className="form-group text-center">
                                    <label htmlFor="bidI" className="text-gray fs-6">Enter OTP</label>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "20px" }}>
                                        <input type="text" className="form-control text-center" placeholder="-" />
                                        <input type="text" className="form-control text-center" placeholder="-" />
                                        <input type="text" className="form-control text-center" placeholder="-" />
                                        <input type="text" className="form-control text-center" placeholder="-" />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link className="btn dtl_btn" to="/Login">Submit</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

UpdateProfile.propTypes = propTypes;
UpdateProfile.defaultProps = defaultProps;
// #endregion

export default UpdateProfile;