/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import $ from 'jquery'
import Header from './Header';
import Footer from './Footer';
import NftCard from './common/NftCard';
import ProfileCard from './common/ProfileCard';
import swal from 'sweetalert';
import { ontop, baseUrl, nftMint721Contract ,imgsrc,chainUrl } from '../Details';
import { CopyToClipboard } from "react-copy-to-clipboard";
import bg_head from '../Images/bg_head.png'
import SocialShare from "./common/SocialShare";
//#endregion
const propTypes = {};
const defaultProps = {};

/**
 * 
 */
const Profile = () => {
    const { displayName } = useParams();
    const history = useHistory()
    const [profileId, setProfileId] = useState('')
    const [userData, setUserData] = useState({});
    const [socialLinks, setSocialLinks] = useState({
        facebook : '',
        twitter : '',
        whatsapp: '',
        youtube: ''
    });
    const [followerNum, setFollowerNum] = useState(0);
    const [allNftDetails, setAllNftDetails] = useState([]);
    const [auctionDetails, setAuctionDetails] = useState([]);
    const [saleDetails, setSaleDetails] = useState([]);
    const [socialUrl,setSocialUrl] = useState(window.location.href);
    const [profileUserId, serProfileUserId]= useState('');
    const [followed, setFollowed] = useState(false);

    // #region functions
    const Userdetails = () => {
        var config = {
            method: 'get',
            url: `${baseUrl}/userdetails-username?displayname=${displayName}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config)
            .then(async function (response) {
                if(response.data.success === true){
                    if(sessionStorage.getItem('userid') !== null && sessionStorage.getItem('userid') !== '' && sessionStorage.getItem('userid') !== undefined){
                        countFollower( response.data.data._id);
                        checkFollower(sessionStorage.getItem('userid'),response.data.data._id)
                    }
                    let item = [];
                    let data = response.data.data;
                    setProfileId(response.data.data._id);
                    var social = response.data.data.socialMedia;
                    social = JSON.parse(social)
                    console.log(social)
                    if('facebook' in social){
                        setSocialLinks(preState => ({
                            ...preState,
                            'facebook' : social.facebook
                        }))
                    }if('twitter' in social){
                        setSocialLinks(preState => ({
                            ...preState,
                            'twitter' : social.twitter
                        }))
                    }if('discordname' in social){
                        setSocialLinks(preState => ({
                            ...preState,
                            'discord' : social.discordname
                        }))
                    }
                    
                    let follower = response.data.followers;
                    setUserData(data);
                    setFollowerNum(follower);
                    myItemDetails();
                    
                    
                }else{
                    swal("User not found", { button: false, icon: "error", timer: 2000 });
                    history.push('/')
                }
               
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const checkFollower = (userid, profileid) => {
        // return new Promise((resolve,reject) => {
                 
            var config = {
                method: 'get',
                url: `${baseUrl}/check-follower?userid=${userid}&profileid=${profileid}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            axios(config).then(async function (response) {
                if(response.data.success === true){
                    setFollowed(true)
                }else if(response.data.success === false){
                    setFollowed(false)
                }
            })
        // })
    }

    const countFollower = async(userId) =>{
        
        var config = {
            method: 'get',
            url: `${baseUrl}/count-follower?userid=${userId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config).then(async function (response) {
            if(response.data.success === true){
                setFollowerNum(response.data.data);
                 checkFollower(sessionStorage.getItem('userid'), userId);
                // if(followedtest === true){
                //     setFollowed(true)
                // }else{
                //     setFollowed(false)
                // }
            }
        })
    }

    const myItemDetails = async () => {
        $('.ajax-loaderCtn').css("display", "flex");
        let address = userData.address;
        await nftMint721Contract.methods.tokenOwner(address).call().then((nftid) => {

            var data = qs.stringify({
                'id': JSON.stringify(nftid)
            });

            var config = {
                method: 'post',
                url: `${baseUrl}/nft`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };

            axios(config)
                .then(async function (response) {
                    if(response.data.success){
                        let allData = response.data.data;
                        setAllNftDetails(allData)
                        $('.ajax-loaderCtn').css("display", "none");
                    }
                    else{
                        $('.ajax-loaderCtn').css("display", "none");
                        if( userData.address === undefined || userData.address === null || userData.address === ''){
                            swal("User doesn't have primary address", { button: false, icon: "warning", timer: 2000 });
                        }
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    $('.ajax-loaderCtn').css("display", "none");
                });
        })
    }

    const addFollower = () => {
        $('.ajax-loader').css("display", "flex");
        let userId = sessionStorage.getItem('userid');
        if (userId === null || userId === '' || userId === undefined) {
            swal("Please Login to follow", { button: false, icon: "info", timer: 2000 });
            history.push('/Login');

        } else if (profileId === null || profileId === '' || profileId === undefined) {
            swal("User doesn't have primary address you can't follow him", { button: false, icon: "success", timer: 2000 });
            return;
        } else {
            var data = qs.stringify({
                'followedby': userId,
                'followedto': profileId
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/add-follower`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config).then(function (response) {
                if (response.data.success === true) {
                    countFollower(profileId)
                    $('.ajax-loader').css("display", "none");
                    swal("User followed", { button: false, icon: "success", timer: 2000 });
                } else {
                    if(response.status === 203){
                        var data = qs.stringify({
                            'followedby': userId,
                            'followedto': profileId
                        });
                        var config = {
                            method: 'post',
                            url: `${baseUrl}/remove-follower`,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            data: data
                        };

                        axios(config)
                        .then(function (response) {
                            if(response.data.success === true){
                                countFollower(profileId)
                                $('.ajax-loader').css("display", "none");
                                swal("User unfollowed", { button: false, icon: "success", timer: 2000 });
                            }else{
                                $('.ajax-loader').css("display", "none");
                                swal("Error", { button: false, icon: "warning", timer: 2000 });
                            }
                        })
                        .catch(function (error) {
                        $('.ajax-loader').css("display", "none");
                        console.error(error);
                        });
                    }else{
                        $('.ajax-loader').css("display", "none");
                        swal("Error", { button: false, icon: "warning", timer: 2000 });
                    }
                }
            })
            .catch(function (error) {
                $('.ajax-loader').css("display", "none");
                console.error(error);
            });
                
        }

    }

    // sale data
    const userAuction = () => {
        let address =  userData.address;
        var data = qs.stringify({
            'seller': address 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserAuctionData`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
              if(response.data.success === true){
                  let obj = response.data.data
                  if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                    setAuctionDetails([])
                  }else{
                    setAuctionDetails(response.data.data)
                  }
                
              }
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    
    const userSale = () => {
        let address = userData.address;
        var data = qs.stringify({
            'seller': address 
          });
          var config = {
            method: 'post',
            url: `${baseUrl}/getUserSaleData`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            if(response.data.success === true){
               
                let obj = response.data.data;
                  if(Object.keys(obj).length === 0 && obj.constructor === Object) {
                    setSaleDetails([])
                  }else{
                    setSaleDetails(response.data.data)
                  } 
              }
          })
          .catch(function (error) {
            console.error(error);
          });
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
        Userdetails();
    }, []);

    useEffect(() => {
        myItemDetails();
    }, [userData]);

    useEffect(() => {
        userAuction();
        userSale();
    }, [userData]);
    // #endregion
    return <div>
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#000" }}>Please wait....</p>
                <img src="../img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <Header logoPath="../img/logo.png" />
        <div className='exploreWrapper pt-0'>
            <section className="profile_pg pb-5">
                <div className="profile_head" style={{ backgroundImage: `url(${bg_head})` }}>
                    <div className="cstm-container">
                        <div className="prf_l">
                            <div className="user_media"><img className="img-f" src={imgsrc+userData.profileImage} alt="" /></div>
                            <div className="user_data">
                                <h3>{userData.displayName || 'User'}</h3>
                                <p>{userData.email || 'email'}</p>
                            </div>
                        </div>
                        <div className="prf_r">
                            <div className="prf_r_btnb">
                                <div className="copyLink d-flex">
                                <a href= {chainUrl+userData.address} rel="noreferrer" target="_blank">
                                    <input
                                        className="m-0" style={{ cursor: "pointer",fontWeight: "500", border: 0, padding: "0", width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                        type="text"
                                        defaultValue={userData.address}
                                        placeholder="Type some text here"
                                        disabled = {true}
                                    />
                                    </a>
                                    <CopyToClipboard style={{marginTop:"14px"}} text={userData.address} onCopy={() => { swal("Address Copied !!", { button: false, icon: "success", timer: 2000 }); }}>
                                        <div className="copy-area">
                                            <button style={{ border: 0, backgroundColor: "#0000" }}><i className="ri-file-copy-line"></i></button>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                                
                                {sessionStorage.getItem('userid') !== profileId ? <a to="" style={{cursor:"pointer"}} onClick={addFollower}>{!followed ? 'Follow' : 'Unfollow'}</a>:null}
                                <div className="sharebtn_down dropdown">
                                        <button className="btn sharebtnd" id="social" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="ri-share-line"></i>
                                        </button>
                                        <ul className="dropdown-menu socialicon" aria-labelledby="social">
                                            <SocialShare url={socialUrl}/>
                                        </ul>
                                </div>
                                <Link to="/"><i className="ri-more-fill"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cstm-container">
                    <div className="profile_dtl">
                        <div className="dtl_abt">
                            <h3 className="mb-4">About me</h3>
                            <div className="card row">
                                <h5>Bio:</h5>
                                <p style = {{colour:"#000"}}>{userData.bio}</p>
                                <div className="cls_flws">
                                    <div className="clsf_b">
                                        <h4>Collections</h4>
                                        <h3>{allNftDetails.length || 0}</h3>
                                    </div>
                                    <div className="clsf_b">
                                        <h4>Followers</h4>
                                        <h3>{followerNum || 0}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="hrbx hr2"></div>
                            <h3>Follow me</h3>
                            <div className="card">
                                <div className="flowme_bx">
                                    {'facebook' in socialLinks ?
                                        (<a rel="noreferrer" href={"https://www.facebook.com/"+socialLinks.facebook} target="_blank">
                                        {/* (<a rel="noreferrer" href={socialLinks.facebook} target="_blank"> */}
                                            <span className="icon_ri fb"><i className="ri-facebook-fill"></i></span>
                                            <span>facebook</span>
                                            <span>{socialLinks.facebook}</span>
                                        </a>) : <p>
                                            <span className="icon_ri fb"><i className="ri-facebook-fill"></i></span>
                                            <span>facebook</span>
                                            {/* <span></span> */}
                                        </p>}

                                        {'twitter' in socialLinks ?	
                                            (<a rel="noreferrer" href={"https://twitter.com/"+socialLinks.twitter} target="_blank">	
                                             {/* (<a rel="noreferrer" href={socialLinks.twitter} target="_blank">	 */}
                                                <span className="icon_ri msg"><i className="ri-twitter-fill"></i></span>	
                                                <span>Twitter</span>	
                                                <span>{socialLinks.twitter}</span>	
                                            </a>) : <p>	
                                                <span className="icon_ri msg"><i className="ri-twitter-fill"></i></span>	
                                                <span>Twitter</span>	
                                                {/* <span></span>	 */}
                                            </p>}		
                                        {'discord' in socialLinks ?	
                                            (<a rel="noreferrer" href={"https://www.instagram.com/"+socialLinks.discord} target="_blank">	
                                            {/* (<a rel="noreferrer" href={socialLinks.discord} target="_blank">	 */}
                                                <span className="icon_ri yut"><i className="ri-instagram-line"></i></span>	
                                                <span>Instagram</span>	
                                                <span>{socialLinks.discord}</span>	
                                            </a>) : <p>	
                                                <span className="icon_ri yut"><i style={{color: "#eb17bd",backgroundColor: "#ff18183d",padding: "8px 8px",margin: "0px 8px 0px 0",borderRadius: "50%",position: "relative",
                                                top: "3px"}} className="ri-instagram-line"></i></span>	
                                                <span>Instagram</span>	
                                                {/* <span></span>	 */}
                                            </p>}
                                </div>
                            </div>
                        </div>
                        <div className="dtl_slitm">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">Collection</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-CricX-tab" data-bs-toggle="pill" data-bs-target="#pills-CricX" type="button" role="tab" aria-controls="pills-CricX" aria-selected="false">For Sale</button>
                                </li>
                            </ul>
                            <div className="ajax-loaderCtn" style={{ display: "none" }}>
                                <div style={{ textAlign: "center" }}>
                                    <p id="loaderText" style={{ color: "#000" }}>Please wait....</p>
                                    <img src="../img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
                                </div>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade active show" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                                    <div className="cardX">
                                        <div className="innerPlayer">
                                            {allNftDetails.length > 0 ? (allNftDetails.map((item) => (
                                                <NftCard key={item.id || item.nftId} item={item} />
                                            ))) : <div style={{ textAlign: "center", fontSize: "25px" }}>No result found</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-CricX" role="tabpanel" aria-labelledby="pills-CricX-tab">
                                    <div className="cardX">
                                        <div className="innerPlayer">
                                        {auctionDetails.length > 0 ?(auctionDetails.map((item) => (
                                            <ProfileCard key = {item.id || item.nftId} item = {item}/>
                                            ))) : null}
                                            {saleDetails.length > 0 ? (saleDetails.map((item) => (
                                            <ProfileCard key = {item.id || item.nftId} item = {item}/>
                                            ))) : null}
                                            {auctionDetails.length === 0 && saleDetails.length === 0 ?  <div style={{ textAlign: "center", fontSize: "25px" }}>No result found</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />

    </div>;
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;
// #endregion

export default Profile;