import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";

import Home from "./component/Home";
import Explore from "./component/Explore";
import CreateNewSale from "./component/CreateNewSale";
import Buynow from "./component/Buynow";
import ConnectWallet from "./component/ConnectWallet";
import Deals from "./component/Deals";
import ForgotPassword from "./component/ForgotPassword";
import GeneratePassword from "./component/GeneratePassword";
import ItemSale from "./component/ItemSale";
import MyItems from "./component/MyItems";
import MyWishlist from "./component/MyWishlist";
import Login from "./component/Login";
import SoldOut from "./component/SoldOut";
import Register from "./component/Register";
import PlaceBid from "./component/PlaceBid";
import Profile from "./component/Profile";
import UpdateProfile from "./component/UpdateProfile";
import ContactUs from "./component/ContactUs";
import BuyOnly from "./component/BuyOnly";
import CmsPage from "./component/CmsPage";
import AllSearch from "./component/AllSearch";
import AboutUs from "./component/AboutUs";
import Claim from "./component/Claim";
import BidOnly from "./component/BidOnly"
function App() {
	return (
		<>
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
                    <Route exact path="/Aboutus">
						<AboutUs />
					</Route>
					<Route path="/Login">
						<Login />
					</Route>
					<Route path="/Register">
						<Register />
					</Route>
					<Route path="/PlaceBid">
						<PlaceBid />
					</Route>
					<Route path="/Explore">
						<Explore />
					</Route>
					<Route path="/CreateNewSale">
						<CreateNewSale />
					</Route>
					<Route path="/Buynow">
						<Buynow />
					</Route>
					<Route path="/ConnectWallet">
						<ConnectWallet />
					</Route>
					<Route path="/Deals">
						<Deals />
					</Route>
					<Route path="/ForgotPassword">
						<ForgotPassword />
					</Route>
					<Route path="/GeneratePassword">
						<GeneratePassword />
					</Route>
					<Route path="/SoldOut">
						<SoldOut />
					</Route>
					<Route path="/ItemSale">
						<ItemSale />
					</Route>
					<Route path="/MyItems">
						<MyItems />
					</Route>
					<Route path="/MyWishlist">
						<MyWishlist />
					</Route>
					<Route exact path="/Profile/:displayName">
                        <Profile/>
					</Route>
					<Route path="/UpdateProfile">
						<UpdateProfile />
					</Route>
					<Route path="/ContactUs">
						<ContactUs />
					</Route>
					<Route path="/BuyOnly">
						<BuyOnly />
					</Route>
                    <Route path="/BidOnly">
						<BidOnly />
					</Route>
					<Route path="/allSearch">
						<AllSearch />
					</Route>
					<Route path="/Claim">
						<Claim />
					</Route>
					{/* put always in last */}
					<Route path="/:slug">
						<CmsPage />
					</Route>
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
