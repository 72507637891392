/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState} from 'react';
import DealCountDown from '../DealCountDown';
import CountDown from '../CountDown';
import Bidmodal from './Bidmodal';
import HistoryItem from "./HistoryItem";
import LikesCount from './LikesCount';
import { Oneto11,tokenToUsd } from '../../Details';

export default function NewListCard(props) {
    const {item} = props;
    
    const [showHistory, setShowHistory] = useState(false);
    const [show, setShow] = useState(false);
    
    
    if('AuctionToken' in item){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var[bidUrl, setBidUrl] = useState( `/PlaceBid?tokenNo=${item.AuctionToken}`); 
    }
    else if('SaleToken' in item){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var [buyUrl, setBuyUrl] = useState( `/Buynow?tokenNo=${item.SaleToken}`);
    }else if('DealToken' in item){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var [urlRedirect, setUrlRedirect] = useState(`/Buynow?tokenNo=${item.DealToken}`)
    }
    const now = Math.floor((new Date()).getTime() / 1000);
    if(item.AuctionToken || item.DealToken){
        var  start = Number(item.startTime);
        var end = Number(item.endTime);
    }
    
  return (
    <>
        {'SaleToken' in item ?(
           <> <div className="card">
               <div style={{position:"relative"}} >
                    <a href={buyUrl}>
                        <div className="cardImg">
                            <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..." />
                        </div>
                    </a>
                    <LikesCount nftId = {item.SaleToken}/>
               </div>
            
            <div className="card-body">
                <a href = {buyUrl} className="redirect-btn card-text text-decoration-none">
                    <span>{(JSON.parse(item.ipfs)).name}</span>
                    <div className='d-flex justify-content-between w-100 align-items-baseline'>
                        <span className='pricePlusLike' >Price: {Oneto11}{(item.amount) / 10 ** 18} / <b>~${tokenToUsd((item.amount) / 10 ** 18)}</b></span>
                        
                    </div>
                    
                </a>
                <div className="history_View justify-content-end">
                    <a href = {buyUrl} className="bid_btn btn">
                        Buy
                    </a>
                </div>
            </div>
        </div>
    </>
):(
        <>{'AuctionToken' in item && end > now ?
        <div>
        <div className="card">
        <div className="time_btn bid_btn"> <CountDown end={item.endTime} start={item.startTime} /> </div>
        <div style={{position:"relative"}} >
            <a href={buyUrl} >
                <div className="cardImg">
                    <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..." />
                </div>
                <LikesCount nftId = {item.AuctionToken}/>
            </a>
        </div>

        <div className="card-body">
             
                <a href={bidUrl} className="redirect-btn card-text text-decoration-none">

                    <span>{(JSON.parse(item.ipfs)).name}</span>
                    <div className='d-flex justify-content-between w-100 align-items-baseline'>
                        <span className='pricePlusLike' >Initial Price: {Oneto11}{((parseInt(item.amount) / 10 ** 18))} / <b>~${tokenToUsd((parseInt(item.amount) / 10 ** 18))}</b></span>
                        
                    </div>
                    
                </a>
            
            <div className="history_View">
                <button className="btn bg-transparent ps-0" data-bs-toggle="modal" data-bs-target="#history" onClick={() => setShowHistory(true)}>
                    <i className="ri-history-line"></i>
                    <span>View History</span>
                </button>
                {(now > Number(start) && now < Number(end) ? <button className="bid_btn btn" onClick={() => { setShow(true); } }>Place Bid</button> : null)}
            </div>
        </div>
        </div>
        <div className="modelboxAll">
                <Bidmodal show={show} token={item.AuctionToken} onClose={() => setShow(false)} />
        </div>
        {showHistory && (<HistoryItem token={'AuctionToken' in item ? item.AuctionToken : item.SaleToken} onClose={() => setShowHistory(false)} />)}

        </div> : 'DealToken' in item && end > now ?
        
            (<div className="dealsCrd" key={item.id}>
            <div className="dealsCard" style={{position:"relative"}}>
                <div className="dealsIMg bannerS" style={{ backgroundImage: `url(${(JSON.parse(item.ipfs)).image})` }}>
                <a  className="redirect-btn dels_ank text-decoration-none" href={urlRedirect} ></a>
                    <div className="stockPrice">
                        <div className="">
                            <h6>{(JSON.parse(item.ipfs)).name}</h6>
                        </div>
                        <div className="deal-price">
                            <div className="leftStock">
                                <p>
                                    <span>Deal Price</span>
                                    <span>{Oneto11}{(parseInt(item.amount) / 10 ** 18)} / <b>~${tokenToUsd(parseInt(item.amount) / 10 ** 18)}</b></span>
                                </p>
                            </div>
                            <div className="rightStock">
                                <div className="rightStock">
                                    <p>
                                        {/* <span style={{ fontSize: "16px", color: "#183B56", textTransform: "uppercase", fontWeight: "600" }}>Deal End</span> */}
                                        <span className="rightStock rightDeal"><DealCountDown end={parseInt(item.endTime)} start = {parseInt(item.startTime)}/></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dealLike' style={{position:"absolute", top: "20px", left: "20px"}}>
                    <LikesCount nftId = {item.id}/>
                </div>
            </div>
        </div>)
         : null}</>
    )}
    </>
  );
}
