/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import OTPInput from "react-otp-input";
import $ from "jquery";
import axios from 'axios';
import qs from 'qs';
import dummyImg from '../Images/dummy.jpg'
import swal from 'sweetalert';
import Header from './Header'
import Footer from './Footer'
import { baseUrl, ontop } from '../Details';

var otpNum;
var otpVerified = false;
var imgData;
var timerInterval;
// #endregion

// #region styled-components

// #endregion

// #region component
const defaultProps = {};
const propTypes = {};

const Register = () => {

    const history = useHistory();
    const [ProfileImg, setProfileImg] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [customUrl, setCustomUrl] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [bio, setBio] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [DiscordName, setDiscordName] = useState('');
    const [emailNotification, setEmailNotification] = useState('');
    const [bidNotification, setBidNotification] = useState('');
    const [purchageNotification, setPurchaseNotification] = useState('');
    const [followedNotification, setFollowedNotification] = useState('');
    const [check, setCheck] = useState('');
    const [OTP, setOTP] = useState("");
    const [count, setCount] = useState(0);

    // #region functions
    const validateEmail = () => {
        if (!otpVerified) {
            let emailID = document.getElementById("email").value;
            let emailIDVal = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailIDTrue = emailIDVal.test(emailID);
            if (emailIDTrue) {
                document.getElementById('email_error').style.display = "none";
                document.getElementById('email_error').innerHTML = "";
                document.getElementById("otpmodal").style = "background-color: #183B56;cursor: pointer;pointer-events: auto;"
                document.getElementById('email_verified').style.display = "block";
                return true;
            } else {
                document.getElementById("otpmodal").style = "background-color: rgb(78 103 171);cursor: pointer;pointer-events: none;"
                document.getElementById('email_error').style.display = "block";
                document.getElementById('email_verified').style.display = "none";
                if (emailID === "") {
                    document.getElementById("otpmodal").style = "background-color:rgb(78 103 171);cursor: pointer;pointer-events: none;"
                    document.getElementById('email_error').innerHTML = "** Fill this field";
                }
                else {
                    document.getElementById("otpmodal").style = "background-color: rgb(78 103 171);cursor: pointer;pointer-events: none;"
                    document.getElementById('email_error').innerHTML = "** Email is invalid.";
                }
                return false;
            }
        } else if (otpVerified) {
            return true;
        }
    }

    const validateUsername = () => {
        let displayName = document.getElementById("displayName").value;
        let userNameVal = /^(?=.*[a-z])(?=.*\d)[A-Za-z\d_]{6,10}$/;
        let userNameTrue = userNameVal.test(displayName);
        if (userNameTrue) {
            document.getElementById('Username_error').style.display = "none";
            document.getElementById('Username_error').innerHTML = "";
            document.getElementById('username_verified').style.display = "block";
            return true;
        } else {
            document.getElementById('Username_error').style.display = "block";
            document.getElementById('username_verified').style.display = "none";
            if (displayName === "") {
                document.getElementById('Username_error').innerHTML = "** Fill this field";
            } else {
                document.getElementById('Username_error').innerHTML = "** Name must contain six to ten character, and should be alpha numeric.";
            }
            return false;
        }
    }

    const validatePassword = () => {
        let pass = document.getElementById("pass").value;
        let confirmPass = document.getElementById("confirmPass").value;
        let passVal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$^!%*?&_])[A-Za-z\d@#$^!%*?&_]{8,}$/;
        let passValTrue = passVal.test(pass);
        if (passValTrue) {
            document.getElementById('pass_error').style.display = "none";
            document.getElementById('pass_error').innerHTML = "";
            document.getElementById('password_verified').style.display = "block";

            if (pass === confirmPass) {
                document.getElementById('confirmPass_error').style.display = "none";
                document.getElementById('confirmPass_error').innerHTML = "";
                document.getElementById('confirmpass_verified').style.display = "block";
                return true;
            } else {
                document.getElementById('confirmPass_error').style.display = "block";
                document.getElementById('confirmpass_verified').style.display = "none";
                if (confirmPass === "") {
                    document.getElementById('confirmPass_error').innerHTML = "** Fill this field";
                } else {
                    document.getElementById('confirmPass_error').style.display = "block";
                    document.getElementById('confirmPass_error').innerHTML = "Password and cofirm password didnt match";
                }
                return false;
            }

        } else {
            document.getElementById('pass_error').style.display = "block";
            document.getElementById('password_verified').style.display = "none";
            if (pass === "") {
                document.getElementById('pass_error').innerHTML = "** Fill this field";
            }
            else {
                document.getElementById('pass_error').innerHTML = "**Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
            }
            return false;
        }
    }

    const closebutton = () => {
        var showcls = document.getElementById("Verifyemail");
        showcls.classList.remove("show");
        showcls.classList.remove("d-block");
        showcls.style.backgroundColor = '#0000';
    }

    const showTimer = (seconds) => {

        timerInterval = setInterval(() => {
            if (Number(seconds) > 0) {
                setCount(--seconds);
            } else {
                clearInterval(timerInterval);
                document.getElementById('resend-otp-btn').removeAttribute("disabled");
            }
        }, 1000);
    }

    const verifyEmail = () => {
        let Email = email;
        if (Email !== '' && Email !== undefined && Email !== null) {
            $('.ajax-loader').css("display", "flex");
            var data = qs.stringify({
                'email': Email
            });
            var config = {
                method: 'post',
                url: `${baseUrl}/register-email-verify`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response.data.success === true) {
                        console.log(response.data);
                        otpNum = response.data.data;
                        $('.ajax-loader').css("display", "none");
                        swal("OTP has been sent to your email, Please check...", {
                            timer: 3000,
                            icon: "success",
                            button: false,
                        });
                        let timestamp = parseInt(response.data.time);
                        let currentStamp = parseInt((new Date().getTime()) / 1000);
                        let time = 120;
                        if (timestamp + 120 === currentStamp) {
                            document.getElementById('resend-otp-btn').removeAttribute("disabled");
                        } else {
                            clearInterval(timerInterval)
                            showTimer(time);
                        }
                        let element = document.getElementById('otpmodal');
                        element.setAttribute("data-bs-toggle", "modal");
                        element.setAttribute("data-bs-target", "#Verifyemail");
                        let showcls = document.getElementById("Verifyemail");
                        showcls.className += " show d-block";
                        showcls.style.backgroundColor = '#0000008a';
                        document.getElementById('resend-otp-btn').setAttribute("disabled", "true");
                    } else if (response.status === 203) {
                        $('.ajax-loader').css("display", "none");
                        // swal("Wait for two minutes to send another otp request", {	
                        //     timer: 3000,	
                        //     icon: "warning",	
                        //     button: false,	
                        //   });	
                        let timestamp = parseInt(response.data.time);
                        let currentStamp = parseInt((new Date().getTime()) / 1000);

                        if (timestamp + 120 === currentStamp) {
                            document.getElementById('resend-otp-btn').removeAttribute("disabled");
                        } else {
                            clearInterval(timerInterval)
                            let time = timestamp + 120 - currentStamp;
                            showTimer(time);
                        }
                        let element = document.getElementById('otpmodal');
                        element.setAttribute("data-bs-toggle", "modal");
                        element.setAttribute("data-bs-target", "#Verifyemail");

                        let showcls = document.getElementById("Verifyemail");
                        showcls.className += " show d-block";
                        showcls.style.backgroundColor = '#0000008a';
                        document.getElementById('resend-otp-btn').setAttribute("disabled", "true");
                    } else {
                        $('.ajax-loader').css("display", "none");
                        document.getElementById('Verifyemail').click();;
                        swal(response.data.msg, {
                            timer: 3000,
                            icon: "warning",
                            button: false,
                        });
                    }
                })
                .catch(function (error) {
                    $('.ajax-loader').css("display", "none");
                    console.error(error);
                });
        } else {
            swal("Please Enter Email", { button: false, icon: "warning", timer: 1000 });
        }

    }

    const matchOtp = () => {

        var userOtp = OTP;

        if (userOtp !== undefined && userOtp !== null && userOtp !== '') {
            userOtp = parseInt(userOtp);
            $('.ajax-loader').css("display", "block");
            if (userOtp === otpNum) {
                var config = {
                    method: 'get',
                    url: `${baseUrl}/otp-verify?email=${email}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                    },
                };

                axios(config)
                    .then(function (response) {

                        if (response.data.success === true) {
                            $('.ajax-loader').css("display", "none");
                            otpVerified = true;
                            document.getElementById("closeButton").click();
                            document.getElementById("verifiedIcon").style.display = "block";
                            document.getElementById("email").disabled = true;
                            document.getElementById("otpmodal").style.display = "none";
                            setOTP('');
                            swal("OTP verified", { button: false, icon: "success", timer: 3000 });
                        } else {
                            swal("There is some problem while processing request", { button: false, icon: "warning", timer: 1000 });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                setOTP('');
                $('.ajax-loader').css("display", "none");
                swal("Invalid OTP", { button: false, icon: "warning", timer: 3000 });
                // setTimeout(() => { verifyEmail()},4000)
            }
        } else {
            swal("Please enter valid OTP", { button: false, icon: "warning", timer: 3000 });
        }

    }

    const handleregister = () => {
        let displayname = displayName;
        let Email = email;
        let Password = password;
        let ConfirmPassword = confirmPassword;
        let Bio = bio;
        let Facebook = facebook;
        let Twitter = twitter;
        let discordname = DiscordName;
        let EmailNotification = emailNotification;
        let BidNotification = bidNotification;
        let FurchageNotification = purchageNotification;
        let FollowedNotification = followedNotification;
        let profileImg = ProfileImg;
        let Check = check;

        if (validateUsername()) {
            if (validateEmail()) {
                if (validatePassword()) {
                    if (otpVerified === true) {
                        if (Check === true) {
                            document.getElementById('check_error').style.display = "none";
                            var jsonObj = [];
                            var jsonObj2 = [];
                            var social = {};
                            var notify = {};
                            if (Facebook !== '') {
                                social["facebook"] = Facebook;
                            }
                            if (Twitter !== '') {
                                social["twitter"] = Twitter;
                            }
                            if (discordname !== '') {
                                social["discordname"] = discordname;
                            }
                            jsonObj.push(social);
                            if (Facebook !== '') {
                                notify["email"] = EmailNotification;
                            }
                            if (Twitter !== '') {
                                notify["bids"] = BidNotification;
                            }
                            if (discordname !== '') {
                                notify["items"] = FurchageNotification;
                            }
                            if (discordname !== '') {
                                notify["followers"] = FollowedNotification;
                            }

                            jsonObj2.push(notify);
                            var data = new FormData();
                            data.append('dp_files', imgData);
                            data.append('displayname', displayname);
                            data.append('email', Email);
                            data.append('password', Password);
                            data.append('re_password', ConfirmPassword);
                            data.append('bio', Bio);
                            data.append('check', Check);
                            // data.append('socialmedia', JSON.stringify(jsonObj[0]));
                            // data.append('notification', JSON.stringify(jsonObj2[0]));
                            data.append('emailverified', true);
                            if (jsonObj.length > 0) {
                                let socialdata = JSON.stringify(jsonObj[0])
                                data.append("socialmedia", socialdata);
                            }
                            if (jsonObj2.length > 0) {
                                let notifydata = JSON.stringify(jsonObj2[0])
                                data.append("notification", notifydata);
                            }
                            var config = {
                                method: 'post',
                                url: `${baseUrl}/register`,
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Access-Control-Allow-Origin': '*'
                                },
                                data: data
                            };
                            axios(config)
                                .then(function (response) {
                                    if (response.data.success === true) {
                                        swal(response.data.msg, {
                                            timer: 3000,
                                            icon: "success",
                                            button: false,
                                        });
                                        history.push('/Login');
                                        window.scroll({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth'
                                        });
                                    }
                                    else {
                                        swal(response.data.msg, {
                                            timer: 3000,
                                            icon: "warning",
                                            button: false,
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.error(error);
                                });
                        }
                        else {
                            swal("“Agree to terms and conditions”", { button: false, icon: "warning", timer: 1000 });
                            if (Check) {
                                document.getElementById('check_error').style.display = "none";
                            }
                            else {
                                document.getElementById('check_error').style.display = "block";
                                document.getElementById('check_error').innerHTML = "**Accept the terms of services by checking the boxes**";
                            }
                        }
                    }
                    else {
                        swal("Please verified Email", { button: false, icon: "warning", timer: 1000 });
                        document.getElementById("verifiedIcon").style.display = "none";
                    }
                } else {
                    swal({ title: "Please enter a valid password", icon: "error", background: " #808080", timer: 2000 });
                }
            } else {
                swal({ title: "Please enter a valid email", icon: "error", background: " #808080", timer: 2000 });
            }
        } else {
            swal({ title: "Please enter a valid username", icon: "error", background: " #808080", timer: 2000 });
        }




    }
    // #endregion

    //use effect region
    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);

    useEffect(() => {
        var readURL = function (input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $('.profile-pic').attr('src', e.target.result);
                    imgData = input.files[0];
                }

                reader.readAsDataURL(input.files[0]);
            }
        }
        $(".file-upload").on('change', function () {
            readURL(this);
        });

        $(".upload-button").on('click', function () {
            $(".file-upload").click();
        });
    }, []);

    useEffect(() => {
        setCustomUrl(`https://nft.oneto11.io/profile/${displayName.replace(/\s+/g, '')}`)
    }, [displayName]);
    //endregion
    return <div>
        <Header />
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt="" />
            </div>
        </div>
        <section className="exploreWrapper">
            <div className="cstm-container">
                <div className="innr_bck_box">
                    <Link className="btn viewBtn" to="/">
                        <i className="ri-arrow-left-line" style={{ fontSize: "22px" }}></i>
                        <p className="m-0">Back to home</p>
                    </Link>
                </div>
                <div className="registerbox">
                    <div className="profile_pic_update">
                        <div className="avatar-upload upload12">
                            <div className="avatar-edit">
                                <input type="file" accept=".png, .jpg, .jpeg" className="file-upload p-image" id="imageUpload" name="myfile" onChange={(event) => { setProfileImg(event.target.value); }} value={ProfileImg} />
                                <label htmlFor="imageUpload"></label>
                            </div>
                            <div className="avatar-preview-reg">
                                <div id="imagePreview">
                                    <img className="profile-pic" src="img/dummy.jpg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="ppu_data">
                            <h3>Profile photo</h3>
                            <p>We recommend an image of at least 400x400. Gifs work too 🙌</p>
                        </div>
                    </div>
                    <div className="row cstmfrm">
                        <div className="col-lg-6">
                            <div className="cstmfrmbx">
                                <form>
                                    <h3>🍉 Account info</h3>
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl">Display name*</label>
                                        <input type="text" className="form-control" placeholder="Enter Display Name" id="displayName" onChange={(event) => { setDisplayName(event.target.value); }} value={displayName} onKeyUp={validateUsername} />
                                        <p id="username_verified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Username is Valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                        <span id="Username_error" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl">Custom URL</label>
                                        <input type="text" className="form-control" id="customUrl" placeholder="https://oneto11-3232e.web.app/Profile/User" defaultValue={customUrl} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl d-flex justify-content-between">Email* <span className="fw-normal" style={{ fontSize: "13px" }} >Your email for marketplace notifications</span>
                                        </label>
                                        <div className="f_emal_bx">
                                            <input type="email" className="form-control" placeholder="enter your mail id" id="email" onChange={(event) => { setEmail(event.target.value); }} value={email} onKeyUp={validateEmail} />
                                            <a href="#" id="otpmodal" onClick={verifyEmail} >Confirm*</a>
                                            <div id="verifiedIcon" style={{ position: "absolute", right: "15px", top: "15px", display: "none" }}><img src="img/check2.png" alt=".." style={{ width: "28px" }} /></div>
                                        </div>
                                        <p id="email_verified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Email is valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                        <span id="email_error" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>
                                    </div>
                                    <div className="nr_pswd_bx">
                                        <div className="form-group">
                                            <label htmlFor="" className="cstm_lbl">New Password*</label>
                                            <input type="password" id="pass" autoComplete="off" className="form-control" placeholder="Enter Your Password" onChange={(event) => { setPassword(event.target.value); }} value={password} onKeyUp={validatePassword} />
                                            <p id="password_verified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Password is Valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                            <span id="pass_error" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="cstm_lbl">Re-Enter Password*</label>
                                            <input type="password" id="confirmPass" autoComplete="off" className="form-control" placeholder="Enter Your Password" onChange={(event) => { setConfirmPassword(event.target.value); }} value={confirmPassword} onKeyUp={validatePassword} />
                                            <p id="confirmpass_verified" style={{ display: "none", color: "green", padding: " 0 10px" }}>Password is Valid <img src="img/check.png" alt=".." style={{ width: "15px" }} /></p>
                                            <span id="confirmPass_error" className="text-danger bold" style={{ display: "none", border: "1px solid #deb2e7", textAlign: "center", margin: "5px 8px", backgroundColor: "#dc35451f", fontSize: "13px", borderRadius: "4px" }}></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl">Bio</label>
                                        <textarea className="form-control" onChange={(event) => { setBio(event.target.value); }} value={bio}></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cstmfrmbx">
                                <h3>👨 Social media ‍</h3>
                                <div className="form-group">
                                    <label htmlFor="" className="cstm_lbl">Facebook</label>
                                    <div className="input-box">
                                        <span className="prefix">https://www.facebook.com/</span>
                                        <input type="text" className="form-control onfocusOutline" placeholder="Facebook username" onChange={(event) => { setFacebook(event.target.value); }} value={facebook} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="cstm_lbl">Twitter</label>
                                    <div className="input-box">
                                        <span className="prefix">https://twitter.com/</span>
                                        <input type="text" className="form-control onfocusOutline" placeholder="Twitter username" onChange={(event) => { setTwitter(event.target.value); }} value={twitter} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="cstm_lbl">Instagram</label>
                                    <div className="input-box">
                                        <span className="prefix">https://www.instagram.com/</span>
                                        <input type="text" className="form-control onfocusOutline" placeholder="Instagram username" onChange={(event) => { setDiscordName(event.target.value); }} value={DiscordName} />
                                    </div>
                                </div>
                                <div className="switch_bx">
                                    <h3>📮 Notifications</h3>
                                    <label className="cstmswitch">
                                        <input type="checkbox" id="check1" onChange={() => { setEmailNotification(!emailNotification) }} checked={emailNotification} />
                                        <span className="cstmslider"></span>
                                        Email Notifications
                                    </label>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={() => { setBidNotification(!bidNotification) }} checked={bidNotification} />
                                        <span className="cstmslider"> </span>
                                        New Bids
                                    </label>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={() => { setPurchaseNotification(!purchageNotification) }} checked={purchageNotification} />
                                        <span className="cstmslider" ></span>
                                        Item Purchased
                                    </label>
                                    <label className="cstmswitch">
                                        <input type="checkbox" onChange={() => { setFollowedNotification(!followedNotification) }} checked={followedNotification} />
                                        <span className="cstmslider"></span>
                                        People Followed
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="fst-italic mb-3" style={{ fontSize: "18px" }}>
                            <input type="checkbox" style={{ marginRight: "5px" }} name="" onChange={() => { setCheck(!check) }} checked={check} />
                            Please take a few minutes to read and understand <a href="/terms" target="_blank" rel="noreferrer">Terms of Service</a> To continue, you’ll need to accept the terms of services by checking the box.
                        </p>
                        <span id="check_error" className="text-danger bold" style={{ display: "none", margin: "0px 0 25px 0", fontSize: "15px" }}></span>
                        <Link className="btn dtl_btn" onClick={handleregister} to="#">Register</Link>
                        <span id="check_error" className="text-danger bold" style={{ display: "none", margin: "0px 0 25px 0", fontSize: "15px" }}></span>
                        <div className='pt-3 pb-5'>
                            Already have an account, Please <Link to="/Login" className="cstm_link">Login!</Link>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <div className="modal commonModal oneForm fade" id="Verifyemail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton" onClick={closebutton}><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Verify your email id</h3>
                            <div style={{ textAlign: "center" }}>
                                <h5 className="heading mb-2 text-gray fs-6" style={{ textTransform: "none" }}>{email}</h5>
                                <div style={{ textAlign: "center" }}>
                                    <h5 className="heading mb-2 text-gray fs-6">Resend OTP in</h5>
                                    <h6 id="minuteId">{'0' + Math.floor((count % (60 * 60)) / (60))} : {Math.floor((count % (60))) < 10 ? '0' + Math.floor((count % (60))) : Math.floor((count % (60)))}</h6>
                                </div>
                            </div>
                            <form action=" " className="p-4">
                                <div className="form-group text-center">
                                    <label htmlFor="bidI" className="text-gray fs-6">Enter OTP</label>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="inputField4">
                                            <OTPInput isInputNum={true} className="d-block form-control text-center mx-2 border-0" value={OTP} onChange={setOTP} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <button type="button" id="match-otp-btn" className="btn dtl_btn" onClick={matchOtp}>Verify</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <button type="button" id="resend-otp-btn" className="btn dtl_btn" onClick={verifyEmail}>Resend</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

Register.propTypes = propTypes;
Register.defaultProps = defaultProps;
// #endregion

export default Register;