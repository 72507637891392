/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState} from 'react';
import HistoryItem from "./HistoryItem";
import {Oneto11,tokenToUsd } from '../../Details';
import LikesCount from './LikesCount';


export default function Sale(props) {
  //  debugger
    // console.log('sale -')
    // console.log(props)
    const {item} = props;
    const [urlRedirect, setUrlRedirect] = useState(`/Buynow?tokenNo=${item.id}`)
    //Redirect to buy page
    const [showHistory, setShowHistory] = useState(false);
  return (
    <>
        <div className="card" key={item.id}>
        <div style={{position:"relative"}} >
            <a href={urlRedirect} >
                <div className="cardImg">
                    <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..." />
                </div>
            </a>
            <LikesCount nftId = {item.id}/>
        </div>
            
            <div className="card-body">
                <a href = {urlRedirect} className="redirect-btn card-text text-decoration-none">
                    <span>{(JSON.parse(item.ipfs)).name}</span>
                    
                   <div className="d-flex mb-2 justify-content-between w-100 align-items-baseline">
                       <span className = "pricePlusLike">Price: {Oneto11}{(item.price) / 10 ** 18} / <b> ~${tokenToUsd((item.price) / 10 ** 18)}</b> </span>
                       
                    </div>
                    
                </a>
                <div className="history_View justify-content-end">
                    {/* <button className="btn bg-transparent ps-0" onClick={() => setShowHistory(true)}>
                        <i className="ri-history-line"></i>
                        <span>View History</span>
                    </button> */}
                    <a href = {urlRedirect} className="bid_btn btn">
                        Buy
                    </a>
                </div>
            </div>
        </div>
        {showHistory && (

                <HistoryItem token={item.id} onClose={() => setShowHistory(false)} />
            )

            }
    </>
  )
}
