/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState} from 'react';
import CountDown from '../CountDown';
import { Oneto11,tokenToUsd } from '../../Details';
import LikesCount from './LikesCount';
import Bidmodal from './Bidmodal';
import HistoryItem from "./HistoryItem";

export default function PriceComponent(props) {
    
    const {item}= props;
   const now = Math.floor((new Date()).getTime() / 1000);
    if(item.details){
        var  start = Number(item.details.startTime);
        var end = Number(item.details.endTime);
    }
    const [showHistory, setShowHistory] = useState(false);
    const [show, setShow] = useState(false);
    const[buyUrl, setBuyUrl] = useState( `/Buynow?tokenNo=${item.id}`);
    if('details' in item){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var[bidUrl, setBidUrl] = useState( `/PlaceBid?tokenNo=${item.id}`); 
    }
    
  return (
    <>
{item.details ? (
    <>{end > now ?
        (
            <><div className="card" key={item.id}>
            {item.details ? <div className="time_btn bid_btn"> <CountDown end={item.details.endTime} start={item.details.startTime} /> </div> : <span style={{ display: "none" }}></span>}
            <div style={{position:"relative"}} >
                <a href = {bidUrl} className="redirect-btn card-text text-decoration-none">
                    <div className="cardImg">
                        <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..." />
                    </div>
                </a>
                <LikesCount nftId = {item.id}/>
            </div>
            
            
            <div className="card-body">
            
                <a href = {bidUrl}  className="redirect-btn card-text text-decoration-none">
                <span>{(JSON.parse(item.ipfs)).name}</span>
                <div className='d-flex justify-content-between w-100 align-items-baseline'>
                    <span className='pricePlusLike' >Initial Price: {Oneto11}{item.details ? (parseInt(item.details.price)/10**18) : ((parseInt(item.price) / 10 ** 18))} / <b>~${item.details ? tokenToUsd((parseInt(item.details.price)/10**18)) : tokenToUsd((parseInt(item.price) / 10 ** 18))}</b></span>
                    
                </div>
                
                </a>
                
                <div className="history_View justify-content-end">
                    {item.details ?(now > Number(start) &&  now < Number(end)? <button className="bid_btn btn" onClick={() => { setShow(true) }} >
                            Place Bid
                        </button> : null) :(<a href = {buyUrl} className="bid_btn btn">Buy</a>)
                    }
                </div>
            </div>
            </div>
            <div className="modelboxAll">
                    <Bidmodal show={show} token={item.id} onClose={() => setShow(false)} />
            </div></>
        ):null}</>
        
    
):(
    <div className="card" key={item.id}>
           <a href = {buyUrl} style={{position:"relative"}} className="redirect-btn card-text text-decoration-none">
           <div className="cardImg" >
                <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..." />
            </div>
            <LikesCount nftId = {item.id}/>
           </a>
            
            <div className="card-body">
            <a href = {buyUrl} className="redirect-btn card-text text-decoration-none">
                <span>{(JSON.parse(item.ipfs)).name}</span>
                <div className='d-flex justify-content-between w-100 align-items-baseline'>
                    <span className='pricePlusLike' >Price: {Oneto11}{(item.price) / 10 ** 18} / <b>~${tokenToUsd((item.price) / 10 ** 18)}</b></span>
                    
                </div>
                
                
            </a>
                <div className="history_View justify-content-end">
                    <a href = {buyUrl} className="bid_btn btn">Buy</a>                
                </div>
            </div>
        </div>
)}
        
{showHistory && (<HistoryItem token={item.id} onClose={() => setShowHistory(false)} />)}

</>
  )
}
