/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import $ from "jquery";
import axios from 'axios'
import Deal from './common/Deal';
import Footer from './Footer';
import Header from './Header';
import NewListCard from './common/NewListCard';
import { ontop, baseUrl, contract, web3,chain } from '../Details';
import {DealNftSort,getRunningDeal} from "../helpers/helper"
var arr3 = false;
var batch;
// #endregion
const propTypes = {};
const defaultProps = {};

const Deals = () => {

    var address = sessionStorage.getItem("address");

    const [dealHighToLow, setDealHighToLow] = useState([]);
    const [dealLowToHigh, setDealLowToHigh] = useState([]);
    const [dealData, setDealData] = useState([]);
    const [newDeal, setNewDeal] = useState([]);
    const [cricxDealData, setCricxDealData] = useState([]);
    const [evolutionxDealData, setEvolutionxDealData] = useState([]);

    // #region functions
    
    const dealNftDetails = async() => {
        let getArray = setInterval(async() => {
            if (arr3) {
                clearInterval(getArray);
                const data = await DealNftSort(getArray, window.deal);
                setCricxDealData(data.cricx);
                setEvolutionxDealData(data.evolutionx);
                setDealLowToHigh(data.LowToHigh);
                setDealHighToLow(data.HighToLow)
            }
        }, 5000);
    }
    const NewDealList = () =>{
        $('.ajax-loader').css("display", "flex");
        var config = {
            method: 'get',
            url: `${baseUrl}/getnewdeals`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
             }
          };
          
          axios(config)
          .then(function (response) {
            var dataArr = [];
            var data = response.data.data;
            
            for(let i = 0; i < data.length; i++){
                             
                var now = Math.floor((new Date()).getTime() / 1000);
                if(parseInt(data[i].endTime) > now && parseInt(data[i].startTime) < now){
                    dataArr.push(data[i])
                }
            }
            setNewDeal(dataArr);
            $('.ajax-loader').css("display", "none");
          })
          .catch(function (error) {
            console.error(error);
            $('.ajax-loader').css("display", "none");
          });
    }
    const getDealList = async (saleLength) => {
        $('.ajax-loader').css("display", "flex");
        let batch3 = new web3.BatchRequest();
        let dealArr = {};
        let dealTokenIdArr;
        let address = sessionStorage.getItem("address");
        if (saleLength === '0') {
            arr3 = true;
            $('.ajax-loader').css("display", "none");

        }

        for (let i = 0; i < saleLength; i++) {
            batch3.add(contract.methods.dealDetails(i).call.request((address, (error, dealInfo) => {
                if (error) throw error;
                dealArr[dealInfo.tokenId] = dealInfo.dealInfo;
                dealTokenIdArr = Object.keys(dealArr);
                if (saleLength - 1 === i) {

                    var data = qs.stringify({
                        'id': JSON.stringify(dealTokenIdArr)
                    });

                    var config = {
                        method: 'post',
                        url: `${baseUrl}/nft`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Origin': '*'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            let res = response.data.data;
                            let NFTDetail = [];
                            let NFTSet = {};
                            for (var i = 0; i < res.length; i++) {
                                var now = Math.floor((new Date()).getTime() / 1000);
                                if(parseInt(dealArr[dealTokenIdArr[i]]['endTime']) > now && parseInt(dealArr[dealTokenIdArr[i]]['startTime']) < now){
                                    NFTSet['id'] = dealTokenIdArr[i];
                                    NFTSet['ipfs'] = res[i].ipfs;
                                    NFTSet['details'] = dealArr[dealTokenIdArr[i]];
                                    NFTDetail.push(NFTSet);
                                    NFTSet = {};
                                }
                                
                            }
                            
                            window.deal = NFTDetail
                            setDealData(NFTDetail);
                            arr3 = true;
                            $('.ajax-loader').css("display", "none");

                        })
                        .catch(function (error) {
                            console.error(error);
                            $('.ajax-loader').css("display", "none");
                        });
                }


            })));
        }
        batch3.execute();
    }

    const getData = async() =>{
        const deal = await getRunningDeal();
        setDealData(deal);
        window.deal = deal
        arr3 = true;
        $('.ajax-loader').css("display", "none");
    }

    // #useeffect region
    useEffect(() => {

        if(web3.givenProvider !== null && web3.givenProvider.chainId === chain){
            batch = new web3.BatchRequest();
            batch.add(contract.methods.totalDeal().call.request(('0x0000000000000000000000000000000000000000', (error, totalDeal) => { if (error) throw error; getDealList(totalDeal) })));
            batch.execute();
        }else{
            getData()
        }
        dealNftDetails();
    }, []);

    useEffect(() => {
        var body = document.body;
        body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);
    // #endregion
    return <div>
        <Header />
        <div className="ajax-loader" style={{ display: "flex" }}>
                            <div style={{ textAlign: "center" }}>
                                <p id="loaderText" style={{ color: "#fff" }}>Loading....</p>
                                <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt = ""/>
                            </div>
        </div>
        <section className="bg-white exploreWrapper" id="deal_bx">
            <div className="cstm-container">
                <div className="innerExplore dls_bx-m">
                    <h2 className="heading">Deals</h2>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-All-tab" data-bs-toggle="pill" data-bs-target="#pills-BAll" type="button" role="tab" aria-controls="pills-BAll" aria-selected="true">All</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BCricX-tab" data-bs-toggle="pill" data-bs-target="#pills-BCricX" type="button" role="tab" aria-controls="pills-BCricX" aria-selected="false">CricX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BEvolutionX-tab" data-bs-toggle="pill" data-bs-target="#pills-BEvolutionX" type="button" role="tab" aria-controls="pills-BEvolutionX" aria-selected="false">EvolutionX</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BNewListed-tab" onClick={NewDealList} data-bs-toggle="pill" data-bs-target="#pills-BNewListed" type="button" role="tab" aria-controls="pills-BNewListed" aria-selected="false">New Listed</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BHigh-tab" data-bs-toggle="pill" data-bs-target="#pills-BHigh" type="button" role="tab" aria-controls="pills-BHigh" aria-selected="false">High to Low Price</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-BLow-tab" data-bs-toggle="pill" data-bs-target="#pills-BLow" type="button" role="tab" aria-controls="pills-BLow" aria-selected="false">Low to High Price</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-BAll" role="tabpanel" aria-labelledby="pills-All-tab">
                        <div className="dealsCardWrapper z-index-0">
                            {/* Deal data */}
                            {dealData.length > 0 ? dealData.map((item) => (

                                <Deal key = {item.id} item={item} />
                            )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}
                        </div>

                    </div>
                    <div className="tab-pane fade" id="pills-BCricX" role="tabpanel" aria-labelledby="pills-BCricX-tab">
                        
                        <div className="dealsCardWrapper z-index-0">
                            {cricxDealData.length > 0 ? cricxDealData.map((item) => (
                                        <NewListCard key={item.DealToken} item={item} />
                                    )) :<div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    No result found
                                </div>}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-BEvolutionX" role="tabpanel" aria-labelledby="pills-BEvolutionX-tab">
                       
                        <div className="dealsCardWrapper z-index-0">
                            {evolutionxDealData.length > 0 ? evolutionxDealData.map((item) => (
                                        <NewListCard key={item.DealToken} item={item} />
                                    )) :<div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    No result found
                                </div>}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-BNewListed" role="tabpanel" aria-labelledby="pills-BNewListed-tab">
                        
                        <div className="dealsCardWrapper z-index-0">
                        {newDeal.length > 0 ? newDeal.map((item) => (
                                    <NewListCard key={item.DealToken} item={item} />
                                )) :<div style={{ fontSize: "25px", minHeight: "0px", height: "100%", width: "100%", position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                No result found
                            </div>}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-BHigh" role="tabpanel" aria-labelledby="pills-BHigh-tab">
                        High to Low Price
                        <div className="dealsCardWrapper z-index-0">
                            {/* Deal data */}
                            {dealHighToLow.length > 0 ? dealHighToLow.map((item) => (
                                <Deal key = {item.id} item={item} />
                            )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-BLow" role="tabpanel" aria-labelledby="pills-BLow-tab">
                        Low to High Price
                        <div className="dealsCardWrapper z-index-0">
                            {/* Deal data */}
                            {dealLowToHigh.length > 0 ? dealLowToHigh.map((item) => (

                                <Deal key = {item.id} item={item} />
                            )) : <div style={{fontSize: "25px",minHeight:"0px",height: "100%",width:"100%",position: "absolute",alignItems:"center",justifyContent:"center",display:"flex"}}>No result found</div>}

                        </div>
                    </div>
                    {/* <div className="viewAllbtnWrapper text-center">
                        <Link className="btn viewBtn" to="/Deals">
                            <i className="ri-restart-line"></i>
                            View all items
                        </Link>
                    </div> */}

                </div>

            </div>
        </section>
        <Footer />
    </div>;
}

Deals.propTypes = propTypes;
Deals.defaultProps = defaultProps;
// #endregion

export default Deals;