/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// #region constants
import React ,{useEffect, useState } from 'react';
import { Link,useLocation,useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import $ from 'jquery';
import swal from 'sweetalert';
import Header from './Header'
import Footer from './Footer'
import { ontop, nftMint721Contract, baseUrl, web3,contract, ContractAddress } from '../Details';
// #endregion

const propTypes = {};
const defaultProps = {};

const ItemSale = () => {
    
    const location = useLocation();
    const history = useHistory();
    const [allNftDetails,setAllNftDetails] = useState([]);
    const [saleAmt, setSaleAmt] = useState(0);
    const [saleToken, setSaleToken] = useState(0);
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    
    // #region functions
    const approveNFt = async (nftid) => {
        let address = sessionStorage.getItem('address');
        let userId = sessionStorage.getItem('userid');
        
        if(userId !== null || userId !== undefined || userId !== ''){
            if(address !== null || address !== undefined || address !== ''){
                let checkApprove =  await nftMint721Contract.methods.getApproved(nftid).call()
                if(checkApprove === ContractAddress){
                    swal("Already Approved", { buttons: false, icon: "info", timer: 1000 });
                }else{
                    await nftMint721Contract.methods.approve(ContractAddress, nftid).send({from: address},(err, result)=>{
                        if (err) {
                            swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                        }
                        if (result) {
                            swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                            setTimeout(()=>{$('.ajax-loader').css("display", "flex");}, 2000);
                        }
                    }).on('receipt', function (receipt) {
                        if (receipt.status === true) {
                            $('.ajax-loader').css("display", "none");
                            $('.approveBtn').css("display", "none");
                            swal({ title: "Your NFT is approved", icon: "success", background: "#808080", timer: 2000 });
                            
                            // document.getElementById("rebalaId").style.display = "none";
                        } else {
                            $('.ajax-loader').css("display", "none");
                            swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                        }
                    })
                }
            }else{
                $('.ajax-loader').css("display", "none");
                swal("Please connect to the wallet", { buttons: false, icon: "error", timer: 1000 });
            }
        }else{
            $('.ajax-loader').css("display", "none");
            swal("Please Login First", { buttons: false, icon: "error", timer: 1000 });
        }
    }
    // Check approve token
    const checkappFn = async(nftid) =>{
        let checkApprove =  await nftMint721Contract.methods.getApproved(nftid).call()
            if(checkApprove === ContractAddress){
                $('.approveBtn').css("display", "none");
            }
            else{
                swal("Please Approve Your NFT first", { buttons: false, icon: "info", timer: 2000 });
                $('.approveBtn').css("display", "block");
            }
    }
    const createSell = async(nftid) =>{
        
        let address = sessionStorage.getItem('address');
        let userId = sessionStorage.getItem('userid');
        let amount = saleAmt;
        if(userId !== null || userId !== undefined || userId !== ''){
            if(address !== null || address !== undefined || address !== ''){
                let checkApprove =  await nftMint721Contract.methods.getApproved(nftid).call();
                if(checkApprove === ContractAddress){
                    if (amount !== "" && amount > 0) {
                        await contract.methods.sell(nftid, (await web3.utils.toWei(amount, "ether"))).send({from: address},(err, result)=>{
                            if (err) {
                                swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                            }
                            if (result) {
                                swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                setTimeout(()=>{$('.ajax-loader').css("display", "flex");}, 2000);
                            }
                        }).on('receipt', function (receipt) {
                            if (receipt.status === true) {
                                $('.ajax-loader').css("display", "none");
                                document.getElementById(`card${nftid}`).style = "cursor: pointer;pointer-events: none;";
                                document.getElementById(`checkiconsale${nftid}`).style.display = "block";
                                document.getElementById("closeButton2").click();
                                swal("NFT has been put on sale successfully", { button: false, icon: "success", timer: 2000 });
                            } else {
                                $('.ajax-loader').css("display", "none");
                                swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                            }
                        })
                    }
                    else{
                        swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
                    }
                }else{
                    $('.ajax-loader').css("display", "none");
                   swal("Please Approve Your NFT first", { buttons: false, icon: "info", timer: 1000 });
                }
            }else{
                $('.ajax-loader').css("display", "none");
                swal("Please connect to the wallet", { buttons: false, icon: "error", timer: 1000 });
            }
        }else{
            $('.ajax-loader').css("display", "none");
            swal("Please Login First", { buttons: false, icon: "error", timer: 1000 });
        }
    }
    const createAuction = async(nftid) =>{
        let address = sessionStorage.getItem('address');
        let userId = sessionStorage.getItem('userid');
        let amount = saleAmt;
        let startStamp = (Date.parse(startTime))/1000 ;
        let endStamp = (Date.parse(endTime))/1000 ;
        if(parseInt(endStamp) > parseInt(startStamp)){
            if(userId !== null || userId !== undefined || userId !== ''){
                if(address !== null || address !== undefined || address !== ''){
                    let checkApprove =  await nftMint721Contract.methods.getApproved(nftid).call();
                    if(checkApprove === ContractAddress){
                        if (amount !== "" && amount > 0) {
                            await contract.methods.createAuction(nftid, (await web3.utils.toWei(amount, "ether")), startStamp, endStamp).send({from: address},(err, result)=>{
                                if (err) {
                                    swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                                }
                                if (result) {
                                    swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                                    setTimeout(()=>{$('.ajax-loader').css("display", "flex");}, 2000);
                                }
                            }).on('receipt', function (receipt) {
                                if (receipt.status === true) {
                                    $('.ajax-loader').css("display", "none");
                                    document.getElementById(`card${nftid}`).style = "cursor: pointer;pointer-events: none;";
                                    document.getElementById(`checkiconaution${nftid}`).style.display = "block";
                                    document.getElementById("closeButton").click();
                                    swal("Auction is created successfully", { button: false, icon: "success", timer: 2000 });
                                    
                                } else {
                                    $('.ajax-loader').css("display", "none");
                                    swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                                }
                            })
                        }
                        else{
                            swal({ title: "Please Enter Valid Amount", icon: "error", background: "#808080", timer: 2000 });
                        }
                    }else{
                    swal("Please Approve Your NFT first", { buttons: false, icon: "info", timer: 1000 });
                    }
                }else{
                    swal("Please connect to the wallet", { buttons: false, icon: "error", timer: 1000 });
                }
            }else{
                swal("Please Login First", { buttons: false, icon: "error", timer: 1000 });
            }
        }else{
            swal("End time should be greate than start time", { buttons: false, icon: "error", timer: 1000 });
        }
    }
    const createDeal = async(nftid) =>{
        let address = sessionStorage.getItem('address');
        let userId = sessionStorage.getItem('userid');
        let amount = saleAmt;
        let startStamp = (Date.parse(startTime))/1000 ;
        let endStamp = (Date.parse(endTime))/1000 ;
        if(userId !== null || userId !== undefined || userId !== ''){
            if(address !== null || address !== undefined || address !== ''){
                let checkApprove =  await nftMint721Contract.methods.getApproved(nftid).call();
                if(checkApprove === ContractAddress){

                    await contract.methods.createDeal(nftid, (await web3.utils.toWei(amount, "ether")), startStamp, endStamp).send({from: address},(err, result)=>{
                        if (err) {
                            swal({ title: "Transaction Error", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
                        }
                        if (result) {
                            swal({ title: "Your transaction is sent, Please wait for confirmation.", icon: "success", background: "#808080", timer: 2000 });
                            setTimeout(()=>{$('.ajax-loader').css("display", "flex");}, 2000);
                        }
                    }).on('receipt', function (receipt) {
                        if (receipt.status === true) {
                            $('.ajax-loader').css("display", "none");
                            document.getElementById(`card${nftid}`).style = "cursor: pointer;pointer-events: none;";
                            document.getElementById(`checkicondeal${nftid}`).style.display = "block";
                            document.getElementById("closeButton3").click();
                            swal("Deal is created successfully", { button: false, icon: "success", timer: 2000 });
                            
                        } else {
                            $('.ajax-loader').css("display", "none");
                            swal("Transaction Error", { buttons: false, icon: "error", timer: 1000 });
                        }
                    })
                    
                }else{
                   swal("Please Approve Your NFT first", { buttons: false, icon: "info", timer: 1000 });
                }
            }else{
                swal("Please connect to the wallet", { buttons: false, icon: "error", timer: 1000 });
            }
        }else{
            swal("Please Login First", { buttons: false, icon: "error", timer: 1000 });
        }
    }
    // #endregion

     // #useeffect region
     useEffect(()=>{
        if(location.state){
            let data = location.state.data;
            
            window.arr =[]
            for(let i = 0; i < data.length; i++){
                let num = (data[i].value).toString()
                window.arr.push(num);
            }
        }
    },[location.state]);

    useEffect(() => {
        var body = document.body;
		body.classList.remove("connectWalletWrapper");
        ontop();  
    }, []);

    useEffect(()=>{
        
        const fetchdata = () => {
            let selectedId = window.arr;

            var data = qs.stringify({
                'id': JSON.stringify(selectedId)
            });

            var config = {
                method: 'post',
                url: `${baseUrl}/nft`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                },
                data: data
            };
    
            axios(config).then(function (response) {
                if(response.data.success === true){
                    let allData = response.data.data;
                    setAllNftDetails(allData)
                }else{
                   history.push('/')
                }
            }).catch(function (error) {
                console.error(error);
            });     
        }
        fetchdata();
        
    },[]);
    // #endregion
    

    return <div>
        <div className="ajax-loader" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
            <Header/>
                <section className="exploreWrapper">
                    <div className="cstm-container">
                        <div className="innr_bck_box">
                            <div className="ibb_l">
                                <Link className="btn viewBtn" to="/MyItems">
                                <i className="ri-arrow-left-line" style={{fontSize: "22px"}}></i>Back to home
                                </Link>
                            </div>
                        </div>
                        <div className="innerExplore exptab_bx">
                            <h2 className="heading">Create New Sale</h2>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                            <div className="ajax-loaderCtn" style={{ display: "none" }}>
                                <div style={{ textAlign: "center" }}>
                                    <p id="loaderText" style={{ color: "#000" }}>Loading....</p>
                                    <img src="img/load.gif" className="img-responsive" style={{ width: "50px" }} alt="" />
                                </div>
                            </div>
                                <div className="playerCard cardX explore_b">
                                    <div className="innerPlayer">
                                        {/* all data */}
                                        {allNftDetails.length > 0 ?(allNftDetails.map((item) => (
                                        // <MarketCard key = {item.id} item = {item}/>
                                        <div className="card" key = {item.NftId} id={"card"+item.NftId}>
                                        <div className="cardImg">
                                        <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                                        </div>
                                        <div className="card-body">
                                            <a href="" className="card-text text-decoration-none">
                                            <span>{(JSON.parse(item.ipfs)).name}</span>
                                            </a>
                                            <div className="align-items-center d-flex fs-16 justify-content-between py-2 text-gray">
                                            </div>
                                            <div className="history_View forsaleonle">
                                                <button className="bid_btn btn" data-bs-toggle="modal" data-bs-target="#crtnsalebd" onClick = {()=>{setSaleToken(item.NftId);checkappFn(item.NftId);}}>
                                                    Bid
                                                    <span className="grncheckicon" id={"checkiconaution"+item.NftId}  style={{display:"none"}}><img src="img/green_check_icon.png" alt=""/></span>
                                                </button>
                                                
                                                <button className="bid_btn btn" data-bs-toggle="modal" data-bs-target="#crtnwsle" onClick = {()=>{setSaleToken(item.NftId);checkappFn(item.NftId);}}>
                                                    Sale
                                                    <span className="grncheckicon" id={"checkiconsale"+item.NftId} style={{display:"none"}}><img src="img/green_check_icon.png" alt=""/></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>  
                                    ))) : <div style={{ textAlign: "center", fontSize: "25px" }}>No result found</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        
        {/* Bid Model */}
        <div className="modal fade commonModal oneForm" id="crtnsalebd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton"><i
                            className="ri-close-line"></i></button>
                        <div className='approveBtn' style={{display:"none"}}>
                            <div className="hr hrbx"></div>
                            <p className='text-center'>Please Approve Your NFT first to proceed further</p>
                            <div className="text-center btnM">
                            <button className="btn bid_btn d-inline-block my-3 w-50" type='button' onClick = {() => approveNFt(saleToken)}>Approve</button>
                            </div>
                        </div>
                        <div className="hr hrbx"></div>
                        <div className="m-history-wrapper" id="itemSale">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Create New Sale For Bid</h3>
                            <form action=" " className="">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">Bid Amount</label>
                                    <input type="number" min="0" onKeyPress={(event) => {  if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} className="form-control" value={saleAmt} onChange = {(event)=>{setSaleAmt(event.target.value)}}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="text-gray fs-6">Select Date / Time</label>
                                    <div className="grd_gap_1fr-2 d-block">
                                        <div className="lebal-Css">
                                            <label htmlFor="" className="text-gray fs-6">Start Time</label>
                                            <input type="datetime-local" className="form-control" value={startTime} onChange={(event)=>{setStartTime(event.target.value)}}/>
                                        </div>
                                        <div className="lebal-Css">
                                            <label htmlFor="" className="text-gray fs-6">End Time</label>
                                            <input type="datetime-local" className="form-control" value={endTime} onChange={(event)=>{setEndTime(event.target.value)}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="hr hrbx"></div>
                            <div className="btnM text-center">
                            <button className="btn bid_btn d-inline-block w-50" type = "button" onClick = {()=>{createAuction(saleToken)}}>Submit</button>                          
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>

        {/* Deal Model */}
        <div className="modal fade commonModal oneForm" id="crtndealbd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id="closeButton3"><i
                            className="ri-close-line"></i></button>
                        <div className='approveBtn'>
                            <div className="hr hrbx"></div>
                            <p className='text-center'>Please Approve Your NFT first to proceed further</p>
                            <div className="text-center btnM">
                            <button className="btn bid_btn d-inline-block my-3 w-50" type='button' onClick = {() => approveNFt(saleToken)}>Approve</button>
                            </div>
                        </div>
                        <div className="hr hrbx"></div>
                        <div className="m-history-wrapper" id="itemSale">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Create New Sale For Deal</h3>
                            <form action=" " className="">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">Bid Amount</label>
                                    <input type="number" min="0" onKeyPress={(event) => {  if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} className="form-control" value={saleAmt} onChange = {(event)=>{setSaleAmt(event.target.value)}}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="text-gray fs-6">Select Date / Time</label>
                                    <div className="grd_gap_1fr-2 d-block">
                                        <div className="lebal-Css">
                                            <label htmlFor="" className="text-gray fs-6">Start Time</label>
                                            <input type="datetime-local" className="form-control" value={startTime} onChange={(event)=>{setStartTime(event.target.value)}}/>
                                        </div>
                                        <div className="lebal-Css">
                                            <label htmlFor="" className="text-gray fs-6">End Time</label>
                                            <input type="datetime-local" className="form-control" value={endTime} onChange={(event)=>{setEndTime(event.target.value)}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="hr hrbx"></div>
                            <div className="btnM text-center">
                            <button className="btn bid_btn d-inline-block w-50" type = "button" onClick = {()=>{createDeal(saleToken)}}>Submit</button>                          
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        
        {/* Sale Model */}

        <div className="modal fade commonModal oneForm" id="crtnwsle" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" id = "closeButton2" aria-label="Close"><i
                            className="ri-close-line"></i></button>
                        <div className='approveBtn' style={{display:"none"}}>
                            <div className="hr hrbx"></div>
                            <p className='text-center'>Please Approve Your NFT first to proceed further</p>
                            <div className="text-center btnM">
                                <button className="btn bid_btn d-inline-block my-3 w-50" type='button' onClick = {() => approveNFt(saleToken)}>Approve</button>
                            </div>
                        </div>
                        <div className="hr hrbx"></div>
                        <div className="m-history-wrapper">
                            <h3 className="heading fs-4 mb-5 text-capitalize">Create New Sale</h3>
                            <form action=" " className="">
                                <div className="form-group">
                                    <label htmlFor="bidI" className="text-gray fs-6">Sale Amount</label>
                                    <input type="number" min="0" onKeyPress={(event) => {  if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} className="form-control" value={saleAmt} onChange = {(event)=>{setSaleAmt(event.target.value)}}/>
                                </div>
                            </form>
                            <div className="hr hrbx"></div>
                            <div className="btnM text-center">
                                <button className="btn bid_btn d-inline-block w-50" type = "button" onClick = {()=>{createSell(saleToken)}}>Submit</button>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

ItemSale.propTypes = propTypes;
ItemSale.defaultProps = defaultProps;
// #endregion

export default ItemSale;