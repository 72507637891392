/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React ,{useEffect,useState} from 'react';
import { Link,useHistory } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import { ontop } from '../Details';
import Web3 from 'web3';
import { tokenContract,WalletSave,rpcUrl } from '../Details';
import swal from 'sweetalert';
// #endregion

const propTypes = {};
const defaultProps = {};

const ConnectWallet = () => {
    const history = useHistory();
    const [userBalance, SetUserBalance] = useState(0);
	const [userAddress, SetUserAddress] = useState('');

     // #region functions
    const refresh = async () => {
        let userId = sessionStorage.getItem("userid");
        if (userId !== "" && userId !== null && userId !== undefined) {
                    let addressLogin = sessionStorage.getItem("address");
                    if (addressLogin !== null) {
                        var balance = await tokenContract.methods.balanceOf(addressLogin).call();
                        SetUserBalance(balance);
                        SetUserAddress(addressLogin);
                        document.getElementById("beforeWalletConnect").style.display = "none";
                        document.getElementById("afterWalletConnect").style.display = "block";
                        let metamaskAdd = await web3.eth.getAccounts();
                        if (metamaskAdd !== addressLogin) {
                            web3.eth.getAccounts()
                                .then((account) => {
                                    sessionStorage.setItem("address", account[0]);
                                })
                        }
                    }
        }
    }
    const chekRefresh = () => {
        setInterval(refresh, 1000);
    }
    const node = "private";
    const web3 = new Web3(Web3.givenProvider || rpcUrl);
    const chainId = 137;
    
    const metamaskconn = async()=>{

        if(web3.givenProvider !== null) {
            web3.givenProvider.enable().then(async function(address) {
                if(parseInt(await web3.currentProvider.networkVersion) === chainId){
                    if(await web3.eth.net.getNetworkType().then() === node) {
                        web3.eth.getAccounts()
                                .then(async (account) => {
                                    let addressLogin = sessionStorage.setItem("address", account[0]);
                                    let userId = sessionStorage.getItem("userid");
                                    await WalletSave(userId,addressLogin)
                                })
                        let address = sessionStorage.getItem("address");
                                if(address !== null){
                                    let element = document.getElementById('otpmodal');
                                    element.setAttribute("data-bs-toggle","modal");
                                    element.setAttribute("data-bs-target","#waletM");
                                    var showcls = document.getElementById("waletM");
                                    showcls.className += " show d-block";
                                    showcls.style.backgroundColor = '#0000008a';
                                    setTimeout(() => { history.push('/') }, 2000);
                        }
                        document.getElementById("beforeWalletConnect").style.display = "none";
                        document.getElementById("afterWalletConnect").style.display = "block";
                    } else {
                           swal("Please select Polygon Mainnet", {
                               button: false,
                               icon: "warning"
                           })
                    }
               
                }else {
                    swal("Please select Polygon Mainnet", {
                        button: false,
                        icon: "warning"
                    })
                }
            }).catch(function(err) {
                swal(err.message, {
                    button: false,
                    icon: "warning"
                })
            });
        } else {
            swal("Please install Metamask wallet", {
                button: false,
                icon: "warning"
            });
        }
    } 
    const closebutton = () => {
        var showcls = document.getElementById("waletM");
        showcls.classList.remove("show");
        showcls.classList.remove("d-block");
        showcls.style.backgroundColor = '#0000';
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
        body.classList.add("connectWalletWrapper");
        ontop();
    }, []);
    useEffect(() => {
        chekRefresh();
        setInterval(refresh, 1000);
    }, []);
    // #endregion
    

    return  <div>
                <Header/>
                <section className="exploreWrapper">
                    <div className="cstm-container">
                        <div className="innr_bck_box">
                            <Link className="btn viewBtn" to="/">
                                <i className="ri-arrow-left-line" style={{fontSize: "22px"}}></i>
                                <p className="m-0">Back to home</p>
                            </Link>
                        </div>
                        <div className="logos">
                            <div className="Logoheading">
                                <h3 className="text-white fs-2 text-uppercase fw-bold">Connect your wallet</h3>
                                <p className="text-white fs-6">Connect with one of available wallet providers or create a new wallet.
                                </p>
                            </div>
                            <div className="logosWrapper">
                                <div className="logoBox shadow-sm" id="otpmodal"  onClick={metamaskconn}>
                                    <div className="logosImg"><img src="img/MetaMask.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">MetaMask</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletE">
                                    <div className="logosImg"><img src="img/Coinbase.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Coinbase Wallet</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/WalletConnect.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Wallet Connect</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Fortmatic.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Fortmatic</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Kaikas.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Kaikas</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Bitski.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Bitski</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Venly.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Venly</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Dapper.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Dapper</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Authereum.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Authereum</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Torus.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Torus</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Portis.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Portis</div>
                                </div>
                                <div className="logoBox shadow-sm" data-bs-toggle="modal" data-bs-target="#waletM">
                                    <div className="logosImg"><img src="img/Trust.png" alt=""/></div>
                                    <div className="logotxt fs-4 headingC fw-bold">Trust</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
        <div className="modal fade commonModal" id="waletM" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close" id ="closeButton" onClick={closebutton}><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper text-center">
                            <img src="img/check.png" alt="" className="mb-4"/>
                            <h3 className="heading fs-3 text-center mb-3 text-capitalize">Wallet Connected!</h3>
                            <p className="text-center text-gray fs-5">You have successfully connected your wallet, now you can start bidding or buy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade commonModal" id="waletE" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close"><i
                            className="ri-close-line"></i></button>
                        <div className="m-history-wrapper text-center">
                            <h3 className="heading fs-3 text-center mb-3 text-danger text-capitalize">Error!</h3>
                            <p className="text-center text-gray fs-5">Wallet connect Request rejected.. Please Contact support
                            </p>
                            <div className="btnM">
                                <Link to="/" className="btn bid_btn bg-primary">Try Again</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

ConnectWallet.propTypes = propTypes;
ConnectWallet.defaultProps = defaultProps;
// #endregion

export default ConnectWallet;