import axios from "axios"
import { baseUrl } from "../Details";
import swal from "sweetalert";
export const allNFTSort = (interval, auction, sale) => {
    return new Promise((resolve, reject) => {
        clearInterval(interval);
        let merge = [];
        let cricx = [];
        let evolutionx = [];
        if (auction === undefined) {
            merge = sale;
        } else if (sale === undefined) {
            merge = auction;
        } else if (auction !== undefined && sale !== undefined) {
            merge = auction.concat(sale);
        }
        if (merge !== undefined) {

            //sorting on the basis of category
            for (let i = 0; i < merge.length; i++) {
                let category = JSON.parse((merge[i].ipfs)).category;
                if (category === "cricx") {
                    cricx.push(merge[i])
                } else if (category === "evolutionx") {
                    evolutionx.push(merge[i])
                }
            }
            // setCricxData(cricx);
            // setEvolutionxData(evolutionx);

            //sorting on the basis of price
            for (var i = 0; i < merge.length; i++) {
                for (var j = i; j < merge.length; j++) {
                    if (parseInt(merge[i].price) > parseInt(merge[j].price)) {
                        let temp = merge[i];
                        merge[i] = merge[j];
                        merge[j] = temp;
                    }
                }
            }
            // setLowToHigh(merge);
            const reversed = [...merge].reverse();
            // setHighToLow(reversed)

            resolve({ cricx: cricx, evolutionx: evolutionx, LowToHigh: merge, HighToLow: reversed })
        }
    })


}

export const DealNftSort = (interval, deal) => {
    return new Promise((resolve, reject) => {
        clearInterval(interval);
        let merge = deal;
        let cricxDeal = [];
        let evolutionxDeal = [];
        if (merge !== undefined) {

            //sorting deal on the basis of category
            for (let i = 0; i < merge.length; i++) {
                let category = JSON.parse((merge[i].ipfs)).category;
                if (category === "cricx") {
                    cricxDeal.push(merge[i])
                } else if (category === "evolutionx") {
                    evolutionxDeal.push(merge[i])
                }
            }
            // setCricxDealData(cricxDeal);
            // setEvolutionxDealData(evolutionxDeal)
            //sorting deal on the basis of price
            for (var i = 0; i < merge.length; i++) {
                for (var j = i; j < merge.length; j++) {
                    if (parseInt(merge[i].price) > parseInt(merge[j].price)) {
                        let temp = merge[i];
                        merge[i] = merge[j];
                        merge[j] = temp;
                    }
                }
            }
            // setDealLowToHigh(merge);
            const reversed = [...merge].reverse();
            // setDealHighToLow(reversed)
            resolve({ cricx: cricxDeal, evolutionx: evolutionxDeal, LowToHigh: merge, HighToLow: reversed })
        }
    })

}


export const getRunningSale = () => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/getRunningSale`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                console.log("getRunningSale", response);
                if (response.data.success) {
                    if (response.data.data.length > 4) {
                        response.data.data.length = 4
                    }
                    resolve(response.data.data)
                } else {
                    resolve([]);
                }
                console.log(JSON.stringify(response.data.success));
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}

export const getRunningAuctions = () => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/getRunningAuctions`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    if (response.data.data.length > 4) {
                        response.data.data.length = 4
                    }
                    resolve(response.data.data)
                } else {
                    resolve([])
                }
                console.log(JSON.stringify(response.data.success));
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}
export const getRunningDeal = () => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/getRunningDeal`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    if (response.data.data.length > 6) {
                        response.data.data.length = 6
                    }
                    resolve(response.data.data)
                } else {
                    resolve([])
                }
                console.log(JSON.stringify(response.data.success));
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}
export const getSoldNFTOwner = (token) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/get-last-owner?tokenid=${token}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    resolve(response.data.data)
                } else {
                    resolve("")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    });

}
export const checkMarket = (token) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/check-market?tokenid=${token}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    resolve(response.data.data)
                } else {
                    resolve("")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}

export const getOneAuction = (token) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/getauction?tokenid=${token}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    console.log(response.data.data)
                    resolve(response.data.data);
                } else {
                    resolve({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}

export const getOneSale = (token) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${baseUrl}/getsale?tokenid=${token}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    console.log(response.data.data)
                    resolve(response.data.data);
                } else {
                    resolve({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}


export const getOneDeal = (token) => {
    return new Promise((resolve, reject) => {

        const config = {
            method: 'get',
            url: `${baseUrl}/getdeal?tokenid=${token}`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    console.log(response.data.data);
                    resolve(response.data.data);
                } else {
                    resolve({});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}


export const loginCheck = (next) => {
    let address = sessionStorage.getItem("address");
    let userId = sessionStorage.getItem("userid");
    if (userId !== null && userId !== '' && userId !== undefined) {
        if (address !== null && address !== '' && address !== undefined) {
            next()
        } else {
            // document.getElementById("closeButton").click();
            swal({ title: "connect to the metamask", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
            setTimeout(() => { window.location.href = "/ConnectWallet" }, 2000);
        }
    }
    else {
        // document.getElementById("closeButton").click();
        swal({ title: "Please login account", icon: "error", background: " #808080", showConfirmButton: false, timer: 2000 });
        setTimeout(() => { window.location.href = "/Login" }, 2000);
    }
}