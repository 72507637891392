/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import qs from 'qs';
import $ from 'jquery';
import {Oneto11 } from '../Details';
import { imgsrc, tokenContract, web3, baseUrl,formatCount,chainUrl } from '../Details';
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from 'axios';
// #endregion

const propTypes = {};
const defaultProps = {};

const Header = (props) => {

    const {logoPath} = props;

    useEffect(() => {
        setInterval(refresh, 1000);
    }, [])
    // setInterval(refresh, 1000);
    const [userBalance, SetUserBalance] = useState(0);
    const [userAddress, SetUserAddress] = useState('');
    const history = useHistory();
    const [searchVal, setSearchVal] = useState('')
    const [searchData, setSearchData] = useState([])
    const [text, setText] = useState("");
    const [displayName,setDisplayName]= useState(null);
    const [profileImage,setProfileImage]= useState(null);

    // #region functions
    const onCopyText = () => {
        swal("Address Copied !!", { button: false, icon: "success", timer: 2000 });
    };

    const loginCheck = () => {
        let userId = sessionStorage.getItem("userid");
        if (userId === "" || userId === null || userId === undefined) {
            history.push('/Login');
        } else {
            history.push('/ConnectWallet');
        }
    }

    const refresh = async () => {
        //console.log("refresh call");
        let userId = sessionStorage.getItem("userid");
        if (userId !== "" && userId !== null && userId !== undefined) {
            let addressLogin = sessionStorage.getItem("address");

            if (addressLogin !== null) {
                var balance = await tokenContract.methods.balanceOf(addressLogin).call();
                SetUserBalance(balance);
                SetUserAddress(addressLogin);
                
                document.getElementById("beforeWalletConnect").style.display = "none";
                document.getElementById("afterWalletConnect").style.display = "block";
                let metamaskAdd = await window.ethereum.request({ method: 'eth_requestAccounts' });
                //console.log("Here", metamaskAdd[0]);
                if (metamaskAdd[0] !== addressLogin) {
                    web3.eth.getAccounts()
                        .then((account) => {
                            sessionStorage.setItem("address", account[0]);
                        })
                }
            }
        }
    }

    const Logout = () => {
        sessionStorage.removeItem('userid');
        sessionStorage.removeItem('address');
        swal("Logout Successfully", { button: false, icon: "success", timer: 2000 });
        setTimeout(() => { history.push('/'); window.location.reload(false); }, 2000);

    }

    const searchNft = useCallback(() => {
        axios.post(`${baseUrl}/search-nft`, {
            search: searchVal
        }).then((result) => {
            if (result.data.success) {
                setSearchData(result.data.data.slice(0, 6))
            } else {
                setSearchData([])
            }
        }).catch((err) => {
            setSearchData([])
            console.error(err)
        })
    }, [searchVal])

    const enterSearch = (event) =>{
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            let url= `/allSearch?searchVal=${searchVal}`
            window.location.href = url;
        }
    }

    const handleWallet = () =>{
        $('.ajax-loader-header').css("display", "flex");
        let address = sessionStorage.getItem('address');
        let userId = sessionStorage.getItem('userid');
        if(userId === '' || userId === null || userId === undefined){
            swal({ title: "Please logIn and connect to wallet", icon: "error", background: " #808080", timer: 2000 });
        }else if(address === '' || address === null || address === undefined){
            swal({ title: "Please logIn and connect to wallet", icon: "error", background: " #808080", timer: 2000 });
        }else{
            var data = qs.stringify({
                'id': userId,
                'userAddress': address
              });
              var config = {
                method: 'post',
                url: `${baseUrl}/userAddress`,
                headers: { 
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                  if(response.data.success === true){
                    $('.ajax-loader-header').css("display", "none");
                    swal({ title: "Wallet address is saved as primary wallet", icon: "success", timer: 2000 });
                  }else{
                    $('.ajax-loader-header').css("display", "none");
                    swal(response.data.msg, {
                        timer: 3000,
                        icon: "warning",
                        button: false,
                      });  
                  }
              })
              .catch(function (error) {
                $('.ajax-loader-header').css("display", "none");
                console.error(error);
              });
        }
    }

    const userDetails = () => {
        
        let userId = sessionStorage.getItem("userid");
        if(userId !== null && userId !== undefined && userId !== ''){
            $('.ajax-loader').css("display", "flex");
        var config = {
            method: 'get',
            url: `${baseUrl}/user-profile?userid=${userId}`,
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
          };
          
          axios(config)
          .then(function (response) {
              if(response.data.success === true){
                let data = response.data.data;
                setDisplayName(data.displayName);
                let imgname = data.profileImage;
                let imgurl = imgsrc+imgname.trim();
                setProfileImage(imgurl)
                $('.ajax-loader').css("display", "none");
              }$('.ajax-loader').css("display", "none");
             
          })
          .catch(function (error) {
            console.error(error);
            $('.ajax-loader').css("display", "none");
          });
        }
    }
    // #endregion

    // #useeffect region
    useEffect(() =>{
        userDetails();
    },[]);
    useEffect(() => {
        if (searchVal) searchNft()
        else setSearchData([])
    }, [searchNft, searchVal]);
    // #endregion
    
    return <div>
        <div className="ajax-loader-header" style={{ display: "none" }}>
            <div style={{ textAlign: "center" }}>
                <p id="loaderText" style={{ color: "#fff" }}>Please wait....</p>
                <img src="img/load.gif" className="img-responsive" alt="" style={{ width: "50px" }} />
            </div>
        </div>
        <header className="oneto11_navbar commonNav">
            <div className="cstm-container">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                    <Link className="navbar-brand" to="/"><img src={logoPath || "img/logo.png"} width="100%" alt="" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <form className="d-flex m-0 ms-5">
                        <input onKeyPress={(event)=>{enterSearch(event)}} className="form-control me-2" type="search" placeholder="Search" aria-label="Search"
                            name="search" value={searchVal} onChange={(e) => {
                                setSearchVal(e.target.value.replace(/[^\w\s]/gi, ""))
                            }} />
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15.369" height="15.373" viewBox="0 0 15.369 15.373">
                            <defs>
                                <clipPath id="a">
                                    <rect width="15.369" height="15.373" fill="none" />
                                </clipPath>
                            </defs>
                            <g clipPath="url(#a)">
                                <g transform="translate(-547.461 -43)">
                                    <path
                                        d="M19.689,18.754l-4.275-4.315a6.092,6.092,0,1,0-.925.937l4.247,4.287a.658.658,0,0,0,.929.024A.662.662,0,0,0,19.689,18.754Zm-9.061-3.326a4.81,4.81,0,1,1,3.4-1.409A4.78,4.78,0,0,1,10.628,15.428Z"
                                        transform="translate(542.961 38.507)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                        {searchData && searchData.length > 0 && searchVal && (
                            <div className='search-list-outer'>
                                <div className='search-list-inner'>
                                <p className="recentTxt" style={{padding: "15px 10px 5px",fontSize:"12px"}}>RECENT SEARCH</p>
                                    {searchData.map((item) => {
                                        const data = JSON.parse(item.ipfs)
                                        return (

                                            <a href={`/allSearch?searchVal=${searchVal}`}>
                                                <div className='search-item' key={item._id}>
                                                    {data.name}
                                                </div>
                                            </a>
                                        )
                                    })}
                                    <a href={`/allSearch?searchVal=${searchVal}`}>
                                        <div className='show-all'>
                                            Show All
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )}
                    </form>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link text-white px-3 fs-6 active" aria-current="page" to="/BuyOnly">Buy Only</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white px-3 fs-6 active" aria-current="page" to="/BidOnly">Bid Only</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white px-3 fs-6" href="/Aboutus">About us</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white px-3 fs-6" to="/Deals">Deals</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white px-3 fs-6" to="/ContactUs">Contact US</Link>
                            </li>
                            <li className="nav-item" id="beforeWalletConnect">
                                <a className="nav-link text-white px-3 fs-6 wallet" onClick={loginCheck}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.56" height="18.056" viewBox="0 0 19.56 18.056" className="me-2">
                                        <g transform="translate(-3.375 -4.499)">
                                            <path
                                                d="M20.113,11.25H6.2a2.823,2.823,0,0,0-2.821,2.821v7.9A2.823,2.823,0,0,0,6.2,24.791H20.113a2.823,2.823,0,0,0,2.821-2.821v-7.9A2.823,2.823,0,0,0,20.113,11.25Z"
                                                transform="translate(0 -2.237)" fill="#fff" />
                                            <path
                                                d="M17.613,4.547,6.569,6.7C5.722,6.893,4.5,7.744,4.5,8.778a2.962,2.962,0,0,1,2.3-.893H20.3V6.921a2.7,2.7,0,0,0-.654-1.768h0A2.224,2.224,0,0,0,17.613,4.547Z"
                                                transform="translate(-0.373)" fill="#fff" />
                                            <circle cx="1.5" cy="1.5" r="1.5" transform="translate(18.529 14.498)"
                                                fill="#0661dc" />
                                        </g>
                                    </svg>
                                    <span onClick={loginCheck}>Connect Wallet</span>
                                </a>
                            </li>
                            <li className="nav-item dropdown" id="afterWalletConnect" style={{ display: "none" }}>
                                <Link to="/ConnectWallet" className="nav-link text-white px-3 fs-6 wallet walletF" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.56" height="18.056" viewBox="0 0 19.56 18.056" className="me-2">
                                        <g transform="translate(-3.375 -4.499)">
                                            <path
                                                d="M20.113,11.25H6.2a2.823,2.823,0,0,0-2.821,2.821v7.9A2.823,2.823,0,0,0,6.2,24.791H20.113a2.823,2.823,0,0,0,2.821-2.821v-7.9A2.823,2.823,0,0,0,20.113,11.25Z"
                                                transform="translate(0 -2.237)" fill="#fff" />
                                            <path
                                                d="M17.613,4.547,6.569,6.7C5.722,6.893,4.5,7.744,4.5,8.778a2.962,2.962,0,0,1,2.3-.893H20.3V6.921a2.7,2.7,0,0,0-.654-1.768h0A2.224,2.224,0,0,0,17.613,4.547Z"
                                                transform="translate(-0.373)" fill="#fff" />
                                            <circle cx="1.5" cy="1.5" r="1.5"
                                                transform="translate(18.529 14.498)" fill="#0661dc" />
                                        </g>
                                    </svg>
                                    <span className="d-flex"><p style={{ width: "60px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{formatCount((userBalance / 10 ** 18).toFixed(8))}</p> 
                                    {displayName ? (<p>{displayName}</p>) : (<p>Matic</p>)}
                                    </span>
                                    {profileImage ? (<div className="userFace"><img src={profileImage} alt="" /></div>) : (<div className="userFace"><img src="img/avtar2.png" alt="" /></div>)}
                                    
                                </Link>
                                <ul className="dropdown-menu wltdrp" aria-labelledby="navbarDropdown">
                                    <div className="copyLink d-flex">
                                            <a href= {chainUrl+userAddress} rel="noreferrer" target="_blank"><input
                                                className="m-0" style={{fontWeight: "500",cursor: "pointer",border: 0,padding: "0",width: "130px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                type="text"
                                                value={userAddress}
                                                placeholder="Type some text here"
                                                onChange={(event) => setText(event.target.value)}
                                                disabled = {true}
                                            />
                                            </a>
                                            <CopyToClipboard text={userAddress} onCopy={onCopyText}>
                                                <div className="copy-area">
                                                <button style={{     border: 0, backgroundColor:"#0000"}}><i className="ri-file-copy-line"></i></button>
                                                </div>
                                            </CopyToClipboard>
                                            
                                     </div>
                                    <div className="list_grd">
                                        <div className="prfs_w">
                                        {profileImage ? (<div className="userAvtar">
                                        <img src={profileImage} alt="" /></div>) : (<div className="userAvtar"><img src="img/avtar2.png" alt="" /></div>)}
                                            
                                            <div className="pw_dat">
                                                <p>Balance</p>
                                                <p>{Oneto11}{formatCount((userBalance / 10 ** 18).toFixed(8))}</p>
                                            </div>
                                        </div>
                                        <div className="hr hrbx"></div>
                                        <div className="prfs_lstng">
                                            <ul>
                                            <li><button className="setBtn" style={{border: "0",backgroundColor:"transparent",display:"flex",alignItems:"center",color:"#183B56",padding: "4px 0px",
                                                        fontSize: "17px"}} type="button" onClick={handleWallet}><i className="ri-wallet-3-fill"></i>&nbsp;&nbsp;&nbsp;Set Wallet</button><p style={{fontSize:"13px",padding:"0px 0 10px"}}>(set this wallet as your primary wallet)</p></li>
                                                <li><Link to="/MyItems"><i className="ri-landscape-fill"></i>My items</Link></li>
                                                <li><Link to="/UpdateProfile"><i className="ri-edit-line"></i>Edit Profile</Link></li>
                                                <li><Link to="/MyWishlist"><i className="ri-heart-fill"></i>My Wishlist</Link></li>
                                                <li><Link to="/Claim"><i className="ri-numbers-fill"></i>My Claim</Link></li>
                                                {/* <li><Link to="/" onClick=""><i className="ri-logout-box-line"></i>Logout</Link></li> */}
                                                <li><Link to="/" onClick={Logout}><i className="ri-logout-box-line"></i>Logout</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    </div>;
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
// #endregion

export default Header;