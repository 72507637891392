/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// #region constants
import React ,{useEffect, useState } from 'react';
import { Link,useLocation,useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import swal from 'sweetalert';
import $ from "jquery";
import Header from './Header'
import Footer from './Footer'
import { baseUrl,ontop } from '../Details';
// #endregion

// #region component
const propTypes = {};
const defaultProps = {};
 
const GeneratePassword = () => {
    const location = useLocation();
    if(location.state){
        sessionStorage.setItem("Verify", location.state.otpVerified);
        sessionStorage.setItem("email", location.state.otpVerified);
    }
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verification, setVerification] = useState(sessionStorage.getItem("Verify"));
    const [email, setEmail] = useState(sessionStorage.getItem("email"));
    let history = useHistory();

    // #region functions
    //Validating Password
    const validatePassword = () => {
        let pass = document.getElementById("password").value;
        let confirmPass = document.getElementById("re_password").value;
        let passVal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$^!%*?&_])[A-Za-z\d@#$^!%*?&_]{8,}$/;
        let passValTrue = passVal.test(pass);
        if (passValTrue) {
          document.getElementById('passError').style.display = "none";
          document.getElementById('passError').innerHTML = "";
          document.getElementById('passwordVerified').style.display = "block";
          
            if (pass === confirmPass) {
                document.getElementById('confirmPassError').style.display = "none";
                document.getElementById('confirmPassError').innerHTML = "";
                document.getElementById('confirmPasswordVerified').style.display = "block";
                return true;
            }else {
                document.getElementById('confirmPassError').style.display = "block";
                document.getElementById('confirmPasswordVerified').style.display = "none";
                if (confirmPass === "") {
                document.getElementById('confirmPassError').innerHTML = "** Fill this field";
                } else {
                document.getElementById('confirmPassError').style.display = "block";
                document.getElementById('confirmPassError').innerHTML = "Password and cofirm password didnt match";
                }
                return false;
            }
            
        } else {
            document.getElementById('passError').style.display = "block";
            document.getElementById('passwordVerified').style.display = "none";
            if (pass === "") {
            document.getElementById('passError').innerHTML = "** Fill this field";
            }
            else {
            document.getElementById('passError').innerHTML = "**Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
            }
            return false;
        }
    }
    const passwordchange = () => {
        const Password = password;
        const ConfirmPassword = confirmPassword;
        var verification = location.state.otpVerified
        var email = location.state.email

        if(validatePassword()){
            if(verification === true){
                $('.ajax-loader').css("display", "flex");
                var data = qs.stringify({
                    'email': email,
                   'otpverified': verification,
                   'password': Password,
                   're_password': ConfirmPassword 
                   });
                   var config = {
                     method: 'post',
                     url: `${baseUrl}/forget-password`,
                     headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Origin': '*'
                     },
                     data : data
                   };
                   
                   axios(config)
                   .then(function (response) {
                     if(response.data.success === true){
                        verification = false;
                        email = '';
                        localStorage.removeItem("Verify");
                        localStorage.removeItem("email");
                        $('.ajax-loader').css("display", "none");
                        swal("Password Generated Successfully",{button:false, icon:"success",timer:3000})
                        setTimeout(history.push('/Login'), 3000);
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                        
                    }
                    else{
                        $('.ajax-loader').css("display", "none");
                        swal("Error",{button:false, icon:"warning",timer:3000})
                    }
                   })
                   .catch(function (error) {
                     console.error(error);
                     $('.ajax-loader').css("display", "none");
                   });
            }
            else{
                $('.ajax-loader').css("display", "none");
            }
        }
        else{
            swal("Please enter valid details",{button:false, icon:"warning",timer:1000});
        }
        
        
    }
    // #endregion

    // #useeffect region
    useEffect(() => {
        var body = document.body;
		body.classList.remove("connectWalletWrapper");
        ontop();
    }, []);
    // #endregion
    return <div>
            <Header/>
            <div className="ajax-loader" style={{display:"none"}}>
                <div style={{textAlign:"center"}}> 
                    <p id="loaderText" style={{color:"#fff"}}>Please wait....</p>
                    <img src="img/load.gif" className="img-responsive" alt="" style={{width: "50px"}}/> 
                </div>
            </div>
                <section className="exploreWrapper">
                    <div className="cstm-container">
                        <div className="innr_bck_box">
                            <Link className="btn viewBtn" to="/ForgotPassword">
                                <i className="ri-arrow-left-line" style={{fontSize: "22px"}}></i>
                                <p className="m-0">Back to home</p>
                            </Link>
                        </div>
                        <div className="loginInner">
                            <div>
                                <h1 className="fw-bold headingC">Generate Password</h1>
                            </div>
                            <div className="mt-5 w-50 loginData mb-5 bg-white cstmfrm shadow-sm">
                                <form action="" className="">
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl">Enter  Password</label>
                                        <input type="password" id = "password" className="form-control" placeholder="Enter Password" onKeyUp = {validatePassword}onChange = {(event)=>{setPassword(event.target.value)}} value = {password}/>
                                        <span id = "passError" style={{display:"none",border: "1px solid #deb2e7", textAlign: "center",margin: "5px 8px",backgroundColor: "#dc35451f",fontSize: "13px", borderRadius: "4px"}}></span>
                                        <p id="passwordVerified" style={{display:"none",color:"green",padding:" 0 10px"}}>Password is Valid <img src="img/check.png" alt=".." style={{width: "15px"}}/></p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="cstm_lbl">Re-Enter Password</label>
                                        <input type="password" id = "re_password" className="form-control" placeholder="Re-Enter Password" onKeyUp={validatePassword} onChange = {(event)=>{setConfirmPassword(event.target.value);}} value = {confirmPassword}/>
                                        <span id = "confirmPassError" style={{display:"none",border: "1px solid #deb2e7", textAlign: "center",margin: "5px 8px",backgroundColor: "#dc35451f",fontSize: "13px", borderRadius: "4px"}}></span>
                                        <p id="confirmPasswordVerified" style={{display:"none",color:"green",padding:" 0 10px"}}>Re-Password is Valid <img src="img/check.png" alt=".." style={{width: "15px"}}/></p>
                                    </div>
                                    <div className="hrbx hr"></div>
                                    <div className="loginBtn d-flex align-items-center justify-content-between">
                                        <Link to="/Login" className="cstm_link">Login?</Link>
                                        <Link className="btn btn-primary rounded-pill shadow" to="#" id = "passSubmit" onClick = {passwordchange} data-bs-toggle="modal" data-bs-target="#mailvefryctn">Submit</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
        <Footer/>        
    </div>;
}

GeneratePassword.propTypes = propTypes;
GeneratePassword.defaultProps = defaultProps;
// #endregion

export default GeneratePassword;