/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { baseUrl } from '../Details';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const CmsPage = () => {
  const location = useLocation();
  const [cmsData, setCmsData] = useState({})
  useEffect(() => {
    getCmsData(location.pathname)
  }, [])


  const getCmsData = (slug) => {
    axios.get(`${baseUrl}${slug}`).then((result) => {
      if (result.data.success) {
        setCmsData(result.data.data)
      } else {
        //window.location.href = "/"
      }
    }).then((err) => {
      //       
    })
  }

  return <div>
    <Header />
    <div className='exploreWrapper' style={{ padding: "70px 0" }}>
      {cmsData && cmsData.content && (
        <div className='container'

          dangerouslySetInnerHTML={{ __html: cmsData.content }}>
        </div>
      )}
    </div>
    <Footer />
  </div>;
}



CmsPage.propTypes = propTypes;
CmsPage.defaultProps = defaultProps;
// #endregion

export default CmsPage;