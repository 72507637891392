/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState}from 'react';
import LikesCount from './LikesCount';
import { Oneto11, contract } from '../../Details';

export default function NftCard(props) {
    const {item} = props;
    const [url, setUrl] = useState('');
    // const tokenId = item.id || item.NftId;
    useEffect(()=>{
        const getUrl = async(tokenId) => {

            await contract.methods.checkMarket(tokenId).call().then((data)=>{
                if(data === 'Auction'){
                    setUrl(`/PlaceBid?tokenNo=${tokenId}`);    
                }else if(data === 'Sale'){
                    setUrl(`/Buynow?tokenNo=${tokenId}`);
                }else if(data === 'Deal'){
                    setUrl(`/Buynow?tokenNo=${tokenId}`);
                }else{
                    setUrl(`/SoldOut?tokenNo=${tokenId}`);
                }
            })       
        }
        getUrl(item.id || item.NftId);
    },[item])
  return (
    <div>
        <div className="card" key = {item.id || item.NftId}>
        <div style={{position:"relative"}} >
            <a href={url} >
                    <div className="cardImg">
                        <img src={(JSON.parse(item.ipfs)).image} className="card-img-top" alt="..."/>
                    </div>
                </a>
                <LikesCount nftId = {item.id || item.NftId}/>
        </div>
            
            <div className="card-body">
                <a href={url} className="card-text text-decoration-none">
                <span>{(JSON.parse(item.ipfs)).name}</span>
                <div className='d-flex justify-content-between w-100 align-items-baseline'>
                    <span className='pricePlusLike' >Price: {Oneto11}{item.price &&  item.price !== '0' ? parseInt(item.price)/10**18 : 0}</span>   
                </div>
                </a>
                <div className="history_View">
                </div>
            </div>
        </div>  
    </div>
  )
}
